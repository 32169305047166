import React, { Component } from 'react';
import { Form } from 'react-bootstrap';

import { fba } from '../../firebase.js';
import '../../css/common.css';
import Swal from 'sweetalert2';

export default class ForgotPass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      validated: false,
      isLoading: false,
    };
  }

  handleForgotPass = (e) => {
    const { email } = this.state;
    const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === true) {
      this.setState({ isLoading: true });
      if (!emailPattern.test(email)) {
        this.setState({ isLoading: false });
        return Swal.fire('Error', `Enter a valid email`, 'warning');
      } else {
        fba
          .sendPasswordResetEmail(email)
          .then(() => {
            Swal.fire('Success', `Email sent to user email Id.`, 'success');
            this.setState({ isLoading: false });
          })
          .catch(() => {
            this.setState({ isLoading: false });
            Swal.fire('Error', `user doesn't exist`, 'error');
          });
      }
    } else {
      this.setState({
        validated: true,
        isLoading: false,
      });
    }
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  render() {
    const { email, validated, isLoading } = this.state;
    return (
      <div className='forgetPass'>
        <div className='left-container'></div>
        <div className='right-container'>
          <div className='formContainer'>
            <Form
              noValidate
              className='form-style'
              validated={validated}
              onSubmit={this.handleForgotPass}
            >
              <h5 className='letterName'>
                PRODUCTI<span className='brandLastLetter'>O</span>
              </h5>
              <label className='appName'>
                Enter your email address and we'll send you a link to reset your
                password.
              </label>
              <br />
              <Form.Group>
                <Form.Control
                  className='inputTag'
                  type='email'
                  placeholder='Email'
                  name='email'
                  value={email}
                  onChange={this.handleChange}
                  required
                />
                <Form.Control.Feedback type='invalid'>
                  Please enter valid email id
                </Form.Control.Feedback>
              </Form.Group>
              <div className='send-button'>
                <button
                  type='submit'
                  className='button-send'
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <i
                      className='fa fa-spinner fa-spin'
                      state={{ fontSize: '24px' }}
                    />
                  ) : (
                    'Send Request'
                  )}
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}
