import React from 'react';
import { Modal, ListGroup, Card } from 'react-bootstrap';
import parseHTML from 'html-react-parser';

const TaskDescriptionModal = ({ onShow, onHide, task }) => {
  return (
    <Modal
      show={onShow}
      onHide={onHide}
      size='lg'
      className=''
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          Task Description Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>{task.description && parseHTML(task.description)}</>
        <Card.Title>Attachments</Card.Title>
        <br />
        <ListGroup>
          {task.files && task.files.length > 0
            ? task.files &&
              task.files.map((file) => (
                <ListGroup.Item>
                  <a
                    href={file.url}
                    rel='noopener noreferrer'
                    download
                    className='links-taskDetail'
                    target='_blank'
                  >
                    {file.name}
                  </a>
                </ListGroup.Item>
              ))
            : 'No Attachments Found!'}
        </ListGroup>
      </Modal.Body>
    </Modal>
  );
};

export default TaskDescriptionModal;
