export function storyboardReducer(
  state = {
    inputValues: {
      isChecked: false,
      taskDetailsGoalOption: '',
      taskDetailsGoalOptionValue: '',
      filteredValuesByOption: [],
    },
  },
  action
) {
  switch (action.type) {
    case 'SET_STORYBOARD_INPUT_VALUE':
      return {
        ...state,
        inputValues: {
          ...state.inputValues,
          [action.name]: action.value,
        },
      };
    case 'CLEAR_STORYBOARD_INPUT_VALUE':
      return {
        ...state,
        inputValues: {
          isChecked: false,
          taskDetailsGoalOption: '',
          taskDetailsGoalOptionValue: '',
          // filteredValuesByOption: [],
        },
      };
    case 'SET_STORYBOARD_OPTION':
      return {
        ...state,
        inputValues: {
          ...state.inputValues,
          filteredValuesByOption:
            action.payload && action.payload.length > 0 ? action.payload : [],
        },
      };
    default:
      return state;
  }
}
