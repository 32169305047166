import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import parseHTML from 'html-react-parser';
import ShowMoreText from 'react-show-more-text';
import _ from 'lodash';
import LazyLoad from 'react-lazyload';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Dropdown, Modal, Card, ListGroup } from 'react-bootstrap';
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import moment from 'moment';

import * as taskDetailActions from './../redux/actions/taskDetailActions';
import * as loaderActions from './../redux/actions/loaderAction';
import * as memberActions from './../redux/actions/memberActions';

import defaultImg from './../images/defaultProfile.png';
import defaultFileImg from './../images/File_icon.png';
import {
  imgExtentionTypes,
  docExtentionType,
  videoExtentionType,
} from '../utils/mime-type';
import { getUsersByCompanyId } from './storyboad/storyboardService';

class ArchivedTaskContainer extends Component {
  state = {
    tasks: [],
    showDescriptionModaL: false,
    modalDescription: {},
  };

  async componentDidMount() {
    let companyId = localStorage.getItem('companyID');
    const { fetchAllTasks, fetchEmp } = this.props.actions;
    await fetchEmp(companyId);
    await fetchAllTasks(companyId);
    const { tasks } = this.props.taskDetailReducer;
    this.setState({ tasks });
  }

  componentWillReceiveProps(nextProps, prevState) {
    if (nextProps.taskDetailReducer.tasks !== prevState.tasks) {
      this.setState({ tasks: nextProps.taskDetailReducer.tasks });
    }
  }

  handleShowDescriptionModal = (task) => {
    if (task) {
      this.setState({
        ...this.state,
        showDescriptionModaL: true,
        modalDescription: task,
      });
    }
  };

  onEditUnassignedTask = (task) => {
    if (task) {
      const {
        id,
        assignBy,
        empID,
        title,
        points,
        taskGroupId,
        goalId,
        goalOptionId,
        startDate,
        endDate,
        endTime,
        file,
        files,
        description,
      } = task;
      const onInputChange = this.props.actions.setTaskDetailInputValue;
      const startDateObj = moment(startDate).toDate();
      const endDateObj = moment(endDate).toDate();
      const endTimeObj = moment(`${endDate} ${endTime}`).toDate();
      const editorDescription = EditorState.createWithContent(
        ContentState.createFromBlockArray(convertFromHTML(description))
      );
      onInputChange('taskId', id);
      onInputChange('assignBy', assignBy);
      onInputChange('empID', empID);
      onInputChange('taskDetailTitle', title);
      onInputChange('taskDetailPoints', points);
      onInputChange('taskGroup', taskGroupId);
      onInputChange('taskDetailsGoalType', goalId);
      onInputChange('taskDetailsGoalOption', goalOptionId);
      onInputChange('startDate', startDateObj);
      onInputChange('endDate', endDateObj);
      onInputChange('endTime', endTimeObj);
      onInputChange('editorState', editorDescription);
      if (file === '') {
        onInputChange('file', '');
      }
      if (files && files.length > 0) {
        _.forEach(files, (file, i) => {
          if (imgExtentionTypes.includes(file.fileExtention)) {
            file.isImage = true;
          } else if (docExtentionType.includes(file.fileExtention)) {
            file.isDocument = true;
          }
        });
        onInputChange('originalFiles', files);
      } else {
        onInputChange('originalFiles', null);
      }
      this.props.history.push(`/admin/tasks`);
    }
  };

  showTasks = (tasks) => {
    _.map(tasks, (task) => {
      task.file = task.file ? task.file : defaultFileImg;
      const file = task.file.split('?alt')[0];
      task.taskDescriptionString = task.description
        ? taskDetailActions.convertHTMLToText(task.description)
        : '';
      task.isValidImg = imgExtentionTypes.indexOf(file.split('.').pop(-1)) >= 0;
      task.isDocument = docExtentionType.indexOf(file.split('.').pop(-1)) >= 0;
      task.isVideo = videoExtentionType.indexOf(file.split('.').pop(-1)) >= 0;
    });

    const handleSetOption = (key, taskObj) => {
      const myState = [...this.state.tasks];
      _.forEach(myState, (obj) => {
        if (obj.id === taskObj.id) {
          obj.selectLable = key.empName;
          // TaskGroup
          if (taskObj && taskObj.assignBy === 'taskGroup') {
            if (key && key.tasks) {
              _.forEach(key.tasks, (utask) => {
                if (utask && utask.id) {
                  if (
                    taskObj.id === utask.id &&
                    utask.assignBy === 'taskGroup'
                  ) {
                    obj.isComplete = utask.isComplete ? true : false;
                    obj.isDeleted = utask.isDeleted ? true : false;
                  }
                }
              });
            }
          }
        }
      });
      this.setState((prevState) => {
        return { ...prevState, ...myState };
      });
    };

    const showEmployee = (obj) => {
      const { users } = this.props.memberReducer;
      let usersArray = [];
      // let allUsers = [...users]; // Changed
      let allUsers = getUsersByCompanyId(users);
      if (allUsers && allUsers.length) {
        usersArray = allUsers.filter((user) => {
          if (user.empTaskGroupId && user.empTaskGroupId !== '') {
            return user.empTaskGroupId === obj.taskGroupId;
          }
        });
        let finalArray = [];
        let newUsersArray = usersArray;
        if (obj.assignBy === 'taskGroup') {
          if (obj.taskGroupUsers) {
            obj.taskGroupUsers.forEach((u) => {
              if (u) {
                newUsersArray.forEach((user) => {
                  if (user) {
                    if (u.id === user.username) {
                      finalArray.push(user);
                    }
                  }
                });
              }
            });
          } else {
            _.forEach(newUsersArray, (user) => {
              if (user) {
                if (user.tasks !== undefined && user.tasks.length > 0) {
                  user.tasks.forEach((t) => {
                    if (t) {
                      if (t.id === obj.id && t.assignBy === obj.assignBy) {
                        finalArray.push(user);
                      }
                    }
                  });
                }
              }
            });
          }
        }
        if (finalArray && finalArray.length > 0) {
          return (
            <Dropdown.Menu>
              {finalArray.map((user, i) => (
                <Dropdown.Item
                  eventKey={i}
                  id={user.uid}
                  style={{ fontSize: '18px' }}
                  key={i}
                  onClick={() => handleSetOption(user, obj)}
                >
                  {user.empName}
                  <LazyLoad
                    key={user.id}
                    height={150}
                    placeholder={
                      <img
                        alt='employee img'
                        className='employee-img'
                        src={defaultImg}
                      />
                    }
                  >
                    <img
                      alt='employee img'
                      className='employee-img'
                      src={user.photoURL || defaultImg}
                    />
                  </LazyLoad>
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          );
        } else {
          return (
            <Dropdown.Menu>
              <Dropdown.Item
                eventKey={'user'}
                id={'user.uid'}
                key={'i'}
                style={{
                  padding: '0px 10px',
                  fontSize: '16px',
                  lineHeight: '30px',
                  textAlign: 'center',
                }}
              >
                No Users Found!
              </Dropdown.Item>
            </Dropdown.Menu>
          );
        }
      }
    };

    const showEmployeeImg = (task) => {
      // let allUsers = [...this.props.memberReducer.users];
      let allUsers = getUsersByCompanyId(this.props.memberReducer.users);
      let filterUser = _.filter(allUsers, ['username', task.username]);
      let imgUrl =
        filterUser && filterUser.length !== 0
          ? filterUser[0].photoURL
          : defaultImg;
      return (
        <>
          <img alt='employee img' className='employee-img' src={imgUrl} />{' '}
          {filterUser && filterUser.length === 0 ? 'N/A' : task.username}
        </>
      );
    };

    if (tasks) {
      const data = _.map(tasks, (task, i) => (
        <tr key={i}>
          <td className={task.assignBy === 'unassigned' ? 'text-center' : ''}>
            {task.assignBy === 'employee' && showEmployeeImg(task)}
            {task.assignBy === 'taskGroup' && (
              <Dropdown drop='down'>
                <Dropdown.Toggle className='dropDownSelect' id='dropdown-basic'>
                  {task.selectLable}
                  {showEmployee(task)}
                </Dropdown.Toggle>
              </Dropdown>
            )}
            {task.assignBy === 'unassigned' && 'N/A'}
          </td>
          <td>
            {task.isValidImg && (
              <img
                src={task.file}
                className='employee-img'
                alt={task.username}
              />
            )}{' '}
            {task.title}
            {task.isDocument && (
              <div>
                <span style={{ color: '#212529' }}>
                  <i
                    className='fa fa-file'
                    style={{
                      marginLeft: '7px',
                      marginTop: '7px',
                      fontSize: '21px',
                      cursor: 'pointer',
                    }}
                  />
                </span>
              </div>
            )}
            {task.isVideo && (
              <div>
                <span style={{ color: '#212529' }}>
                  <i
                    className='fa fa-video-camera'
                    style={{
                      marginLeft: '7px',
                      marginTop: '7px',
                      fontSize: '21px',
                      cursor: 'pointer',
                    }}
                  />
                </span>
              </div>
            )}
          </td>
          <td style={{ maxWidth: '267px', wordBreak: 'break-all' }}>
            {task.description && task.description !== '' ? (
              <React.Fragment>
                <i
                  onClick={() => this.handleShowDescriptionModal(task)}
                  className='fa fa-eye'
                  aria-hidden='true'
                  style={{
                    float: 'right',
                    fontSize: '21px',
                  }}
                ></i>{' '}
                <ShowMoreText
                  lines={3}
                  more='Show More'
                  less='Show Less'
                  anchorClass='small-text'
                  expanded={false}
                  width={250}
                >
                  {task.taskDescriptionString}
                </ShowMoreText>
              </React.Fragment>
            ) : (
              'N/A'
            )}
          </td>
          <td>
            <select
              className='border-50'
              style={{ height: '34px', fontSize: '14px', padding: '4px' }}
            >
              <option disabled value=''>
                {' '}
                -- Please Select --{' '}
              </option>
              <option value={task.startDate}>
                {' '}
                Start Date: {task.startDate}
              </option>
              <option value={task.endDate}> End Date: {task.endDate}</option>
            </select>
          </td>
          <td className='text-center'>
            {(task.isComplete && 'Completed') ||
              (task.isDeleted && 'Deleted') ||
              (task.isUnassigned && 'Unassigned') ||
              'N/A'}{' '}
            {task.isUnassigned && (
              <div
                onClick={() => this.onEditUnassignedTask(task)}
                className='d-inline green-icon mr-2'
              >
                <i
                  style={{
                    fontSize: '18px',
                    marginLeft: '6px',
                  }}
                  className='fa fa-pencil'
                ></i>
              </div>
            )}
          </td>
        </tr>
      ));
      if (tasks.length === 0 && tasks !== null) {
        return (
          <tr>
            <td
              style={{ fontSize: '14px' }}
              className='text-center'
              colSpan='8'
            >
              No Tasks Found...{' '}
            </td>
          </tr>
        );
      }
      return data;
    }
  };

  showOnlyArchivedTasks = (tasks) => {
    let allTasks = [...tasks];
    let notCompleteTasksArray = [];
    if (tasks && tasks.length > 0) {
      let allUsers = [...this.props.memberReducer.users];
      let companyUsers = getUsersByCompanyId(this.props.memberReducer.users);
      // let allUsers = [...this.props.memberReducer.users];
      let usersArray = companyUsers;
      if (allTasks) {
        _.forEach(allTasks, (task) => {
          if (task && task.assignBy === 'employee') {
            let filterUser = _.filter(companyUsers, [
              'username',
              task.username,
            ]);
            if (filterUser && filterUser.length > 0) {
              _.forEach(filterUser, (u) => {
                if (u && u.tasks) {
                  _.forEach(u.tasks, (utask) => {
                    if (utask && utask.id) {
                      if (task.id === utask.id) {
                        if (!utask.isDeleted) {
                          utask.isDeleted = false;
                        }
                        if (
                          utask.isComplete === true ||
                          utask.isDeleted === true
                        ) {
                          task.isComplete = utask.isComplete;
                          task.isDeleted = utask.isDeleted;
                          notCompleteTasksArray.push(task);
                        }
                      }
                    }
                  });
                }
              });
            } else {
              const unAuthUser = _.filter(allUsers, [
                'username',
                task.username,
              ]);
              if (unAuthUser && unAuthUser.length === 0) {
                task.isUnassigned = true;
                task.empID = '';
                task.username = '';
                notCompleteTasksArray.push(task);
              }
            }
          } else if (task && task.assignBy === 'taskGroup') {
            let taskGroupUsers = [];
            if (task && task.taskGroupUsers && task.taskGroupUsers.length > 0) {
              _.forEach(task.taskGroupUsers, (tgUsers) => {
                if (tgUsers && tgUsers.id) {
                  _.forEach(usersArray, (user) => {
                    if (user && user.id && user.id === tgUsers.id) {
                      taskGroupUsers.push(user);
                    }
                  });
                }
              });
            } else {
              task.isUnassigned = true;
            }
            if (taskGroupUsers) {
              let taskGroupUsersLength = taskGroupUsers.length;
              let isCompleteTasksLength = 0;
              let isDeletedTasksLength = 0;
              _.forEach(taskGroupUsers, (user) => {
                if (user && user.tasks) {
                  _.forEach(user.tasks, (utask) => {
                    if (utask && utask.id && utask.assignBy === 'taskGroup') {
                      if (!utask.isDeleted) {
                        utask.isDeleted = false;
                      }
                      if (
                        (task.id === utask.id && utask.isComplete === true) ||
                        (task.id === utask.id && utask.isDeleted === true)
                      ) {
                        if (utask.isComplete) {
                          isCompleteTasksLength++;
                        }
                        if (utask.isDeleted) {
                          isDeletedTasksLength++;
                        }
                      }
                    }
                  });
                }
              });
              if (
                taskGroupUsersLength === isCompleteTasksLength ||
                isDeletedTasksLength
              ) {
                notCompleteTasksArray.push(task);
              }
            }
          } else if (task && task.assignBy === 'unassigned') {
            notCompleteTasksArray.push(task);
          }
        });
      }
    }
    notCompleteTasksArray = _.orderBy(
      notCompleteTasksArray,
      ['createdAt'],
      ['desc']
    );
    return this.showTasks(notCompleteTasksArray);
  };

  displayModal = () => {
    return (
      <Modal
        show={this.state.showDescriptionModaL}
        onHide={() =>
          this.setState({
            showDescriptionModaL: false,
            modalDescription: '',
          })
        }
        size='lg'
        className=''
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            Task Description Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            {this.state.modalDescription.description &&
              parseHTML(this.state.modalDescription.description)}
          </>
          <Card.Title>Attachments</Card.Title>
          <br />
          <ListGroup>
            {this.state.modalDescription.files &&
            this.state.modalDescription.files.length > 0
              ? this.state.modalDescription.files &&
                this.state.modalDescription.files.map((file) => (
                  <ListGroup.Item>
                    <a
                      href={file.url}
                      rel='noopener noreferrer'
                      download
                      className='links-taskDetail'
                      target='_blank'
                    >
                      {file.name}
                    </a>
                  </ListGroup.Item>
                ))
              : 'No Attachments Found!'}
          </ListGroup>
        </Modal.Body>
      </Modal>
    );
  };

  render() {
    const isLoading = this.props.loaderReducer.loader;
    return (
      <>
        {this.state.showDescriptionModaL ? this.displayModal() : false}
        {isLoading ? (
          <div>
            <Backdrop
              style={{
                zIndex: 99,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: 'transparent',
              }}
              open={isLoading}
            >
              <CircularProgress color='inherit' />
            </Backdrop>
          </div>
        ) : (
          <div>
            <div className='plan-content'>
              <h2>Archived Task</h2>
            </div>
            <div className='row'>
              <div className='col-lg-12'>
                <div className=' pl-3 pr-3 mt-3'>
                  <div className='table-responsive'>
                    <table className='table table-bordered table-head-fixed table-hover mt-0 available12'>
                      <thead>
                        <tr>
                          <th className='text-center' scope='col'>
                            Employee Name
                          </th>
                          <th className='text-center' scope='col'>
                            Task Title
                          </th>
                          <th className='text-center' scope='col'>
                            Description
                          </th>
                          <th className='text-center' scope='col'>
                            Duration
                          </th>
                          <th className='text-center' scope='col'>
                            Status
                          </th>
                        </tr>
                      </thead>
                      <div className='available-items available12-items'>
                        <tbody className='word-body'>
                          {this.state.tasks &&
                            this.showOnlyArchivedTasks(this.state.tasks)}
                        </tbody>
                      </div>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  const { taskDetailReducer, memberReducer, loaderReducer } = state;
  if (taskDetailReducer.tasks) {
    _.map(
      taskDetailReducer.tasks,
      (task) => (task.selectLable = 'Please Select')
    );
  }
  return {
    taskDetailReducer,
    memberReducer,
    loaderReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      ...bindActionCreators(taskDetailActions, dispatch),
      ...bindActionCreators(memberActions, dispatch),
      ...bindActionCreators(loaderActions, dispatch),
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ArchivedTaskContainer)
);
