import React, { useState, useRef, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';
import Avatar from '@material-ui/core/Avatar';

import { imgTypes } from './../../utils/mime-type';
import defaultLogo from './../../images/productio-Circle.png';

export const UpdateProfileModal = ({
  show,
  onHide,
  updateProfile,
  isLoading,
  userInfo,
  setProfileInputValue,
  removeProfile,
}) => {
  const [state, setState] = useState({ file: defaultLogo, fileObj: null });

  const handleImageChange = (e) => {
    const fileObj = e.target.files[0];
    if (!imgTypes.includes(fileObj.type)) {
      Swal.fire('Error', `Select Only Image!`, 'warning');
      return;
    } else {
      const isGreater = Math.ceil(fileObj.size / 1024) > 5000;
      if (isGreater) {
        Swal.fire('Error', `File size exceeds 5 MB`, 'warning');
        return;
      }
      setProfileInputValue('file', fileObj);
      setState({
        file: URL.createObjectURL(fileObj),
        fileObj,
      });
    }
  };

  const onUpdateProfile = () => {
    if (!state.fileObj) {
      // alert('Popup Closed without Uploading Any Change!');
      onHide();
    } else {
      updateProfile(state.fileObj);
    }
  };

  useEffect(() => {
    if (userInfo && typeof userInfo === 'object' && userInfo !== undefined) {
      setState({ file: userInfo.photoURL ? userInfo.photoURL : defaultLogo });
    } else {
      setState({ file: defaultLogo });
    }
  }, [userInfo]);

  const handleRemoveProfile = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this company logo!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3a3a64',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      preConfirm: async () => {
        Swal.getTitle().innerHTML = 'Please Wait!';
        Swal.getConfirmButton().innerHTML = `Removing...`;
        Swal.getCancelButton().style.display = 'none';
        await removeProfile().then((res) => setState({ file: defaultLogo }));
      },
    }).then((res) => {
      if (res.value) {
        Swal.fire('Deleted!', 'Your Company Logo has been removed.', 'success');
      }
    });
  };

  const onHideHandler = () => {
    if (!state.file.startsWith('/static')) {
      if (state.file.startsWith('blob')) {
        setState({ file: defaultLogo });
      }
    }
    onHide();
  };

  const imageRef = useRef();
  return (
    <Modal
      show={show}
      onHide={onHideHandler}
      size='sm'
      className='modalForm'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      backdrop='static'
    >
      <Modal.Header closeButton className='m-0 pt-2 pb-2 companyModal'>
        <Modal.Title id='contained-modal-title-vcenter'>
          Update Company Logo
        </Modal.Title>
        <i
          className='fa fa-trash'
          onClick={() => handleRemoveProfile()}
          aria-hidden='true'
        ></i>
      </Modal.Header>
      <Modal.Body className='pt-0'>
        <form className='edit-form'>
          <div className='avatar-upload'>
            <div className='avatar-edit'>
              <input
                type='file'
                ref={imageRef}
                onChange={(e) => handleImageChange(e)}
                style={{ display: 'none' }}
                accept='image/*'
                id='imageUpload'
              />
              <label htmlFor='imageUpload'></label>
            </div>
            <div className='avatar-preview'>
              <div id='imagePreview'>
                <Avatar alt='Profile Img' src={state.file} />
              </div>
            </div>
          </div>
        </form>

        <Button
          onClick={() => onUpdateProfile()}
          className='savebutton'
          disabled={isLoading}
        >
          {isLoading ? (
            <i className='fa fa-spinner fa-spin' style={{ fontSize: '24px' }} />
          ) : (
            'Save'
          )}
        </Button>
      </Modal.Body>
    </Modal>
  );
};
