import * as taskDetailActions from './../actions/taskDetailActions';

export function taskDetailReducer(
  state = {
    inputValues: {
      assignBy: '',
      taskDetailTitle: '',
      taskDetailText: '',
      taskDetailPoints: '',
      taskDetailsGoalType: '',
      goalTypeQualifiers: {},
      taskDetailsPlanGroup: '',
      taskGroupOptions: [],
      empID: '',
      empUsername: '',
      taskDetailsPlanType: '',
      taskDetailsGoalOption: '',
      taskGroup: '',
      startDate: '',
      endDate: '',
      endTime: '',
      file: null,
      editorState: null,
      originalFiles: null,
      removedFiles: [],
      taskId: null,
    },
    savingTask: false,
    savedTask: false,
  },
  action
) {
  switch (action.type) {
    case 'SET_TASK_DETAIL_INPUT_VALUE':
      return {
        ...state,
        inputValues: {
          ...state.inputValues,
          [action.name]: action.value,
        },
      };
    case 'SET_GOAL_TYPE_OPTION_KEY':
      return {
        ...state,
        inputValues: {
          ...state.inputValues,
          goalTypeQualifiers: {
            ...state.inputValues.goalTypeQualifiers,
            [action.key]: '',
          },
        },
      };
    case 'SET_GOAL_TYPE_QUALIFIER_INPUT_VALUE':
      return {
        ...state,
        inputValues: {
          ...state.inputValues,
          goalTypeQualifiers: {
            ...state.inputValues.goalTypeQualifiers,
            [action.name]: action.value,
          },
        },
      };
    case 'CLEAR_INPUT_VALUES':
      return {
        ...state,
        inputValues: {
          assignBy: '',
          taskDetailTitle: '',
          taskDetailText: '',
          taskDetailPoints: '',
          taskDetailsGoalType: '',
          goalTypeQualifiers: {},
          taskDetailsPlanGroup: '',
          taskGroupOptions: [],
          taskGroup: '',
          empID: '',
          empUsername: '',
          taskDetailsPlanType: '',
          taskDetailsGoalOption: '',
          startDate: '',
          endDate: '',
          endTime: '',
          file: '',
          editorState: null,
          originalFiles: null,
          removedFiles: [],
          taskId: null,
        },
      };
    case 'SAVING_TASK':
      return {
        ...state,
        savingTask: action.savingTask,
      };
    case 'SAVED_TASK':
      return {
        ...state,
        savedTask: action.savedTask,
      };
    case 'SET_TASK_GROUPS':
      return {
        ...state,
        inputValues: {
          ...state.inputValues,
          taskGroupOptions: [...action.taskGroups],
        },
      };
    case taskDetailActions.CLEAR_INPUT_FILES:
      state.inputValues.file = action.file;
      return {
        ...state,
        inputValues: {
          ...state.inputValues,
        },
      };

    case taskDetailActions.CLEAR_ORIGINAL_FILES:
      state.inputValues.originalFiles = action.originalFiles;
      return {
        ...state,
        inputValues: {
          ...state.inputValues,
        },
      };

    case taskDetailActions.SET_REMOVE_FILES:
      state.inputValues.removedFiles = action.removedFiles;
      return {
        ...state,
        inputValues: {
          ...state.inputValues,
        },
      };

    case 'GET_TASKS':
      return {
        ...state,
        tasks: action.tasks,
      };
    case taskDetailActions.CLEAR_TASKS:
      return {
        ...state,
        tasks: [],
      };
    case taskDetailActions.CLEAR_MODAL_INPUT_VALUES:
      return {
        ...state,
        inputValues: {
          assignBy: '',
          taskDetailTitle: '',
          taskDetailText: '',
          taskDetailPoints: '',
          taskDetailsGoalType: '',
          goalTypeQualifiers: {},
          taskDetailsPlanGroup: '',
          taskGroup: '',
          empID: '',
          empUsername: '',
          taskDetailsPlanType: '',
          taskDetailsGoalOption: '',
          taskGroupOptions: [...state.inputValues.taskGroupOptions],
          startDate: '',
          endDate: '',
          endTime: '',
          file: '',
          editorState: null,
          originalFiles: null,
          removedFiles: [],
          taskId: null,
        },
      };
    default:
      return state;
  }
}
