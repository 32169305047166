import { combineReducers } from 'redux';
import { appReducer } from './appReducer';
import { planReducer } from './planReducer';
import { goalReducer } from './goalReducer';
import { memberReducer } from './memberReducer';
import { taskDetailReducer } from './taskDetailReducer';
import { taskGroupReducer } from './taskGroupReducer';
import { companyReducer } from './companyReducer';
import { loaderReducer } from './loaderReducer';
import { profileReducer } from './profileReducer';
import { storyboardReducer } from './storyboardReducer';

export default combineReducers({
  appReducer,
  planReducer,
  goalReducer,
  taskDetailReducer,
  taskGroupReducer,
  memberReducer,
  companyReducer,
  loaderReducer,
  profileReducer,
  storyboardReducer
});
