/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import ShowMoreText from 'react-show-more-text';
import { Dropdown } from 'react-bootstrap';
import LazyLoad from 'react-lazyload';
import download from 'downloadjs';

import defaultImg from '../../images/defaultProfile.png';
import defaultFileImg from '../../images/File_icon.png';
import {
  docExtentionType,
  imgExtentionTypes,
  videoExtentionType,
} from './../../utils/mime-type';
import TaskDescriptionModal from '../modals/taskDescriptionModal';
import { convertHTMLToText } from './../../redux/actions/taskDetailActions';
import {
  getTasksByCompanyId,
  getUsersByCompanyId,
  getActiveUserByEmpTaskGroupId,
} from './storyboardService';

const TasksContainer = ({
  selectedTask,
  fetchTasks,
  onEdit,
  onDelete,
  onFilter,
  search_task,
  filterBy,
  selectedUsersByTask,
}) => {
  const [placeholder, setPlaceholder] = useState('Search by Title');
  const [state, setState] = useState({ tasks: [] });
  const [showDescriptionModal, setShowDescriptionModal] = useState(false);
  const [modalDescription, setModalDescription] = useState(null);

  useEffect(() => {
    if (fetchTasks && fetchTasks !== undefined) {
      let tasks = checkTaskIsComplete(fetchTasks, selectedTask);
      tasks = _.orderBy(tasks, ['createdAt'], ['desc']);
      setState({ tasks: tasks });
    }
  }, [fetchTasks]);

  const handleShowDescriptionModal = (task) => {
    setModalDescription(task);
    setShowDescriptionModal(true);
  };

  const checkTaskIsComplete = (tasks, selectedTask) => {
    if (selectedTask && selectedTask !== undefined) {
      let allTasks = getTasksByCompanyId(tasks);
      let allUsers = getUsersByCompanyId(selectedUsersByTask);
      let usersArray = getActiveUserByEmpTaskGroupId(allUsers, selectedTask);
      let notCompleteTasksArray = [];
      if (allTasks) {
        _.forEach(allTasks, (task) => {
          if (task && task.assignBy === 'employee') {
            let filterUser = _.filter(allUsers, ['username', task.username]);
            if (filterUser) {
              _.forEach(filterUser, (u) => {
                if (u && u.tasks) {
                  _.forEach(u.tasks, (utask) => {
                    if (utask && utask.id) {
                      if (
                        task.id === utask.id &&
                        utask.isComplete === false &&
                        utask.isDeleted === false
                      ) {
                        notCompleteTasksArray.push(task);
                      }
                    }
                  });
                }
              });
            }
          } else if (task && task.assignBy === 'taskGroup') {
            let taskGroupUsers = [];
            if (task && task.taskGroupUsers)
              _.forEach(task.taskGroupUsers, (tgUsers) => {
                if (tgUsers && tgUsers.id) {
                  _.forEach(usersArray, (user) => {
                    if (user && user.id && user.id === tgUsers.id) {
                      taskGroupUsers.push(user);
                    }
                  });
                }
              });
            if (taskGroupUsers) {
              let taskGroupUsersLength = taskGroupUsers.length;
              let isCompleteTasksLength = 0;
              let isDeletedTasksLength = 0;
              _.forEach(taskGroupUsers, (user) => {
                if (user && user.tasks) {
                  _.forEach(user.tasks, (utask) => {
                    if (utask && utask.id && utask.assignBy === 'taskGroup') {
                      if (
                        (task.id === utask.id && utask.isComplete === true) ||
                        (task.id === utask.id && utask.isDeleted === true)
                      ) {
                        if (utask.isComplete) isCompleteTasksLength++;
                        if (utask.isDeleted) isDeletedTasksLength++;
                      }
                    }
                  });
                }
              });
              let isCompleted = false;
              let isDeleted = false;
              isCompleted =
                taskGroupUsersLength === isCompleteTasksLength ? true : false;
              isDeleted =
                taskGroupUsersLength === isDeletedTasksLength ? true : false;
              if (!isCompleted && !isDeleted) {
                if (!_.some(notCompleteTasksArray, ['id', task.id])) {
                  notCompleteTasksArray.push(task);
                }
              }
            }
          }
        });
      }
      notCompleteTasksArray &&
        _.map(notCompleteTasksArray, (task) => {
          if (task) {
            if (task.isComplete) task.isComplete = false;
            if (task.isDeleted) task.isDeleted = false;
          }
        });
      return notCompleteTasksArray;
    }
  };

  const handleSetOption = (key, taskObj) => {
    let myState = [...state.tasks];
    myState = getTasksByCompanyId(myState);
    _.forEach(myState, (obj) => {
      if (obj.id === taskObj.id) {
        obj.selectLable = key.empName;
        // Employee
        if (taskObj.assignBy === 'employee') {
          if (key && key.tasks) {
            _.forEach(key.tasks, (utask) => {
              if (utask && utask.id) {
                if (taskObj.id === utask.id && utask.assignBy === 'employee') {
                  obj.isComplete = utask.isComplete ? true : false;
                  obj.isDeleted = utask.isDeleted ? true : false;
                }
              }
            });
          }
        }
        // TaskGroup
        else if (taskObj && taskObj.assignBy === 'taskGroup') {
          if (key && key.tasks) {
            _.forEach(key.tasks, (utask) => {
              if (utask && utask.id) {
                if (taskObj.id === utask.id && utask.assignBy === 'taskGroup') {
                  obj.isComplete = utask.isComplete ? true : false;
                  obj.isDeleted = utask.isDeleted ? true : false;
                }
              }
            });
          }
        }
      }
    });
    setState((prevState) => {
      return { ...prevState, ...myState };
    });
  };

  const CheckFunction = (e) => {
    window.jQuery('.isTaskActive').removeClass('isTaskActive');
    window.jQuery('#' + e.currentTarget.id).addClass('isTaskActive');
    filterBy(e.currentTarget.id);
    if (e.currentTarget.id === 'TaskGroup') {
      setPlaceholder('Search By Title');
    } else if (e.currentTarget.id === 'startDate') {
      setPlaceholder('Search By Start Date');
    } else {
      setPlaceholder('Search by ' + e.currentTarget.id);
    }
  };

  const showTasks = (tasks) => {
    // _.orderBy(tasks, ['createdAt'], ['asc']);
    _.map(tasks, (task) => {
      task.file = task.file ? task.file : defaultFileImg;
      task.taskDescriptionString = task.description
        ? convertHTMLToText(task.description)
        : '';
      const file = task.file.split('?alt')[0];
      task.isValidImg = imgExtentionTypes.indexOf(file.split('.').pop(-1)) >= 0;
      task.isDocument = docExtentionType.indexOf(file.split('.').pop(-1)) >= 0;
      task.isVideo = videoExtentionType.indexOf(file.split('.').pop(-1)) >= 0;
    });

    const showEmployee = (obj) => {
      let usersArray = [];
      let allUsers = getUsersByCompanyId(selectedUsersByTask);
      if (selectedUsersByTask && selectedUsersByTask.length) {
        usersArray = selectedUsersByTask.filter((user) => {
          if (user.empTaskGroupId && user.empTaskGroupId !== '') {
            return user.empTaskGroupId === obj.taskGroupId;
          }
        });
        let finalArray = [];
        let newUsersArray = usersArray;
        if (obj.assignBy === 'taskGroup') {
          if (obj.taskGroupUsers) {
            obj.taskGroupUsers.forEach((u) => {
              if (u) {
                newUsersArray.forEach((user) => {
                  if (user) {
                    if (u.id === user.username) {
                      finalArray.push(user);
                    }
                  }
                });
              }
            });
          } else {
            _.forEach(newUsersArray, (user) => {
              if (user) {
                if (user.tasks !== undefined && user.tasks.length > 0) {
                  user.tasks.forEach((t) => {
                    if (t) {
                      if (t.id === obj.id && t.assignBy === obj.assignBy) {
                        finalArray.push(user);
                      }
                    }
                  });
                }
              }
            });
          }
        } else if (obj.assignBy === 'employee') {
          finalArray = newUsersArray.filter(
            (user) => user.username === obj.username
          );
          if (finalArray.length === 0) {
            finalArray = allUsers.filter(
              (user) => user.username === obj.username
            );
          }
        }
        if (finalArray && finalArray.length > 0) {
          return (
            <Dropdown.Menu>
              {finalArray.map((user, i) => (
                <Dropdown.Item
                  eventKey={i}
                  id={user.uid}
                  key={i}
                  onClick={() => handleSetOption(user, obj)}
                >
                  {user.empName}
                  <LazyLoad
                    key={user.id}
                    height={150}
                    placeholder={
                      <img
                        alt='employee img'
                        className='employee-img'
                        src={defaultImg}
                      />
                    }
                  >
                    <img
                      alt='employee img'
                      className='employee-img'
                      src={user.photoURL || defaultImg}
                    />
                  </LazyLoad>
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          );
        } else {
          return (
            <Dropdown.Menu>
              <Dropdown.Item eventKey={'user'} id={'user.uid'} key={'i'}>
                No Users Found!
              </Dropdown.Item>
            </Dropdown.Menu>
          );
        }
      }
    };

    if (tasks) {
      const data = _.map(tasks, (task, i) => (
        <tr key={i}>
          <td style={{ wordBreak: 'break-all' }}>
            {((task.fileType && task.fileType === 'image') ||
              task.isValidImg) && (
              <img
                src={task.file}
                className='employee-img'
                alt={task.username}
              />
            )}{' '}
            {((task.fileType && task.fileType === 'document') ||
              task.isDocument) && (
              <span style={{ color: '#212529', paddingRight: '8px' }}>
                <i
                  className='fa fa-file'
                  style={{
                    marginLeft: '7px',
                    marginTop: '7px',
                    fontSize: '21px',
                    cursor: 'pointer',
                  }}
                />
              </span>
            )}
            {((task.fileType && task.fileType === 'video') || task.isVideo) && (
              <span style={{ color: '#212529' }}>
                <i
                  className='fa fa-video-camera'
                  style={{
                    marginLeft: '7px',
                    marginTop: '7px',
                    fontSize: '21px',
                    cursor: 'pointer',
                  }}
                />
              </span>
            )}
            {task.title}{' '}
          </td>
          <td style={{ fontSize: '13px', padding: '0px' }}>
            <Dropdown drop='down'>
              <Dropdown.Toggle className='dropDownSelect' id='dropdown-basic'>
                {task.selectLable}
                {showEmployee(task)}
              </Dropdown.Toggle>
            </Dropdown>
          </td>
          <td style={{ textAlign: 'center' }} className='htmlContent'>
            <i
              onClick={() => handleShowDescriptionModal(task)}
              className='fa fa-eye'
              aria-hidden='true'
              style={{
                fontSize: '19px',
              }}
            ></i>{' '}
            {/* <div>View Detail</div> */}
            {/* <ShowMoreText
              lines={3}
              more='Show More'
              less='Show Less'
              anchorClass=''
              expanded={false}
              width={250}
            >
              {task.taskDescriptionString}
            </ShowMoreText> */}
          </td>
          <td className='selectTask'>
            {' '}
            <select className='border-50' style={{ marginLeft: '-5px' }}>
              <option disabled value=''>
                Please Select
              </option>
              <option value={task.startDate}>
                Start Date: {task.startDate}
              </option>
              <option value={task.endDate}> End Date: {task.endDate}</option>
            </select>
          </td>
          <td className='padding-body-right'>
            {(task.isComplete && 'Completed') ||
              (task.isDeleted && 'Deleted') ||
              (!task.isComplete && !task.isDeleted && 'N/A')}
          </td>
          <td className='action-body'>
            <a
              onClick={() => onEdit(task)}
              className='d-inline green-icon mr-2'
            >
              <i className='fa fa-pencil'></i>
            </a>
            <a className='d-inline red-icon' onClick={() => onDelete(task)}>
              <i className='fa fa-trash-o'></i>
            </a>
          </td>
        </tr>
      ));
      if (tasks.length === 0 && tasks !== null) {
        return (
          <tr>
            <td
              style={{ fontSize: '14px' }}
              className='text-center'
              colSpan='8'
            >
              No Tasks Found...{' '}
            </td>
          </tr>
        );
      }
      return data;
    }
  };

  return (
    <>
      <div className='taskcontent pl-4'>
        <h5 className='font-weight-bold d-inline'>
          Task
          <span className='font-weight-normal'>
            {' '}
            /{' '}
            {selectedTask &&
            selectedTask.name &&
            selectedTask.name !== undefined
              ? selectedTask.name
              : 'N/A'}
          </span>
        </h5>
        <span className='inputfloat pull-right'>
          <input
            type='text'
            className='form-control inputform'
            placeholder={placeholder}
            value={search_task}
            onChange={(e) => onFilter(e)}
          />
        </span>
        <hr className='mb-3' />
        <div className='table-responsive'>
          <table className='table tasktable tablegoal table-head-fixed table-resposnive-width1'>
            <thead className='thead-light1'>
              <tr id='tableTask'>
                <th
                  className='tasktitle1 isTaskActive'
                  id='title'
                  onClick={(e) => CheckFunction(e)}
                  style={{ width: '17%' }}
                >
                  Title
                  <i className='fa fa-filter filter-icon1'></i>
                </th>
                <th style={{ width: '20%' }}>Employee list</th>
                <th style={{ width: '18%' }}>Description</th>
                <th
                  style={{ width: '22%' }}
                  className=''
                  id='startDate'
                  onClick={(e) => CheckFunction(e)}
                >
                  Duration <i className='fa fa-filter filter-icon1'></i>
                </th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <div className='available-items available12'>
              <tbody
                style={{
                  height: state.tasks && state.tasks.length === 1 ? 110 : '',
                }}
              >
                {showTasks(state.tasks)}
              </tbody>
            </div>
          </table>
        </div>
      </div>
      {showDescriptionModal && (
        <TaskDescriptionModal
          onShow={showDescriptionModal}
          task={modalDescription}
          onHide={() => setShowDescriptionModal(false)}
        />
      )}
    </>
  );
};

export default TasksContainer;
