import React, { useState, useRef, useEffect } from 'react';
import _ from 'lodash';
import { Editor } from 'react-draft-wysiwyg';
import DatePicker from 'react-datepicker';
import Picker from 'react-emojipicker';
import Avatar from '@material-ui/core/Avatar';
import { Modal, Button } from 'react-bootstrap';

import { docType, imgTypes, videoTypes } from '../../utils/mime-type';
import GoalTypeQualifiers from '../GoalTypeQualifiers';
import Swal from 'sweetalert2';

const getGoalTypeQualifiers = (
  inputValues,
  onInputChange,
  getGoalTypeOptionKeys, // Dont't Remove it will break app
  goals
) => {
  let chosenGoalType = inputValues.taskDetailsGoalType;
  return (
    <GoalTypeQualifiers
      chosenGoalType={chosenGoalType}
      onInputChange={onInputChange}
      inputValues={inputValues}
      goals={goals}
    />
  );
};

const StoryboardTaskEditModal = ({
  handleSubmit,
  onInputChange,
  onQualifierInputChange,
  getGoalTypeOptionKeys,
  inputValues,
  savingTask,
  savedTask,
  goals,
  employees,
  clearTaskFiles,
  taskGroupOptions,
  onShow,
  onHide,
  removeOriginalFile,
}) => {
  let emojiValue = '';
  const [isEmojiOpen, toggleIsEmoji] = useState(false);
  const handleEmojiToggle = () => {
    const isShow = isEmojiOpen ? false : true;
    toggleIsEmoji(isShow);
  };
  let imageRef = useRef();
  if (imageRef.current !== undefined && imageRef.current !== null) {
    if (!savingTask && !!savedTask) {
      imageRef.current.value = null;
    }
  }

  const handleImageChange = (e) => {
    const orginalFiles = inputValues.file && [...inputValues.file];
    let files = [...e.target.files];
    if(orginalFiles && orginalFiles.length > 0) {
      files = [...orginalFiles, ...files];
    }
    if (files.length === 1) {
      const file = e.target.files[0];
      if (file) {
        const acceptedTypes = [...videoTypes, ...docType.concat(imgTypes)];
        if (!acceptedTypes.includes(file.type)) {
          Swal.fire('Error', `Please Select Valid File!`, 'warning');
          imageRef.current.value = null;
          return;
        } else {
          onInputChange('file', files);
        }
      }
    } else if (files.length > 1) {
      if (files) {
        let validFiles = [];
        _.forEach(files, (file, i) => {
          const acceptedTypes = [...videoTypes, ...docType.concat(imgTypes)];
          if (!acceptedTypes.includes(file.type)) {
            Swal.fire('Error', `Please Select Valid File!`, 'warning');
            imageRef.current.value = null;
            return;
          } else {
            validFiles.push(file);
          }
        });
        onInputChange('file', validFiles);
      }
    } else {
      Swal.fire('Error', `Please Select Valid File!`, 'warning');
      return;
    }
  };

  const [goalOptions, setGoalOptions] = useState([]);

  const handleGoalOptions = (name, value) => {
    const goal = goals.filter((f) => f.id === value)[0];
    let filterOptions;
    if (goal) {
      filterOptions = goal.options ? Object.values(goal.options) : [];
    } else {
      filterOptions = [];
    }
    setGoalOptions(filterOptions);
    onInputChange(name, value);
    onInputChange('taskDetailsGoalOption', '');
  };

  const handleGoals = (name, value) => {
    onInputChange(name, value);
    onInputChange('taskDetailsGoalType', '');
    onInputChange('taskDetailsGoalOption', '');
  };

  const handleOptionsOnLoad = () => {
    const goal = goals.find((g) => g.id === inputValues.taskDetailsGoalType);
    if (goal && goal.options) {
      setGoalOptions(goal.options);
    }
  };

  const handleChangeAssignBy = (name, value) => {
    onInputChange(name, value);
    onInputChange('empID', '');
    onInputChange('taskGroup', '');
    onInputChange('taskDetailsGoalType', '');
    onInputChange('taskDetailsGoalOption', '');
  };

  useEffect(() => {
    handleOptionsOnLoad();
  }, []);

  return (
    <Modal
      show={onShow}
      onHide={onHide}
      size='lg'
      className=''
      aria-labelledby='contained-modal-title-vcenter'
      centered
      backdrop='static'
    >
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>Edit Task</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          <form id='task-details-form' className='task-details-form taskform'>
            <div className='row mb-2'>
              <div className='col col-sm-12'>
                <select
                  type='select'
                  id='assignBy'
                  name='assignBy'
                  required
                  onChange={(e) =>
                    handleChangeAssignBy(e.target.name, e.target.value)
                  }
                  value={inputValues.assignBy}
                >
                  <option value='' style={{ fontWeight: 'bold' }} disabled>
                    Assign By
                  </option>
                  <option id='employee' value='employee'>
                    Member
                  </option>
                  <option id='taskGroup' value='taskGroup'>
                    Strategy
                  </option>
                </select>
              </div>
            </div>
            {inputValues.assignBy && inputValues.assignBy === 'employee' ? (
              <div className='row mb-2'>
                <div className='col col-sm-12'>
                  <select
                    type='select'
                    id='empID'
                    name='empID'
                    required
                    onChange={(e) =>
                      onInputChange(e.target.name, e.target.value)
                    }
                    // value={inputValues.empID}
                  >
                    <option value='' style={{ fontWeight: 'bold' }}>
                      Select Member ID
                    </option>
                    {employees.map((emp, i) => (
                      <option
                        selected={inputValues.empID === emp.empName}
                        key={i}
                        id={emp.uid}
                        value={`${emp.empName}-${emp.username}`}
                      >
                        {emp.empName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            ) : null}

            <div className='row mb-2'>
              <div className='col-md-4 col-sm-12'>
                <input
                  required
                  type='text'
                  id='taskDetailTitle'
                  name='taskDetailTitle'
                  style={{ paddingRight: '28px' }}
                  placeholder='Project title'
                  onChange={(e) => onInputChange(e.target.name, e.target.value)}
                  ref={(ref) => (emojiValue = ref)}
                  value={inputValues.taskDetailTitle}
                />

                <i
                  style={{
                    position: 'absolute',
                    right: '20px',
                    top: '11px',
                    fontSize: '21px',
                    cursor: 'pointer',
                  }}
                  className='fa fa-smile-o'
                  aria-hidden='true'
                  onClick={() => handleEmojiToggle()}
                />
                {isEmojiOpen ? (
                  <Picker
                    onEmojiSelected={(emoji) => {
                      emojiValue.value = emojiValue.value + emoji.unicode;
                      inputValues.taskDetailTitle = emojiValue.value;
                      handleEmojiToggle();
                    }}
                  />
                ) : (
                  false
                )}
              </div>

              <div className='col-md-4 col-sm-12'>
                <input
                  required
                  type='number'
                  id='taskDetailPoints'
                  name='taskDetailPoints'
                  placeholder='Points'
                  onChange={(e) => onInputChange(e.target.name, e.target.value)}
                  value={inputValues.taskDetailPoints}
                />
              </div>

              <div className='col-md-4 col-sm-12'>
                <select
                  required
                  type='select'
                  id='taskGroup'
                  name='taskGroup'
                  onChange={(e) => handleGoals(e.target.name, e.target.value)}
                  value={inputValues.taskGroup}
                >
                  <option value='' style={{ fontWeight: 'bold' }}>
                    Select Strategy
                  </option>
                  {taskGroupOptions &&
                    taskGroupOptions.map((taskGroup, i) => (
                      <option key={i} id={taskGroup.id} value={taskGroup.id}>
                        {taskGroup.name}
                      </option>
                    ))}
                </select>
              </div>
            </div>

            <div className='row mb-2'>
              <div className='col-md-6 col-sm-12'>
                <select
                  required
                  type='select'
                  id='taskDetailsGoalType'
                  name='taskDetailsGoalType'
                  onChange={(e) =>
                    handleGoalOptions(e.target.name, e.target.value)
                  }
                  value={inputValues.taskDetailsGoalType}
                >
                  <option value='' style={{ fontWeight: 'bold' }}>
                    Select Goal Type
                  </option>
                  {goals &&
                    Object.values(goals).map((goal, i) => (
                      <option key={i} value={goal.id}>
                        {goal.name}
                      </option>
                    ))}
                </select>
              </div>

              <div className='col-md-6 col-sm-12'>
                <select
                  required
                  type='select'
                  id='taskDetailsGoalOption'
                  name='taskDetailsGoalOption'
                  onChange={(e) => onInputChange(e.target.name, e.target.value)}
                  value={inputValues.taskDetailsGoalOption}
                >
                  <option value='' style={{ fontWeight: 'bold' }}>
                    Select Option Type
                  </option>
                  {goalOptions &&
                    goalOptions.map((option, i) => (
                      <option key={i} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div className='row mb-2'>
              <div className='col-md-4 col-sm-12'>
                <DatePicker
                  required
                  minDate={new Date()}
                  selected={
                    inputValues.startDate &&
                    typeof inputValues.startDate !== 'string'
                      ? inputValues.startDate
                      : null
                  }
                  placeholderText='Start Date'
                  onChange={(data) => onInputChange('startDate', data)}
                />
              </div>

              <div className='col-md-4 col-sm-12'>
                <DatePicker
                  required
                  minDate={new Date()}
                  selected={
                    inputValues.endDate &&
                    typeof inputValues.endDate !== 'string'
                      ? inputValues.endDate
                      : null
                  }
                  placeholderText='End Date'
                  disabled
                  onChange={(data) => onInputChange('endDate', data)}
                />
              </div>

              <div className='col-md-4 col-sm-12'>
                <DatePicker
                  placeholderText='Select Time'
                  required
                  selected={
                    inputValues.endTime &&
                    typeof inputValues.endTime !== 'string'
                      ? inputValues.endTime
                      : null
                  }
                  onChange={(data) => onInputChange('endTime', data)}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption='Time'
                  dateFormat='h:mm aa'
                />
              </div>
            </div>
            <div className='row mb-2'>
              <div className='col'>
                <div className='form-group files'>
                  <input
                    type='file'
                    onChange={(e) => handleImageChange(e)}
                    className='form-control'
                    id='isImg'
                    ref={imageRef}
                    multiple
                  />
                  <div className='row mt-3 pl-1'>
                    {inputValues.originalFiles &&
                      inputValues.originalFiles.map((file, i) => (
                        <>
                          <div className='col-2' key={i}>
                            {((file && file.type && file.type === 'image') ||
                              (file && file.isImage)) && (
                              <div id='taskImages'>
                                <Avatar alt='Profile Img' src={file.url} />
                              </div>
                            )}
                            {((file && file.type && file.type === 'document') ||
                              (file && file.isDocument)) && (
                              <div className='choosepdf'>
                                <i className='fa fa-file' /> {file.name}
                              </div>
                            )}
                            {((file && file.type && file.type === 'video') ||
                              (file && file.isVideo)) && (
                              <div className='choosepdf'>
                                <i className='fa fa-video-camera' /> {file.name}
                              </div>
                            )}
                            {file && (
                              <i
                                className='fa fa-times imageicon'
                                onClick={() => {
                                  removeOriginalFile(file);
                                }}
                              ></i>
                            )}
                          </div>
                        </>
                      ))}
                    {inputValues.file &&
                      [...inputValues.file].map((file, i) => (
                        <>
                          <div className='col-2' key={i}>
                            {file.type.includes('application') ? (
                              <div className='choosepdf'>
                                <i className='fa fa-file' />
                                {file.name}
                              </div>
                            ) : (
                              <div id='taskImages'>
                                <Avatar
                                  alt='Profile Img'
                                  src={URL.createObjectURL(file)}
                                />
                              </div>
                            )}
                            <i
                              className='fa fa-times imageicon'
                              onClick={() => {
                                clearTaskFiles(file);
                                imageRef.current.value = null;
                              }}
                            ></i>
                          </div>
                        </>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            <div className='row mb-2'>
              <div className='col linkmodal'>
                <Editor
                  editorState={inputValues.editorState}
                  toolbarClassName='toolbarClassName'
                  wrapperClassName='wrapperClassName'
                  editorClassName='editorClassName'
                  onEditorStateChange={(e) => onInputChange('editorState', e)}
                />
              </div>
            </div>
            <div>
              {inputValues.taskDetailsGoalType &&
                getGoalTypeQualifiers(
                  inputValues,
                  onQualifierInputChange,
                  getGoalTypeOptionKeys,
                  goals
                )}
            </div>
          </form>
        </>
      </Modal.Body>
      <Modal.Footer>
        <div className='row'>
          <div className='col-xl-3 col-lg-4 col-md-4 col-sm-4 col-4'>
            <Button
              className='savebutton'
              style={{ fontSize: '16px', padding: '10px' }}
              disabled={!!savingTask}
              onClick={() => handleSubmit()}
            >
              {!!savingTask && 'Updating...'}
              {!savingTask && !!savedTask && 'Updated!'}
              {!savingTask && !savedTask && 'Update'}
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default StoryboardTaskEditModal;
