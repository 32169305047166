import * as jQuery from 'jquery';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

// Static Images
import addMember from '../../images/add-member.png';
import AddTask from '../../images/Add-task.png';
import addGoals from '../../images/AddGoals.png';
import Employee from '../../images/employee-details.png';
import logout from '../../images/logout.png';
import Story from '../../images/storyboard.png';
import taskGroup from '../../images/Task-Group.png';

// Actions
import { db, fba } from '../../firebase';
import * as companyAction from './../../redux/actions/companyActions';
import * as goalActions from './../../redux/actions/goalActions.js';
import * as memberActions from './../../redux/actions/memberActions.js';
import * as planActions from './../../redux/actions/planActions.js';
import * as taskDetailActions from './../../redux/actions/taskDetailActions';
import Swal from 'sweetalert2';

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      companySelected: '',
    };
  }

  menuIcon = () => {
    jQuery('.hit_sidebar').toggleClass('mobileshow');
  };

  async componentDidMount() {
    const userType = localStorage.getItem('userType');
    if (userType === 'SuperAdmin') {
      this.props.fetchCompanys();
    }
  }

  handleChange = async (e) => {
    try {
      this.setState({
        companySelected: e.target.value,
      });
      const snapshot = await db.ref(`users`).once('value');
      let users = snapshot.val();
      var list = Object.values(users);
      let li = _.filter(list, (obj) => obj.uid === this.state.companySelected);
      if (li && li.length !== 0) {
        localStorage.setItem('companyID', li[0].key);
        localStorage.setItem('company', li[0].company);
        let companyId = li[0].key;
        const {
          fetchTaskGroups,
          fetchGoals,
          fetchPlans,
          fetchEmp,
          fetchAllTasks,
        } = this.props.actions;
        fetchEmp(companyId);
        fetchGoals(companyId);
        fetchPlans(companyId);
        fetchTaskGroups(companyId);
        fetchAllTasks(companyId);
      } else {
        Swal.fire('Error', 'No Data Found', 'error');
      }
    } catch (error) {
      console.error(error);
    }
  };

  handleLogout = () => {
    fba.signOut().then(() => {
      localStorage.removeItem('persist:root');
      localStorage.removeItem('isLogin');
      localStorage.removeItem('companyID');
      localStorage.removeItem('company');
      localStorage.removeItem('userType');
      localStorage.removeItem('username');
      this.props.history.push('/');
    });
  };

  render() {
    const pathName = this.props.location.pathname;
    const userType = localStorage.getItem('userType');
    let list = [];
    if (userType === 'SuperAdmin') {
      list = Object.values(this.props.companyReducer.companyList);
    }
    return (
      <aside className='hit_sidebar open_sidbar sidebar-slide-push'>
        {userType === 'SuperAdmin' ? (
          <div className='form-group'>
            <select
              type='select'
              id='companyName'
              name='companyName'
              style={{ width: '92%', margin: '0px 8px' }}
              className='mt-3 p-2 form-control'
              onChange={this.handleChange}
              defaultValue=''
            >
              <option disabled value='' style={{ fontWeight: 'bold' }}>
                Select Company List:
              </option>
              {list.map((obj) => (
                <option key={obj.cuid} value={obj.cuid}>
                  {obj.userCompanyname}
                </option>
              ))}
            </select>
          </div>
        ) : null}
        {userType === 'SuperAdmin' &&
        this.state.companySelected === '' ? null : (
          <ul id='sidebarId'>
            <li className={pathName === '/admin/storyboard' ? 'active' : ''}>
              <NavLink
                activeClassName='link-is-active'
                replace
                exact
                to={'/admin/storyboard'}
                onClick={this.menuIcon}
              >
                <span className='nav-icon'>
                  <img src={Story} alt='' />
                </span>
                <span className='remove_text'>STORYBOARD</span>
              </NavLink>
            </li>
            <li className={pathName === '/admin/taskGroups' ? 'active' : ''}>
              <NavLink
                activeClassName='link-is-active'
                replace
                exact
                to={'/admin/taskGroups'}
                onClick={this.menuIcon}
              >
                <span className='nav-icon'>
                  <img src={taskGroup} alt='' />
                </span>
                <span className='remove_text'>STRATEGY</span>
              </NavLink>
            </li>
            <li className={pathName === '/admin/goals' ? 'active' : ''}>
              <NavLink
                activeClassName='link-is-active'
                replace
                exact
                to={'/admin/goals'}
                onClick={this.menuIcon}
              >
                <span className='nav-icon'>
                  <img src={addGoals} alt='' />
                </span>
                <span className='remove_text'>ADD GOALS</span>
              </NavLink>
            </li>
            <li className={pathName === '/admin/tasks' ? 'active' : ''}>
              <NavLink
                activeClassName='link-is-active'
                replace
                exact
                to={'/admin/tasks'}
                onClick={this.menuIcon}
              >
                <span className='nav-icon'>
                  <img src={AddTask} alt='' />
                </span>
                <span className='remove_text'>ADD TASKS</span>
              </NavLink>
            </li>
            <li className={pathName === '/admin/member' ? 'active' : ''}>
              <NavLink
                activeClassName='link-is-active'
                replace
                exact
                to={'/admin/member'}
                onClick={this.menuIcon}
              >
                <span className='nav-icon'>
                  <img src={addMember} alt='' />
                </span>
                <span className='remove_text'>ADD MEMBER</span>
              </NavLink>
            </li>
            {/*<li>
                <NavLink activeClassName="link-is-active" replace  exact to={"/admin/plans"} onClick={this.menuIcon}>
                  <span className="nav-icon">
                    <img src={report} />
                  </span>
                  <span className="remove_text" >PLANS</span>
                </NavLink>
              </li>*/}
            <li
              className={pathName === '/admin/employee-details' ? 'active' : ''}
            >
              <NavLink
                activeClassName='link-is-active'
                replace
                exact
                to={'/admin/employee-details'}
                onClick={this.menuIcon}
              >
                <span className='nav-icon'>
                  <img src={Employee} alt='' />
                </span>
                <span className='remove_text'>MEMBERS</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                activeClassName='link-is-active'
                replace
                exact
                to={'/admin/archived-tasks'}
                onClick={this.menuIcon}
              >
                <span className='nav-icon'>
                  <img src={AddTask} alt='' />
                </span>
                <span className='remove_text'>TASK LIBRARY</span>
              </NavLink>
            </li>
            <li className='logoutlink'>
              <NavLink replace to={'/'} onClick={this.handleLogout}>
                <span className='nav-icon'>
                  <img src={logout} alt='' />
                </span>
                <span className='remove_text'>LOG OUT</span>
              </NavLink>
            </li>
          </ul>
        )}
      </aside>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    taskDetailReducer,
    goalReducer,
    planReducer,
    memberReducer,
    companyReducer,
  } = state;
  return {
    companyReducer,
    taskDetailReducer,
    goalReducer,
    planReducer,
    memberReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      ...bindActionCreators(taskDetailActions, dispatch),
      ...bindActionCreators(goalActions, dispatch),
      ...bindActionCreators(planActions, dispatch),
      ...bindActionCreators(memberActions, dispatch),
    },
    fetchCompanys: () => dispatch(companyAction.fetchCompanyList()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Sidebar)
);
