import Swal from 'sweetalert2';
import { db, storage, fba } from '../../firebase.js';

// Action Types
export const UPLOAD_PROFILE = '[Profile] Upload Profile Picture';
export const FETCH_PROFILE = '[Profile] Fetch Profile Picture';
export const SAVING_UPLOAD = '[Profile] Saving Upload';
export const SAVED_UPLOAD = '[Profile] Saved Upload';
export const SET_PROFILE_INPUT_VALUE = '[Profiel] Set Input Values';
export const CLEAR_PROFILE_INPUT_VALUE = '[Profile] Clear Profile Input Value';
export const SET_NOTIFICATION = '[Notification] Set Notification';
export const CLEAR_NOTIFICATIONS = '[Notification] Clear Notification';
export const CLEAR_PROFILE = '[Profile] Clear Profile';

// Effects
export const uploadProfilePhoto = (data) => async (dispatch, getState) => {
  const values = getState().profileReducer;
  const { inputValues } = values;
  savingUpload(true);
  var photoURL;
  if (inputValues.file && inputValues.file !== undefined) {
    if (typeof inputValues.file === 'object') {
      try {
        if (values.userInfo) {
          if (
            values.userInfo.photoURL &&
            values.userInfo.photoURL !== undefined &&
            values.userInfo.photoURL !== null
          ) {
            if (!values.userInfo.photoURL.startsWith('/static/')) {
              if (values.userInfo.photoURL && values.userInfo.photoURL !== '') {
                try {
                  await storage.refFromURL(values.userInfo.photoURL).delete();
                } catch (err) {
                  // console.error(err);
                }
              }
            }
          }
        }
        const fileData = await storage
          .ref(`profile/${inputValues.file.name}`)
          .put(inputValues.file);
        const imageSrc = await fileData.ref.getDownloadURL();
        photoURL = imageSrc;
        try {
          if (photoURL && photoURL !== '') {
            await fba.currentUser.updateProfile({ photoURL });
          }
        } catch (err) {
          console.error(err);
        }
        const userName = localStorage.getItem('username');
        const companyId = localStorage.getItem('companyID');
        const snapshot = await db.ref(`users/${userName}`).once('value');
        let userObj = null;
        if (snapshot.exists()) {
          userObj = snapshot.val();
          userObj.id = userName;
        }
        await db.ref(`users/${userObj.id}`).update({ photoURL });
        if (companyId) {
          await db
            .ref(`companyname/${companyId}`)
            .update({ companyURL: photoURL });
        }
        let response = null;
        await db
          .ref(`users/${userName}`)
          .once('value')
          .then((res) => {
            response = res.val();
            clearProfileInputValue();
            dispatch(fetchUserProfileAction(response));
            dispatch(savedUpload(false));
            dispatch(savedUpload(true));
          });
        return response;
      } catch (error) {
        // alert(error.message);
        return error;
      }
    }
  } else {
    const response = { close: true };
    return response;
  }
};

export const fetchProfile = () => async (dispatch) => {
  const userName = localStorage.getItem('username');
  const snapshot = await db.ref(`users/${userName}`).once('value');
  if (snapshot.exists()) {
    let userObj = snapshot.val();
    userObj.id = userName;
    dispatch(fetchUserProfileAction(userObj));
  }
};

export const removeProfile = () => async (dispatch, getState) => {
  const companyId = localStorage.getItem('companyID');
  const values = getState().profileReducer;
  if (!values.userInfo.photoURL) {
    Swal.fire('Error', `Profile picture not available`, 'warning');
    return (values['close'] = true);
  }
  if (values.userInfo.photoURL && values.userInfo.photoURL !== '') {
    try {
      await storage.refFromURL(values.userInfo.photoURL).delete();
    } catch (err) {
      console.error(err.message);
    }
  }
  const currentUserRef = fba.currentUser;
  await currentUserRef.updateProfile({ photoURL: null });
  const userName = localStorage.getItem('username');
  const snapshot = await db.ref(`users/${userName}`).once('value');
  let userObj = null;
  if (snapshot.exists()) {
    userObj = snapshot.val();
    userObj.id = userName;
  }
  await db.ref(`users/${userName}`).update({ photoURL: null });
  if (companyId) {
    await db.ref(`companyname/${companyId}`).update({ companyURL: null });
  }
  userObj.photoURL = null;
  dispatch(fetchUserProfileAction(userObj));
  return values;
};

// Actions
const fetchUserProfileAction = (payload) => ({
  type: FETCH_PROFILE,
  payload,
});

const savingUpload = (bool) => ({
  type: SAVING_UPLOAD,
  savingFile: bool,
});

const savedUpload = (bool) => ({
  type: SAVED_UPLOAD,
  savedFile: bool,
});

export const setProfileInputValue = (name, value) => {
  return {
    type: SET_PROFILE_INPUT_VALUE,
    name,
    value,
  };
};

export const clearProfileInputValue = () => {
  return {
    type: CLEAR_PROFILE_INPUT_VALUE,
  };
};

export const clearProfile = () => {
  return {
    type: CLEAR_PROFILE,
  };
};

// Notifications
export const setNotificationAction = (notification) => {
  return {
    type: SET_NOTIFICATION,
    payload: notification,
  };
};

export const setNotification = (notification, history) => async (
  dispatch,
  getState
) => {
  const values = getState().profileReducer;
  const currentUserId = values.userInfo.id;
  const key =
    values.notification.key && values.notification.key !== undefined
      ? values.notification.key
      : '';

  const publicRef = db.ref(`users/${currentUserId}/notifications/${key}/data/`);

  if (currentUserId && currentUserId !== '' && key && key !== '') {
    try {
      await publicRef.update({ isRead: true }).then(() => {});
    } catch (err) {
      console.error(err.message);
    }
  }

  if (values.notification && values.notification !== undefined) {
    await dispatch(clearNotifications());
  }
  if (history.location.pathname !== '/admin/storyboard') {
    await history.push('/');
  }
  await dispatch(clearNotifications());

  await dispatch(setNotificationAction(notification));
};

export const clearNotifications = () => {
  return {
    type: CLEAR_NOTIFICATIONS,
  };
};
