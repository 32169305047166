const initialState = {
  companyList: []
}

export function companyReducer(state = initialState, action) {
  switch (action.type) {
    case "COMPANY_LIST":
      return Object.assign({}, {
        companyList: action.companyList
      })
    default:
      return state
  }
}