import React, { Component } from 'react';
import _ from 'lodash';
import { Form, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';

import * as constanst from './../../constants';
import { db, fba } from '../../firebase.js';
import '../../css/common.css';
import Swal from 'sweetalert2';
export default class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      company: '',
      username: '',
      email: '',
      passwordOne: '',
      passwordTwo: '',
      role: 'Admin',
      error: null,
      validated: false,
      term_conditions: false,
      isLoading: false,
    };
  }

  handleSignup = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    const {
      firstName,
      lastName,
      email,
      company,
      username,
      passwordOne,
      passwordTwo,
      role,
      term_conditions,
    } = this.state;
    if (form.checkValidity() === true) {
      if (passwordOne !== passwordTwo) {
        return Swal.fire(
          'Error',
          `Password and confirm password does not match`,
          'warning'
        );
      } else if (!term_conditions) {
        Swal.fire(
          'Error',
          `Password and confirm password does not match`,
          'warning'
        );
      } else {
        this.setState({ isLoading: true });
        await db
          .ref('companyname')
          .once('value')
          .then((snapshot) => {
            if (snapshot.exists()) {
              const snapshotValue = Object.values(snapshot.val());
              let dataObj = _.filter(snapshotValue, {
                userCompanyname: company,
              });
              if (dataObj && dataObj.length > 0) {
                Swal.fire(
                  'Already Exist!',
                  `Company Name already used by another company , please user different company name`,
                  'warning'
                );
                this.setState({ isLoading: false });
                return;
              } else {
                db.ref(`users/${username}`)
                  .limitToFirst(1)
                  .once('value')
                  .then((snapshot) => {
                    if (snapshot.exists() && role === 'Admin') {
                      Swal.fire(
                        'Already Exist!',
                        `username already used by another employee , please user different username`,
                        'warning'
                      );
                      this.setState({ isLoading: false });
                      return false;
                    } else {
                      fba
                        .createUserWithEmailAndPassword(email, passwordOne)
                        .then(({ user }) => {
                          const authToken = user.ma;
                          const ref = db.ref('companyname').push();
                          const key = ref.key;
                          let userObj = {
                            uid: user.uid,
                            email: user.email,
                            type: 'Company',
                            isActive: true,
                            empName: firstName,
                            firstName: firstName,
                            lastName: lastName,
                            company: company,
                            role: role,
                            key: key,
                          };
                          db.ref(`users/${username}`)
                            .set(userObj)
                            .then(() => {
                              let companyinfo = {
                                userEmail: user.email,
                                isActive: true,
                                userCompanyname: company,
                                cuid: user.uid,
                                companyId: userObj.key,
                              };
                              db.ref(`companyname/${userObj.key}`)
                                .set(companyinfo)
                                .then(async () => {
                                  const mailObj = {
                                    companyName: company,
                                    email: user.email,
                                    username: username,
                                  };
                                  const config = {
                                    headers: {
                                      'Content-Type': 'application/json',
                                      Authorization: `Bearer ${authToken}`,
                                    },
                                  };
                                  try {
                                    await axios
                                      .post(
                                        constanst.MAILSERVER + '/admin',
                                        mailObj,
                                        config
                                      )
                                      .then(() => {});
                                  } catch (error) {
                                    Swal.fire(
                                      'Error',
                                      `Something Went Wrong Mail Not Sent!`,
                                      'error'
                                    );
                                  }
                                  Swal.fire(
                                    'Success',
                                    `User registered successfully!`,
                                    'success'
                                  );
                                  this.setState({ isLoading: false });
                                  this.props.history.push({
                                    pathname: '/',
                                  });
                                })
                                .catch((error) => {
                                  Swal.fire('Error', error.message, 'error');
                                  this.setState({ isLoading: false });
                                });
                            })
                            .catch((error) => {
                              Swal.fire('Error', error.message, 'error');
                              this.setState({ isLoading: false });
                            });
                        })
                        .catch((error) => {
                          Swal.fire('Error', error.message, 'error');
                          alert(error.message);
                          this.setState({ isLoading: false });
                        });
                    }
                  });
              }
            }
          });
      }
    } else {
      this.setState({
        validated: true,
        isLoading: false,
      });
    }
  };
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ ...this.state, [name]: value });
  };

  handleTermCondition = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  handlePermission = () => {
    window.open('https://www.productio.ai/privacy-terms', '_blank');
  };

  render() {
    const {
      firstName,
      lastName,
      company,
      username,
      email,
      passwordOne,
      passwordTwo,
      error,
      validated,
      isLoading,
    } = this.state;
    return (
      <div className='signUp'>
        <div className='left-container'></div>
        <div className='right-container'>
          <div className='formContainer'>
            <Form
              noValidate
              className='form-style'
              validated={validated}
              onSubmit={this.handleSignup}
            >
              <h5 className='letterName'>
                PRODUCTI<span className='brandLastLetter'>O</span>
              </h5>
              <label className='appName'>
                Please complete to create your account.
              </label>
              <br />
              <Form.Row className='mb-3'>
                <Col>
                  <Form.Control
                    className='inputTag'
                    placeholder='First name'
                    value={firstName}
                    name='firstName'
                    onChange={this.handleChange}
                    required
                  />
                  <Form.Control.Feedback type='invalid'>
                    First name is required
                  </Form.Control.Feedback>
                </Col>
                <Col>
                  <Form.Control
                    className='inputTag'
                    placeholder='Last name'
                    value={lastName}
                    name='lastName'
                    onChange={this.handleChange}
                    required
                  />
                  <Form.Control.Feedback type='invalid'>
                    Last name is required
                  </Form.Control.Feedback>
                </Col>
              </Form.Row>

              <Form.Group>
                <Form.Control
                  className='inputTag'
                  placeholder='Company Name'
                  name='company'
                  value={company}
                  onChange={this.handleChange}
                  required
                />
                <Form.Control.Feedback type='invalid'>
                  Company is required
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                <Form.Control
                  className='inputTag'
                  type='text'
                  placeholder='UserName'
                  name='username'
                  value={username}
                  onChange={this.handleChange}
                  required
                />
                <Form.Control.Feedback type='invalid'>
                  User name is required
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                <Form.Control
                  className='inputTag'
                  type='email'
                  placeholder='Email'
                  name='email'
                  value={email}
                  onChange={this.handleChange}
                  required
                />
                <Form.Control.Feedback type='invalid'>
                  Please enter a valid email Id
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                <Form.Control
                  className='inputTag'
                  type='password'
                  placeholder='Password'
                  name='passwordOne'
                  value={passwordOne}
                  onChange={this.handleChange}
                  required
                />
                <Form.Control.Feedback type='invalid'>
                  Please choose password
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                <Form.Control
                  className='inputTag'
                  type='password'
                  placeholder='Confirm Password'
                  name='passwordTwo'
                  value={passwordTwo}
                  onChange={this.handleChange}
                  required
                />
                <Form.Control.Feedback type='invalid'>
                  Please re-enter password
                </Form.Control.Feedback>
              </Form.Group>
              <div
                className='loginhelp'
                style={{ justifyContent: 'flex-start' }}
              >
                <Form.Group
                  controlId='formBasicCheckbox'
                  className='remeber_checkbox d-inline-flex w-auto'
                >
                  <Form.Check
                    required
                    name='term_conditions'
                    type='checkbox'
                    label='I agree with'
                    feedback='You must agree before submitting.'
                    onChange={this.handleTermCondition}
                  />
                </Form.Group>
                <a
                  href='https://www.productio.ai/privacy-terms'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='termsCondition'
                  style={{
                    fontSize: '12px',
                    lineHeight: '23px',
                    paddingLeft: '5px',
                    color: '#007bff',
                  }}
                >
                  terms and conditions
                </a>
              </div>
              <div className='send-button'>
                <button
                  type='submit'
                  className='button-send'
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <i
                      className='fa fa-spinner fa-spin'
                      state={{ fontSize: '24px' }}
                    />
                  ) : (
                    'Sign up'
                  )}
                </button>
              </div>
              {error && <p>{error.message}</p>}
              <div className='signin-label'>
                <span className='already-signin'>
                  Already have an account ?{' '}
                  <Link to='/'>
                    <span className='signuppage'> Sign In</span>
                  </Link>
                </span>
              </div>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}
