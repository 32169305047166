import { Component } from 'react';
import ReactDOM from 'react-dom';

const modalRoot = document.getElementById('modal-root');
const appRoot = document.getElementById('root');

class Portal extends Component {
  constructor(props) {
    super(props)
    this.el = document.createElement('div');
  }

  componentDidMount() {
    modalRoot.appendChild(this.el)
    appRoot.classList.add('blur');

  }

  componentWillUnmount() {
    modalRoot.removeChild(this.el)
    appRoot.classList.remove('blur');
  }

  render() {
    return ReactDOM.createPortal(
      this.props.children,
      this.el
    )
  }
}

export default Portal;
