if (process.env.REACT_APP_API_URL === 'development') {
  exports.FBK = 'AIzaSyDakwcxdhTaggNEcYCuLvavZ0ZHkhk5Fbw';
  exports.FBD = 'productio-staging.firebaseapp.com';
  exports.FBID = 'productio-staging';
  exports.FBDBURL = 'https://productio-staging.firebaseio.com';
  exports.FBSTORAGE = 'productio-staging.appspot.com';
  exports.APPID = '1:314147025694:web:545a9d6fc9a5f2f90e4f0f';
  exports.MESSAGINGSENDERID = '314147025694';
  exports.SERVERKEY = 'AIzaSyAf4tHTr3RQiqBI0ZTd0JqEqbKwgtzotXc';
  exports.MAILSERVER =
    'https://us-central1-mail-server-832c0.cloudfunctions.net/app/api/v1/sendmail';
  exports.REMOVEUSER =
    'https://us-central1-mail-server-832c0.cloudfunctions.net/app/api/v1/removeMember';
} else {
  exports.FBK = 'AIzaSyCjOxwmXLQJVeTBxvUEvGA_rSseN73Iw7g';
  exports.FBD = 'productio.firebaseapp.com';
  exports.FBID = 'productio';
  exports.FBDBURL = 'https://productio.firebaseio.com';
  exports.FBSTORAGE = 'productio.appspot.com';
  exports.APPID = '1:972711713005:web:cfe83a24d9f7ce75fed4e4';
  exports.MESSAGINGSENDERID = '972711713005';
  exports.SERVERKEY =
    'AAAA4noi2O0:APA91bEcuqwcb8vrjio46Tk6DPshZOtSZh6ti9ILbXAkeZdin-SKpqAnL5XTFpjIBS-vqYg-i7JBfBD5mxg8WiYFd_u_wJykrFNm0EZslfzIkwsUI9Huc8gs9FFkPZ8tol8o8SBs_pMW';
  exports.MAILSERVER =
    'https://us-central1-productio.cloudfunctions.net/app/api/v1/sendmail';
  exports.REMOVEUSER =
    'https://us-central1-productio.cloudfunctions.net/app/api/v1/removeMember';
}
