/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, Fragment } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import $ from 'jquery';
import _ from 'lodash';

// Actions
import * as profileActions from './../../redux/actions/profileActions';
import { UpdateProfileModal } from '../modals/updateProfile';
import { fba, db } from './../../firebase';
import { calendarDate } from './../../utils/sharedService';
import { convertHTMLToText } from './../../redux/actions/taskDetailActions';
import { imgExtentionTypes } from '../../utils/mime-type';

// Images
import Close from '../../images/closemenu.png';
import defaultFileIcon from './../../images/File_icon.png';
import menubugger from '../../images/menubugger1.png';
import defaultLogo from './../../images/productio-Circle.png';

// Material UI
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import NotificationsIcon from '@material-ui/icons/Notifications';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileUploader: null,
      isShow: false,
      isLoading: false,
      active: false,
      anchorEl: null,
      notifications: [],
      isSideBarShow: false,
    };
    this.myInput = React.createRef();
  }

  handleOpen = (event) => {
    this.setState({ anchorEl: event.target });
  };

  menuIcon = () => {
    this.setState({ isSideBarShow: !this.state.isSideBarShow });
    $('.open_sidbar').toggleClass('small_sidebar');
    $('.remove_text').toggleClass('text_hide');
    $('#content_body').toggleClass('margin_left');
    $('.Aside_right').toggleClass('Aside_rightfull');
    $('.menubugger').toggleClass('in');
    $('.menubutton').toggleClass('fade');
    $('.open_sidbar').toggleClass('mobileshow');
  };

  toggleSearch = () => {
    $('.search_input').toggleClass('in');
  };

  handleLogout = () => {
    fba.signOut().then(() => {
      localStorage.removeItem('persist:root');
      localStorage.removeItem('isLogin');
      localStorage.removeItem('companyID');
      localStorage.removeItem('company');
      localStorage.removeItem('userType');
      localStorage.removeItem('username');
      this.props.history.push('/');
    });
  };

  handleImgPropup = () => {
    const { fetchProfile } = this.props.actions;
    fetchProfile();
    this.setState({ isShow: true });
  };

  handleHide = () => {
    this.setState({ isShow: false });
  };

  onImageUpload = (fileObj) => {
    if (fileObj && typeof fileObj === 'object') {
      this.setState({ isLoading: true });
      const { uploadProfilePhoto } = this.props.actions;
      uploadProfilePhoto(fileObj).then((res) => {
        if (res) {
          this.setState({ isShow: false, isLoading: false });
        }
      });
    } else {
      this.setState({ isShow: false, isLoading: false });
    }
  };

  handleOpen = (event) => {
    this.setState({ anchorEl: event.target });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleNotification = (option) => {
    this.props.actions.setNotification(option, this.props.history);
    this.setState({ anchorEl: null });
  };

  rederNotifications = () => {
    let notifications = [...this.state.notifications];
    notifications = notifications.filter(
      (not) => not && not.data && not.notification && not.data.isRead === false
    );
    let notificationsMarkup =
      notifications && notifications.length > 0 ? (
        notifications.map((not, i) => {
          let description = '';
          if (not.notification && not.notification.body) {
            description = convertHTMLToText(not.notification.body);
          }
          if (
            not.notification &&
            not.notification.icon &&
            not.notification.icon !== ''
          ) {
            const url = not.notification.icon.split('?alt')[0];
            not.notification.isImage =
              imgExtentionTypes.indexOf(url.split('.').pop(-1)) >= 0;
            if (!not.notification.isImage)
              not.notification.icon = defaultFileIcon;
          }
          not.notification.icon =
            not.notification &&
            not.notification.icon &&
            not.notification.icon !== ''
              ? not.notification.icon
              : defaultFileIcon;
          const time = not.createdAt ? calendarDate(not.createdAt) : null;
          return (
            <List
              key={i}
              onClick={this.handleNotification.bind(this, not)}
              id={not.id}
            >
              <ListItem alignItems='flex-start'>
                <ListItemAvatar>
                  <Avatar alt='Remy Sharp' src={not.notification.icon} />
                </ListItemAvatar>
                <ListItemText
                  primary={`Title : ${not.notification &&
                    not.notification.title}`}
                  secondary={
                    <Fragment>
                      <Typography
                        component='span'
                        variant='body2'
                        state={{ display: 'inline' }}
                        color='textPrimary'
                      ></Typography>
                      {description && description.length >= 80
                        ? `Description: ${_.truncate(description, {
                            length: 80,
                            separator: /,? +/,
                          })}`
                        : `Description: ${description}`}
                      {not.data.username ? (
                        <>
                          <br />
                          Name: {not.data.username}
                        </>
                      ) : null}
                      {not.data.points ? (
                        <>
                          <br />
                          Points: {not.data.points}
                        </>
                      ) : null}
                      <br />
                      {`Date: ${time}`}{' '}
                      {not.data && not.data.isRead === false && (
                        <i className='fa fa-envelope' aria-hidden='true'></i>
                      )}
                    </Fragment>
                  }
                />
              </ListItem>
              <Divider variant='fullWidth' component='li' />
            </List>
          );
        })
      ) : (
        <MenuItem onClick={this.handleClose}>
          You have no notifications yet
        </MenuItem>
      );
    return notificationsMarkup;
  };

  async componentDidMount() {
    const { fetchProfile } = this.props.actions;
    await fetchProfile();
    let publicRef = null;
    const currentUserId = localStorage.getItem('username');
    if (currentUserId) {
      publicRef = db.ref(`users/${currentUserId}/notifications`);
    }

    if (publicRef) {
      await publicRef
        // .orderByChild('createdAt')
        .on('child_added', (snapshot) => {
          if (snapshot.exists()) {
            let notifications = [...this.state.notifications];
            notifications = notifications.filter(
              (not) =>
                not && not.data && not.notification && not.data.isRead === false
            );
            const obj = snapshot.val();
            obj['key'] = snapshot.key;
            notifications.unshift(obj);
            this.setState({ ...this.state, notifications: notifications });
          }
        });

      await publicRef.on('child_removed', (snapshot) => {
        if (snapshot.exists()) {
          let notifications = [...this.state.notifications];
          notifications = notifications.filter(
            (n) => n.id !== snapshot.val().id
          );
          this.setState({ ...this.state, notifications });
        }
      });

      await publicRef.on('child_changed', (snapshot) => {
        if (snapshot.exists()) {
          let notifications = [...this.state.notifications];
          notifications = notifications.filter(
            (n) => n.id !== snapshot.val().id
          );
          const obj = snapshot.val();
          obj['key'] = snapshot.key;
          notifications.unshift(obj);
          this.setState({ ...this.state, notifications });
        }
      });
    }
  }

  componentWillUnmount() {
    this.props.actions.clearProfile();
    const currentUserId = localStorage.getItem('username');
    if (currentUserId) {
      db.ref(`users/${currentUserId}/notifications`).off();
    }
  }

  showUnSeenCount = (notifications) => {
    let notificationCount = 0;
    notifications &&
      notifications.forEach((n) => {
        if (n && n.data && n.notification) {
          if (n.data.isRead === false) {
            notificationCount += 1;
          }
        }
      });
    return (
      <span onClick={this.handleOpen}>
        <IconButton color='inherit'>
          <Badge badgeContent={notificationCount} color='secondary'>
            <NotificationsIcon />
          </Badge>
        </IconButton>
      </span>
    );
  };

  render() {
    let companyName = localStorage.getItem('company');
    const { userInfo } = this.props.profileReducer;
    const { setProfileInputValue, removeProfile } = this.props.actions;
    let imgPath = '';
    if (userInfo) {
      const { photoURL } = userInfo;
      imgPath = photoURL;
    } else {
      imgPath = '';
    }
    return (
      <>
        <nav className='navbar navbar-fixed-top admin-navbar'>
          <div className='container-fluid nopadd'>
            <div className='col-sm-12'>
              <div className='row'>
                <div className='navbar-header col-xl-2 col-lg-3 col-sm-3 col-5 paddres'>
                  <div className='prod'>
                    <span className='menubugger' onClick={this.menuIcon}>
                      <img src={menubugger} className='menuimage1' alt='' />
                    </span>
                    {/* <h3 className='logohead'>
                      {!this.state.isSideBarShow ? (
                        <img
                          src={defaultLogo}
                          className={`responsiveimagelogo ${
                            this.state.isSideBarShow ? 'menuImageShow' : ''
                          }`}
                          alt='logo'
                        />
                      ) : null}
                    </h3> */}
                    <span
                      id='menu_icon'
                      className='menubutton'
                      onClick={this.menuIcon}
                    >
                      <img src={Close} className='menuimage' alt='' />
                    </span>
                  </div>
                </div>
                <div className='navbar-header col-xl-6 col-lg-5 col-sm-6 col-2'>
                  <div className='searchbar' style={{ display: 'none' }}>
                    <input
                      className='search_input'
                      type='text'
                      name=''
                      placeholder='Enter Search Keywords...'
                    />
                    <a
                      href=''
                      className='search_icon'
                      onClick={this.toggleSearch}
                    >
                      <i className='fa fa-search'></i>
                    </a>
                  </div>
                </div>
                <div className='dropdown pull-right col-xl-4 col-lg-4 col-sm-3 col-5 text-right paddright0px'>
                  {this.showUnSeenCount(this.state.notifications)}
                  <ul className='nav navbar-nav ml-3 dropnav1'>
                    <li className='nav-item dropdown'>
                      <img
                        onClick={() => this.handleImgPropup()}
                        src={imgPath && imgPath !== '' ? imgPath : defaultLogo}
                        className='profile-drop-header'
                        alt='profile'
                      />
                      <a
                        className='nav-link dropdown-toggle'
                        id='navbarDropdown'
                        role='button'
                        data-toggle='dropdown'
                        aria-haspopup='true'
                        aria-expanded='false'
                      >
                        <span className='mobres' style={{ color: '#000000' }}>
                          {companyName && companyName.length >= 29
                            ? _.truncate(companyName, {
                                length: 29,
                                separator: /,? +/,
                              })
                            : companyName}
                        </span>
                      </a>
                      <div
                        className='dropdown-menu'
                        aria-labelledby='navbarDropdown'
                      >
                        <a className='dropdown-item desknone'>Company Name</a>
                        <Link
                          replace
                          to='/'
                          onClick={this.handleLogout}
                          className='dropdown-item'
                        >
                          Logout
                        </Link>
                      </div>
                    </li>
                  </ul>
                  <div>
                    <Menu
                      anchorEl={this.state.anchorEl}
                      open={Boolean(this.state.anchorEl)}
                      onClose={this.handleClose}
                      onEntered={this.onMenuOpened}
                    >
                      {this.rederNotifications()}
                    </Menu>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
        <UpdateProfileModal
          {...this.props}
          show={this.state.isShow}
          onHide={() => this.handleHide()}
          updateProfile={this.onImageUpload}
          userInfo={userInfo}
          isLoading={this.state.isLoading}
          setProfileInputValue={setProfileInputValue}
          removeProfile={removeProfile}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { memberReducer, profileReducer } = state;
  return {
    memberReducer,
    profileReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      ...bindActionCreators(profileActions, dispatch),
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
