import React, { useState, useEffect, Fragment } from 'react';
import _ from 'lodash';

// TaskGroup List Component
const TaskGroupContainer = ({
  taskGroupOptions,
  onSelectingTaskGroup,
  taskGroupId,
  onDeletingTaskGroup,
  onTaskGroupModalOpen,
}) => {
  const [state, setState] = useState({
    taskGroupOptions: [],
    isActive: '',
  });

  useEffect(() => {
    if (taskGroupOptions && taskGroupOptions !== undefined) {
      setState({ ...state, taskGroupOptions, isActive: taskGroupId });
    }
  }, [taskGroupOptions, taskGroupId]);

  const handleSelectingTaskGroup = (event, task) => {
    window.jQuery('li.active').removeClass('active');
    // window.jQuery(this).addClass('active');
    event.target.classList.remove('active');
    event.target.classList.add('active');
    onSelectingTaskGroup(task);
  };

  const handleDeleteTaskGroup = (event, task) => {
    event.preventDefault();
    onDeletingTaskGroup(task.id);
  };

  const handleEditTaskGroup = (event, task) => {
    event.preventDefault();
    onTaskGroupModalOpen(event, task);
  };

  const getTaskData = () => {
    if (
      state.taskGroupOptions &&
      state.taskGroupOptions !== undefined &&
      state.taskGroupOptions.length > 0
    ) {
      return _.map(state.taskGroupOptions, (task, i) => {
        if (task.name.length >= 20) {
          task.trimName = _.truncate(task.name, { length: 17 });
        } else {
          task.trimName = task.name;
        }
        if (task.name !== '' && task.color !== '') {
          return (
            <Fragment key={i}>
              <li
                className={`taskGroupli ${
                  state.isActive === task.id ? 'active' : ''
                }`}
                key={task.id}
                id={task.id}
                onClick={(e) => handleSelectingTaskGroup(e, task)}
              >
                <span className='bg-default' style={{ background: task.color }}>
                  {' '}
                </span>
                {task.trimName}{' '}
              </li>
              <i
                onClick={(e) => handleEditTaskGroup(e, task)}
                className='fa fa-pencil'
                style={{
                  float: 'right',
                  fontSize: '21px',
                  position: 'relative',
                  bottom: '32px',
                  right: '10px',
                  cursor: 'pointer',
                }}
              ></i>
              {/* <i
                onClick={(e) => handleDeleteTaskGroup(e, task)}
                className='fa fa-trash-o'
                style={{
                  float: 'right',
                  fontSize: '21px',
                  position: 'relative',
                  bottom: '32px',
                  right: '-13px',
                  cursor: 'pointer',
                }}
              ></i> */}
            </Fragment>
          );
        }
      });
    } else {
      return <p className='text-center'>No Task Group Found...</p>;
    }
  };
  return (
    <>
      <div className='taskcontent'>
        <h5 className='font-weight-bold d-inline'>Strategy</h5>
        <hr />
      </div>
      <ul className='available-items'>{getTaskData()}</ul>
    </>
  );
};
export default TaskGroupContainer;
