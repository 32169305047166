// File Types
export const docType = [
  'application/pdf', // .pdf
  'application/vnd.oasis.opendocument.presentation', // .odp
  'application/vnd.oasis.opendocument.spreadsheet', // .ods
  'application/vnd.oasis.opendocument.text', // .odt
  'application/rtf', // .rtf
  'application/msword', // .doc
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
  'application/vnd.ms-excel', // .xls
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
  'application/vnd.ms-powerpoint', // .ppt
  'application/vnd.openxmlformats-officedocument.presentationml.presentation', // .pptx
  'application/vnd.ms-fontobject',
  'application/epub+zip', // .epub
  'application/json', // .json
  'text/plain', // .txt
];

export const imgTypes = [
  'image/gif', //.gif
  'image/png', // .png
  'image/jpeg', // .jpeg
  'image/jpg', // .jpg
  'image/tif', // .tif
  'image/tiff', // .tiff
  'image/vnd.wap.wbmp', // .wbmp
  'image/webp', // .webp
  'image/vnd.microsoft.icon', // .ico
  'image/x-icon', // .icox
  'image/bmp', // .bmp
  'image/x-ms-bmp', // .bmpx
  'image/svg+xml', // .svg
  'image/x-jng', // .jng
];

export const videoTypes = [
  'video/x-flv',
  'video/mp4',
  'application/x-mpegURL',
  'video/MP2T',
  'video/3gpp',
  'video/quicktime',
  'video/x-msvideo',
  'video/x-ms-wmv',
];
// Extentions
export const docExtentionType = [
  'pdf',
  'odp',
  'ods',
  'odt',
  'rtf',
  'doc',
  'docx',
  'xls',
  'xlsx',
  'ppt',
  'pptx',
  'epub',
  'json',
  'txt',
];

export const imgExtentionTypes = [
  'gif',
  'png',
  'jpeg',
  'jpg',
  'tif',
  'tiff',
  'wbmp',
  'webp',
  'ico',
  'icox',
  'bmp',
  'bmpx',
  'svg',
  'jng',
];

export const videoExtentionType = [
  'flv',
  'mp4',
  'm3u8',
  'ts',
  '3gp',
  'mov',
  'avi',
  'wmv',
];
