import * as profileActions from './../actions/profileActions';

export function profileReducer(
  state = {
    userInfo: [],
    savingFile: false,
    savedFile: false,
    inputValues: {
      file: null
    },
    notification: []
  },
  action
) {
  switch (action.type) {
    case profileActions.UPLOAD_PROFILE:
      return {
        ...state,
        userInfo: action.payload
      };
    case profileActions.SET_PROFILE_INPUT_VALUE:
      return {
        ...state,
        inputValues: {
          ...state.inputValues,
          [action.name]: action.value
        }
      };
    case profileActions.FETCH_PROFILE:
      return {
        ...state,
        userInfo: action.payload
      };
    case profileActions.SAVING_UPLOAD:
      return {
        ...state,
        savingFile: action.savingFile
      };
    case profileActions.SAVED_UPLOAD:
      return {
        ...state,
        savedFile: action.savedFile
      };

    case profileActions.CLEAR_PROFILE_INPUT_VALUE:
      return {
        ...state,
        inputValues: {
          file: null
        }
      };
    case profileActions.SET_NOTIFICATION:
      return {
        ...state,
        notification: action.payload
      };
    case profileActions.CLEAR_NOTIFICATIONS:
      return {
        ...state,
        notification: []
      };
    case profileActions.CLEAR_PROFILE:
      return {
        ...state,
        userInfo: [],
        savingFile: false,
        savedFile: false,
        inputValues: {
          file: null
        },
        notification: []
      };
    default:
      return state;
  }
}
