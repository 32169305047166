import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Dropdown } from 'react-bootstrap';
import LazyLoad from 'react-lazyload';

import defaultImg from './../../images/defaultProfile.png';
import { getUsersByCompanyId } from './storyboardService';

// All TaskGroup Detail  Component.
const AllTaskGroup = ({
  selectedTask,
  selectedUsersByTask,
  tasks,
  taskGroupModalShow,
}) => {
  const [state, setState] = useState({
    taskGroup: {
      id: '',
      name: '',
      employeeList: '',
      earnedScore: '',
      totalScore: '',
      progress: '',
    },
    allTasks: tasks,
  });
  const [optionDefault, setOptionDefault] = useState({
    id: Math.round(1),
    name: 'Please Select Employee',
  });

  useEffect(() => {
    if (
      selectedTask &&
      typeof selectedTask === 'object' &&
      selectedTask !== undefined
    ) {
      setState({
        taskGroup: {
          id: selectedTask.id,
          name: selectedTask.name,
          employeeList: '',
          totalScore: 0,
          earnedScore: 0,
          progress: 0,
        },
        allTasks: tasks && tasks !== undefined ? tasks : [],
      });
      setOptionDefault({ id: Math.round(1), name: 'Please Select Employee' });
    }
  }, [selectedTask, tasks]);

  useEffect(() => {
    if (selectedTask) {
      setState({
        taskGroup: {
          id: selectedTask.id,
          name: selectedTask.name,
          employeeList: '',
          totalScore: 0,
          earnedScore: 0,
          progress: 0,
        },
      });
    }
  }, [taskGroupModalShow]);

  const handleEmployeeScore = (emp) => {
    const empTaskGroupId = emp.empTaskGroupId;
    const isObject = typeof emp.tasks === 'object' && emp.tasks.length > 0;
    emp.tasks = isObject === false ? Object.values(emp.tasks) : emp.tasks;
    let taskArray = [...emp.tasks];
    taskArray = emp.tasks.filter((t) => t.taskGroupId === selectedTask.id);
    if (empTaskGroupId !== '') {
      let totalScore = 0;
      let earnedScore = 0;
      if (taskArray && taskArray.length > 0) {
        _.forEach(taskArray, (task) => {
          if (task) {
            const poinsInt = parseInt(task.points, 10);
            if (poinsInt > 0 && poinsInt < 10000000 && task.isComplete) {
              totalScore += parseInt(task.points, 10);
            }
            if (poinsInt > 0 && poinsInt < 10000000) {
              earnedScore += parseInt(task.points, 10);
            }
          }
        });
      }
      const progress = Math.floor((totalScore / (earnedScore || 1)) * 100 || 0);
      setState({
        ...state,
        taskGroup: {
          id: selectedTask.id,
          name: selectedTask.name,
          totalScore: totalScore,
          earnedScore: earnedScore,
          progress: progress,
        },
      });
      setOptionDefault({ id: emp.empTaskGroupId, name: emp.empName });
    } else {
      setState({
        ...state,
        taskGroup: {
          id: selectedTask.id,
          name: selectedTask.name,
          employeeList: '',
          totalScore: 0,
          earnedScore: 0,
          progress: 0,
        },
      });
      setOptionDefault({ id: Math.round(1), name: 'Please Select Employee' });
    }
  };

  const showTaskGroupDetail = (taskGroup) => {
    let usersArray = [];
    let newUsersArray = getUsersByCompanyId(selectedUsersByTask);
    taskGroup &&
      taskGroup.id &&
      selectedUsersByTask &&
      selectedUsersByTask.forEach((user) => {
        if (user && user.tasks) {
          _.forEach(user.tasks, (task) => {
            if (
              task.assignBy === 'employee' &&
              task.taskGroupId === taskGroup.id &&
              task.username === user.username
            ) {
              if (!_.some(usersArray, ['id', user.id])) {
                usersArray.push(user);
              }
            } else if (
              task.assignBy === 'taskGroup' &&
              task.taskGroupId === taskGroup.id
            ) {
              task.taskGroupUsers &&
                task.taskGroupUsers.forEach((u) => {
                  if (u) {
                    newUsersArray.forEach((user) => {
                      if (user) {
                        if (u.id === user.username) {
                          if (!_.some(usersArray, ['id', user.id])) {
                            usersArray.push(user);
                          }
                        }
                      }
                    });
                  }
                });
            }
          });
        }
      });
    let filterUsers = [...usersArray];
    filterUsers = getUsersByCompanyId(filterUsers);
    const showEmpByTaskGroup = (users) => {
      return (
        <Dropdown.Menu>
          {users.map((user, i) => (
            <Dropdown.Item
              eventKey={user}
              id={user.uid}
              key={i}
              onClick={(e) => handleEmployeeScore(user)}
            >
              {user.empName}{' '}
              <LazyLoad
                key={user.id}
                height={150}
                placeholder={
                  <img
                    alt='employee img'
                    className='employee-img'
                    src={defaultImg}
                  />
                }
              >
                <img
                  alt='employee img'
                  className='employee-img'
                  src={user.photoURL || defaultImg}
                />
              </LazyLoad>
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      );
    };

    if (taskGroup && taskGroup !== undefined) {
      return (
        <tr key={taskGroup.id}>
          <td className='text-center'> {taskGroup.name}</td>
          <td className='text-center' style={{ width: 'auto' }}>
            <Dropdown drop='down' className='employeeDropdown'>
              <Dropdown.Toggle className='dropDownSelect' id='dropdown-basic'>
                {optionDefault.name}
                {filterUsers && filterUsers.length > 0 ? (
                  showEmpByTaskGroup(filterUsers)
                ) : (
                  <Dropdown.Menu>
                    <Dropdown.Item eventKey={'user'} id={'user.uid'} key={'i'}>
                      No Employee Found
                    </Dropdown.Item>
                  </Dropdown.Menu>
                )}
              </Dropdown.Toggle>
            </Dropdown>
          </td>
          <td className='text-center'>{taskGroup.totalScore}</td>
          <td className='text-center'>{taskGroup.earnedScore}</td>
          <td className='text-center'>{taskGroup.progress}</td>
        </tr>
      );
    } else {
      return (
        <tr>
          <td className='text-center' colSpan='5'>
            No TaskGroup Found...
          </td>
        </tr>
      );
    }
  };
  return (
    <div className='taskgroup ml-0 mr-3 mt-3 mb-3'>
      <div className='taskcontent pl-4'>
        <h5 className='d-inline'>
          {selectedTask && selectedTask.name && selectedTask.name !== undefined
            ? selectedTask.name
            : 'N/A'}
          <span className='font-weight-bold '> / Task Group</span>
        </h5>
        <hr className='mb-3' />
        <div className='table-responsive'>
          <table className='table tablegoal table-head-fixed available12'>
            <thead className='thead-light1'>
              <tr className='text-center'>
                <th className='pl-4'>Title</th>
                <th className='pl-4 text-center'>Employee List</th>
                <th className='pl-4 text-center'>Earned Score</th>
                <th className='pl-4 text-center'>Total Score</th>
                <th className='pl-4 text-center'>Progress %</th>
              </tr>
            </thead>
            <tbody style={{ height: '110px' }}>
              {showTaskGroupDetail(state.taskGroup)}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AllTaskGroup;
