/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Swal from 'sweetalert2';
import LazyLoad from 'react-lazyload';

import * as memberActions from '../../redux/actions/memberActions.js';
import * as taskDetailActions from '../../redux/actions/taskDetailActions';
import { EmployeeEditModal } from '../modals/employeeEdit.js';

import defaultImg from './../../images/defaultProfile.png';

class EmployeeDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalShow: false,
    };
  }

  componentDidMount = () => {
    let companyId = localStorage.getItem('companyID');
    const { fetchEmp, fetchTaskGroups } = this.props.actions;
    fetchEmp(companyId); // get list of Employee from Firebase
    fetchTaskGroups(companyId); // get list of Task group from Firebase
  };

  setModalShow = (emp) => {
    if (emp) {
      const { setMemberInputValue } = this.props.actions;
      setMemberInputValue('empEmailID', emp.email);
      setMemberInputValue('empName', emp.empName);
      setMemberInputValue('empPhoneNumber', emp.empPhoneNumber);
      setMemberInputValue('empTaskGroupId', emp.empTaskGroupId);
      setMemberInputValue('empID', emp.empID);
      setMemberInputValue('empUserName', emp.username);
      this.setState({ modalShow: !this.state.modalShow });
    }
  };

  changeEmpInfo = (name, val) => {
    this.setState({ [name]: val });
  };

  updateEmpInfo = async () => {
    const { updateMember } = this.props.actions;
    await updateMember().then(() => {
      this.setState({ modalShow: !this.state.modalShow });
    });
  };

  deleteEmployee = (data) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3a3a64',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      preConfirm: async () => {
        Swal.getTitle().innerHTML = 'Please Wait!';
        Swal.getConfirmButton().innerHTML = `Deleting...`;
        Swal.getCancelButton().style.display = 'none';
        const { deleteMember } = this.props.actions;
        await deleteMember(data);
      },
    }).then((res) => {
      if (res.value) {
        Swal.fire('Deleted!', 'Member has been deleted.', 'success');
      }
    });
  };

  render() {
    let { employees } = this.props.employees.inputValues;
    const { taskGroupOptions } = this.props.taskDetailReducer.inputValues;
    const editEmpInfo = this.props.employees.inputValues;
    const { savingPlan, savedPlan } = this.props.employees;
    const { setMemberInputValue } = this.props.actions;
    return (
      <div>
        <div className='plan-content'>
          <h2>Members</h2>
        </div>
        <div className='row'>
          <div className='col-lg-12'>
            <div className=' pl-3 pr-3 mt-3'>
              <div className='table-responsive '>
                <table className='table table-bordered table-head-fixed table-hover mt-0'>
                  <thead>
                    <tr>
                      <th className='text-center' scope='col'>
                        Member Name
                      </th>
                      <th className='text-center' scope='col'>
                        Email Id
                      </th>
                      <th className='text-center' scope='col'>
                        Member Id
                      </th>
                      <th className='text-center' scope='col'>
                        Phone Number
                      </th>
                      <th className='text-center' scope='col'>
                        Strategy
                      </th>
                      <th className='text-center' scope='col'>
                        Action
                      </th>
                    </tr>
                  </thead>
                  <div className='available-items table-scroll-members'>
                    <tbody>
                      {employees && employees.length > 0 ? (
                        employees.map((emp, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <LazyLoad
                                  key={emp.id}
                                  height={150}
                                  placeholder={
                                    <img
                                      className='profile-drop'
                                      src={defaultImg}
                                      alt=''
                                    />
                                  }
                                >
                                  <img
                                    className='profile-drop'
                                    src={emp.photoURL || defaultImg}
                                    alt=''
                                  />
                                </LazyLoad>{' '}
                                {emp.empName}{' '}
                              </td>
                              <td
                                style={{ wordBreak: 'break-all' }}
                                className='text-center'
                              >
                                {emp.email}
                              </td>
                              <td className='text-center'>{emp.empID}</td>
                              <td className='text-center'>
                                {emp.empPhoneNumber}
                              </td>
                              <td className='text-center'>
                                {emp.empTaskGroupName}
                              </td>
                              <td className='text-center'>
                                <a
                                  style={{ cursor: 'pointer' }}
                                  className='btn-link'
                                  onClick={() => this.setModalShow(emp)}
                                >
                                  Edit
                                </a>
                                <br />
                                <a
                                  style={{ cursor: 'pointer' }}
                                  className='btn-link'
                                  onClick={() => this.deleteEmployee(emp)}
                                >
                                  Delete
                                </a>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td className='text-center' colSpan='8'>
                            No Members Found!
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </div>
                </table>
              </div>
            </div>
            <EmployeeEditModal
              show={this.state.modalShow}
              onHide={() => {
                this.props.actions.clearMemberInputValues();
                this.setState({ modalShow: false });
              }}
              editEmpInfo={editEmpInfo}
              savingPlan={savingPlan}
              savedPlan={savedPlan}
              changeEmpInfo={this.changeEmpInfo}
              updateEmpInfo={this.updateEmpInfo}
              onInputChange={setMemberInputValue}
              taskGroupOptions={taskGroupOptions}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    employees: state.memberReducer,
    taskDetailReducer: state.taskDetailReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      ...bindActionCreators(memberActions, dispatch),
      ...bindActionCreators(taskDetailActions, dispatch),
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeDetails);
