import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import * as moment from 'moment';
import { EditorState } from 'draft-js';

import * as goalActions from '../redux/actions/goalActions.js';
import * as memberActions from '../redux/actions/memberActions.js';
import * as taskDetailActions from '../redux/actions/taskDetailActions.js';

import TaskDetailsForm from './TaskDetailsForm';

class TaskDetailsContainer extends Component {
  state = {
    editorState: EditorState.createEmpty(),
    text: '',
  };

  resetFile = (file) => {
    const { clearTaskFiles } = this.props.actions;
    return clearTaskFiles(file);
  };

  handleClear = () => {
    const { clearTaskGroups } = this.props.actions;
    this.setState({
      editorState: EditorState.createEmpty(),
      text: '',
    });
    return clearTaskGroups();
  };

  async componentDidMount() {
    let companyId = localStorage.getItem('companyID');
    const { fetchTaskGroups, fetchGoals, fetchEmp } = this.props.actions;
    await fetchEmp(companyId); // get list of Employee from Firebase
    await fetchGoals(companyId); // get list of goals from Firebase
    await fetchTaskGroups(companyId); // get list of Task group from Firebase
  }

  onSaveTaskDetails = (e) => {
    const { saveTaskDetails } = this.props.actions;
    e.preventDefault();
    let companyId = localStorage.getItem('companyID');
    return saveTaskDetails(companyId);
  };

  render() {
    const {
      setTaskDetailInputValue,
      setGoalTypeQualifierInputValue,
      getGoalTypeOptionKeys,
      removeOriginalFile,
    } = this.props.actions;
    const { inputValues, savingTask, savedTask } = this.props.taskDetailReducer;
    inputValues.taskDetailText = this.state.text;
    let taskGroupOptions = inputValues.taskGroupOptions;
    if (inputValues.startDate) {
      const startDate = inputValues.startDate;
      inputValues.endDate = '';
      let endDate = startDate;
      endDate = moment(moment(endDate).add('days', 30)).toDate();
      inputValues.endDate = endDate;
    }

    if (savedTask) {
      this.componentDidMount();
    }
    const { goals } = this.props.goalReducer;
    const { employees } = this.props.memberReducer.inputValues;
    const { onSaveTaskDetails } = this;
    var filter_goals = [];
    var filter_plan = [];
    if (inputValues.taskGroup !== '') {
      filter_goals = _.filter(goals, { taskGroup: inputValues.taskGroup });
      let n1 = _.filter(employees, { empID: inputValues.empID.split('-')[0] });
      if (n1.length > 0) {
        if (n1[0].goals !== undefined) {
          for (let i = 0; i < n1[0].goals.length; i++) {
            filter_goals = _.remove(filter_goals, (obj) => {
              return obj.id !== n1[0].goals[i];
            });
          }
          filter_plan = _.filter(inputValues.taskGroupOptions, {
            id: inputValues.taskGroup,
          });
        }
      }
    }
    return (
      <div>
        <div className='plan-content'>
          <h2>Create New Task</h2>
        </div>
        <TaskDetailsForm
          {...this.props}
          inputValues={inputValues}
          taskGroupOptions={taskGroupOptions}
          saveTaskDetails={onSaveTaskDetails}
          onInputChange={setTaskDetailInputValue}
          onQualifierInputChange={setGoalTypeQualifierInputValue}
          getGoalTypeOptionKeys={getGoalTypeOptionKeys}
          savingTask={savingTask}
          savedTask={savedTask}
          goals={filter_goals}
          plans={filter_plan}
          employees={employees}
          removeOriginalFile={removeOriginalFile}
          onClear={this.handleClear}
          resetFile={this.resetFile}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { taskDetailReducer, goalReducer, memberReducer } = state;
  return {
    taskDetailReducer,
    goalReducer,
    memberReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      ...bindActionCreators(taskDetailActions, dispatch),
      ...bindActionCreators(goalActions, dispatch),
      ...bindActionCreators(memberActions, dispatch),
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TaskDetailsContainer)
);
