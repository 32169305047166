import _ from 'lodash';
import * as moment from 'moment';
import axios from 'axios';
import Swal from 'sweetalert2';
import * as firebase from 'firebase';
import draftToHtml from 'draftjs-to-html';
import {
  convertToRaw,
  EditorState,
  ContentState,
  convertFromHTML,
} from 'draft-js';

import * as constanst from './../../constants';
import * as fcmService from './../../utils/fcmService';
import * as loaderActions from './loaderAction';
import * as memberActions from './memberActions';

import { db, storage } from '../../firebase.js';
import {
  docType,
  imgTypes,
  imgExtentionTypes,
  videoTypes,
} from './../../utils/mime-type';

// Add Task
export const saveTaskDetails = (companyId) => async (dispatch, getState) => {
  const values = getState().taskDetailReducer.inputValues;

  // Validation if Assigned By TaskGroup/Employee
  if (values.assignBy === 'employee' || values.assignBy === 'taskGroup') {
    if (values.assignBy === 'employee' && values.empID === '') {
      Swal.fire('Error!', 'Member is Required', 'warning');
      return;
    }
    if (values.taskGroup === '') {
      Swal.fire('Error!', 'Strategy is Required', 'warning');
      return;
    }
    if (values.taskDetailsGoalType === '') {
      Swal.fire('Error!', 'Goal is Required', 'warning');
      return;
    }
    if (values.taskDetailsGoalOption === '') {
      Swal.fire('Error!', 'Goal Option is Required', 'warning');
      return;
    }
  }

  var usersByTaskGroup = [];
  const companyId = localStorage.getItem('companyID');
  const members = getState().memberReducer.users;
  if (members) {
    usersByTaskGroup = _.filter(members, {
      companyId: companyId,
      type: 'Employee',
    });
  }

  if (values.assignBy === 'taskGroup') {
    if (usersByTaskGroup && usersByTaskGroup.length === 0) {
      return Swal.fire({
        icon: 'error',
        title: 'No Member Found...',
        text: 'Please Add Members First',
      });
    }
  }
  const taskGroupOptions = getState().taskDetailReducer.inputValues
    .taskGroupOptions;
  const taskGroupObj = _.find(
    taskGroupOptions,
    (task) => task.id === values.taskGroup
  );

  var files = values.file;
  var fileObj = null;
  var fileName = null;
  var singleFileType = null;
  var uploadedFiles = [];
  dispatch(savingTask(true));
  // When Task is UnAssigned
  if (values.taskId && values.taskId !== '') {
    // Remove The File From Storage
    try {
      if (values.removedFiles && values.removedFiles.length > 0) {
        await values.removedFiles.forEach(async (img) => {
          try {
            if (!img.url.startsWith('/static/')) {
              await storage.refFromURL(img.url).delete();
            }
          } catch (err) {
            console.error(err);
          }
        });
      }
    } catch (err) {
      console.error(err.message);
    }
  }

  if (files && files.length >= 1) {
    const array = Array.from({ length: files.length }, (value, index) => index);
    uploadedFiles = await Promise.all(
      await array.map(async (index) => {
        const file = files[index];
        const metadata = { contentType: file.type };
        let extention = null;
        let fileType = null;
        if (docType.includes(file.type)) {
          extention = file.name.split('.').pop(-1);
        } else if (imgTypes.includes(file.type)) {
          extention = file.name.split('.').pop(-1);
        }
        if (videoTypes.includes(file.type)) {
          fileType = 'video';
        } else if (docType.includes(file.type)) {
          fileType = 'document';
        } else if (imgTypes.includes(file.type)) {
          fileType = 'image';
        }
        const uploadTask = storage
          .ref(`tasks/${file.name}`)
          .put(file, metadata);
        const url = await new Promise((resolve, reject) => {
          uploadTask.on(
            'state_changed',
            () => {},
            (error) => reject(error),
            async () => {
              try {
                const downloadUrl = await uploadTask.snapshot.ref.getDownloadURL();
                resolve(downloadUrl);
              } catch (err) {
                console.error(err.message);
              }
            }
          );
        });
        return {
          name: file.name,
          url,
          type: fileType,
          fileExtention: extention,
        };
      })
    );
  }
  let fileExtention = null;
  if (uploadedFiles && uploadedFiles.length > 0) {
    if (values.taskId && values.taskId !== '') {
      if (values.originalFiles && values.originalFiles.length > 0) {
        uploadedFiles = values.originalFiles.concat(uploadedFiles);
      }
    }
    const object = uploadedFiles.find((file) =>
      imgExtentionTypes.includes(file.fileExtention)
    );
    if (object) {
      fileObj = object.url;
      fileExtention = object.fileExtention;
      fileName = object.name;
      singleFileType = object.type ? object.type : null;
    } else {
      fileObj = uploadedFiles[0].url;
      fileExtention = uploadedFiles[0].fileExtention;
      fileName = uploadedFiles[0].name;
      singleFileType = uploadedFiles[0].type;
    }
  }
  let uname = values.empID.split('-')[1];
  const publicTaskDetailRef = db.ref('tasks');
  let newCreatedTaskDetailRef = null;
  var taskObj = {};
  if (values.taskId && values.taskId !== '') {
    values.startDate = moment(values.startDate).format('MM/DD/YYYY');
    values.endDate = moment(values.endDate).format('MM/DD/YYYY');
    values.endTime = moment(values.endTime).format('LT');
    newCreatedTaskDetailRef = db.ref(`tasks/${values.taskId}`);
    taskObj = {
      empID: values.empID ? values.empID.split('-')[0] : '',
      username: values.empID ? values.empID.split('-')[1] : '',
      title: values.taskDetailTitle,
      assignBy: values.assignBy ? values.assignBy : '',
      description: draftToHtml(
        convertToRaw(values.editorState.getCurrentContent())
      ),
      goalId: values.taskDetailsGoalType, // GoalTypeId
      goalOptionId: values.taskDetailsGoalOption, // GoalOptionType
      ...values.goalTypeQualifiers,
      planId: values.taskDetailsPlanType ? values.taskDetailsPlanType : '',
      points: values.taskDetailPoints,
      taskGroupId: values.taskGroup, // taskGroupId
      startDate: values.startDate,
      endDate: values.endDate,
      endTime: values.endTime,
      file: fileObj && fileObj !== '' ? fileObj : '', // File URL
      fileExtention: fileExtention ? fileExtention : '', // File Extention
      files: uploadedFiles && uploadedFiles.length > 0 ? uploadedFiles : [], // Files Array
      fileName: fileName && fileName !== '' ? fileName : '', // File Name
      fileType: singleFileType && singleFileType !== '' ? singleFileType : '',
      id: values.taskId,
      isComplete: false,
      isDeleted: false,
      isUnassigned: values.assignBy === 'unassigned' ? true : false,
      companyId: companyId,
      createdAt: new Date().toISOString(),
    };
  } else {
    newCreatedTaskDetailRef = await publicTaskDetailRef.push();
    values.startDate = moment(values.startDate).format('MM/DD/YYYY');
    values.endDate = moment(values.endDate).format('MM/DD/YYYY');
    values.endTime = moment(values.endTime).format('LT');
    taskObj = {
      empID: values.empID ? values.empID.split('-')[0] : '',
      username: values.empID ? values.empID.split('-')[1] : '',
      title: values.taskDetailTitle,
      assignBy: values.assignBy ? values.assignBy : '',
      description: draftToHtml(
        convertToRaw(values.editorState.getCurrentContent())
      ),
      goalId: values.taskDetailsGoalType, // GoalTypeId
      goalOptionId: values.taskDetailsGoalOption, // GoalOptionType
      ...values.goalTypeQualifiers,
      planId: values.taskDetailsPlanType ? values.taskDetailsPlanType : '',
      points: values.taskDetailPoints,
      taskGroupId: values.taskGroup, // taskGroupId
      startDate: values.startDate,
      endDate: values.endDate,
      endTime: values.endTime,
      file: fileObj && fileObj !== '' ? fileObj : '', // File URL
      fileExtention: fileExtention ? fileExtention : '', // File Extention
      files: uploadedFiles && uploadedFiles.length > 0 ? uploadedFiles : [], // Files Array
      fileName: fileName && fileName !== '' ? fileName : '', // File Name
      fileType: singleFileType && singleFileType !== '' ? singleFileType : '',
      id: newCreatedTaskDetailRef.key,
      isComplete: false,
      isDeleted: false,
      isUnassigned: values.assignBy === 'unassigned' ? true : false,
      companyId: companyId,
      createdAt: new Date().toISOString(),
    };
  }

  if (values.taskId && values.taskId !== '') {
    await db
      .ref(`tasks/${values.taskId}`)
      .update(taskObj)
      .then(async () => {
        await saveTaskInUserCollection(values, taskGroupObj, taskObj, uname);
        setTimeout(() => dispatch(savingTask(false)), 2000);
        setTimeout(() => dispatch(savedTask(true)), 2500);
        setTimeout(() => {
          dispatch(savedTask(false));
          Swal.fire('Added!', 'Your Task Added Successfully.', 'success');
          // alert('Task Added Successfully');
        }, 3500);
        dispatch(clearInputValues());
        /* ------------ Assign By Employee End ---------- */
        let companyId = localStorage.getItem('companyID');
        fetchTaskGroups(companyId);
      })
      .catch((err) => {
        dispatch(savingTask(false));
        dispatch(savedTask(false));
        dispatch(clearInputValues());
      });
  } else {
    await newCreatedTaskDetailRef
      .set(taskObj)
      .then(async () => {
        await saveTaskInUserCollection(values, taskGroupObj, taskObj, uname);
        setTimeout(() => dispatch(savingTask(false)), 2000);
        setTimeout(() => dispatch(savedTask(true)), 2500);
        setTimeout(() => {
          dispatch(savedTask(false));
          Swal.fire('Added!', 'Your Task Added Successfully.', 'success');
          // alert('Task Added Successfully');
        }, 3500);
        dispatch(clearInputValues());
        /* ------------ Assign By Employee End ---------- */
        let companyId = localStorage.getItem('companyID');
        fetchTaskGroups(companyId);
      })
      .catch((err) => {
        dispatch(savingTask(false));
        dispatch(savedTask(false));
        dispatch(clearInputValues());
      });
  }
};

const saveTaskInUserCollection = async (
  values,
  taskGroupObj,
  taskObj,
  uname
) => {
  var taskArray = [];
  var goals = [];
  var taskGroup = [];
  var fcmToken = null;
  /* ------------ Assign By TaskGroup Start ---------- */
  if (values.assignBy === 'taskGroup') {
    var snapshotArray = [];
    await db
      .ref(`users`)
      .once('value')
      .then((snapshot) => {
        snapshot &&
          snapshot.forEach((childSnap) => {
            snapshotArray.push({ ...childSnap.val(), id: childSnap.key });
          });
      });
    const filteredUser = snapshotArray.filter(
      (s) => s.empTaskGroupId === taskGroupObj.id
    );
    const taskGroupUsers = [];
    filteredUser.forEach((user) => {
      if (user) {
        taskGroupUsers.push({
          id: user.id,
          empName: user.empName,
          username: user.username, // TODO Remove UnNessassary
        });
      }
    });
    await db.ref(`tasks/${taskObj.id}`).update({ taskGroupUsers });
    filteredUser.forEach(async (user) => {
      let id = user.id;
      taskGroup = [];
      goals = [];
      taskArray = [];
      fcmToken = null;
      // TODO fix empID and username
      taskObj['empID'] = user.empID;
      taskObj['username'] = user.username;
      taskObj['taskGroupUsers'] = taskGroupUsers;
      // Current User Callback start
      await db
        .ref(`users/${user.username}`)
        .once('value')
        .then(async (snapshot) => {
          // Task CallBack
          taskArray = [];
          // Goals CallBack
          goals = [];
          if (snapshot.val().goals !== undefined) {
            for (let g = 0; g < snapshot.val().goals.length; g++) {
              await goals.push(snapshot.val().goals[g]);
            }
          }
          // TaskGroup CallBack
          taskGroup = [];
          if (snapshot.val().taskGroup !== undefined) {
            for (let t = 0; t < snapshot.val().taskGroup.length; t++) {
              await taskGroup.push(snapshot.val().taskGroup[t]);
            }
          }
          // fcm Token
          fcmToken = null;
          if (snapshot.val().fcmToken !== undefined) {
            fcmToken = snapshot.val().fcmToken;
          }
        });
      // current user callback end.
      // Goals
      if (user.goals === undefined) {
        goals.push(taskObj.goalId);
      } else if (user.goals && user.goals !== undefined) {
        goals.push(taskObj.goalId);
      }
      // TaskGroup
      if (user.taskGroup === undefined) {
        if (!taskGroup.includes(taskObj.taskGroupId)) {
          taskGroup.push(taskObj.taskGroupId);
        }
      } else if (user.taskGroup && user.taskGroup !== undefined) {
        if (!user.taskGroup.includes(taskObj.taskGroupId)) {
          if (!taskGroup.includes(taskObj.taskGroupId)) {
            taskGroup.push(taskObj.taskGroupId);
          }
        }
      }

      await db.ref(`users/${id}`).update({
        taskGroup: taskGroup,
        goals,
      });

      await db
        .ref(`users/${user.username}/tasks`)
        .child(taskObj.id)
        .set(taskObj);

      // Notifications <When No Token>
      const companyId = localStorage.getItem('companyID');
      const publicNotificationRef = db.ref(
        `/users/${user.username}/notifications`
      );
      let newCreatedNotificationRef = await publicNotificationRef.push();
      const newCreatedNotificationObj = {
        notification: {
          title: taskObj.title,
          body: convertHTMLToText(taskObj.description),
          icon: taskObj.file,
        },
        id: newCreatedNotificationRef.key,
        data: {
          taskId: taskObj.id,
          goalId: taskObj.goalId,
          goalOptionId: taskObj.goalOptionId,
          taskGroupId: taskObj.taskGroupId,
          isRead: false,
          companyId: companyId,
          isComplete: false,
          type: 'mobile',
        },
        createdAt: firebase.database.ServerValue.TIMESTAMP,
      };
      await newCreatedNotificationRef.set(newCreatedNotificationObj);

      // When Token Exisit
      if (
        user.fcmToken &&
        user.fcmToken !== undefined &&
        user.fcmToken !== ''
      ) {
        const serverKey = constanst.SERVERKEY;
        const fcmURL = fcmService.sendNotificationURL;
        const config = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `key=${serverKey}`,
          },
        };
        try {
          const dataObj = {
            to: user.fcmToken,
            notification: {
              title: newCreatedNotificationObj.notification.title,
              body: newCreatedNotificationObj.notification.body,
              sound: 'Tri-tone',
              image: newCreatedNotificationObj.notification.icon,
            },
            apns: {
              payload: {
                aps: {
                  'mutable-content': 1,
                },
              },
              fcm_options: {
                image: newCreatedNotificationObj.notification.icon,
              },
            },
            id: newCreatedNotificationObj.id,
            data: {
              taskId: newCreatedNotificationObj.data.taskId,
              goalId: newCreatedNotificationObj.data.goalId,
              goalOptionId: newCreatedNotificationObj.data.goalOptionId,
              taskGroupId: newCreatedNotificationObj.data.taskGroupId,
              isRead: newCreatedNotificationObj.data.isRead,
              companyId: newCreatedNotificationObj.data.companyId,
              isComplete: newCreatedNotificationObj.data.isComplete,
              type: newCreatedNotificationObj.data.type,
            },
            createdAt: newCreatedNotificationObj.createdAt,
          };
          const response = await axios.post(fcmURL, dataObj, config);
          if (response.status === 200) {
            if (response.data && response.data.success === 1) {
            }
          }
        } catch (error) {
          alert('Something Went Wrong!');
        }
      }
    });
    /* ------------ Assign By TaskGroup End ---------- */

    /* ------------ Assign By Employee Start ---------- */
  } else if (values.assignBy === 'employee') {
    taskGroup = [];
    goals = [];
    taskArray = [];
    fcmToken = null;
    // For Goal
    await db
      .ref(`users/${uname}`)
      .once('value')
      .then(async (snapshot) => {
        fcmToken = null;
        if (snapshot.val().fcmToken !== undefined) {
          fcmToken = snapshot.val().fcmToken;
        }
        goals = [];
        if (snapshot.val().goals !== undefined) {
          for (let i = 0; i < snapshot.val().goals.length; i++) {
            goals.push(snapshot.val().goals[i]);
          }
        }
        taskGroup = [];
        if (snapshot.val().taskGroup !== undefined) {
          for (let j = 0; j < snapshot.val().taskGroup.length; j++) {
            taskGroup.push(snapshot.val().taskGroup[j]);
          }
        }
        taskArray = [];

        goals.push(values.taskDetailsGoalType);
        if (!taskGroup.includes(values.taskGroup)) {
          taskGroup.push(values.taskGroup);
        }
        taskArray.push(taskObj);
      });

    await db.ref(`users/${uname}`).update({ taskGroup: taskGroup, goals });

    await db
      .ref(`users/${uname}/tasks`)
      .child(taskObj.id)
      .set(taskObj);

    await saveNotification(uname, taskObj, fcmToken);
  }
  return;
};

const saveNotification = async (uname, taskObj, fcmToken) => {
  const companyId = localStorage.getItem('companyID');
  const publicNotificationRef = db.ref(`/users/${uname}/notifications`);
  let newCreatedNotificationRef = await publicNotificationRef.push();
  const newCreatedNotificationObj = {
    notification: {
      title: taskObj.title,
      body: convertHTMLToText(taskObj.description),
      icon: taskObj.file,
    },
    id: newCreatedNotificationRef.key,
    data: {
      taskId: taskObj.id,
      goalId: taskObj.goalId,
      goalOptionId: taskObj.goalOptionId,
      taskGroupId: taskObj.taskGroupId,
      isRead: false,
      companyId: companyId,
      isComplete: false,
      type: 'mobile',
    },
    createdAt: firebase.database.ServerValue.TIMESTAMP,
  };
  await newCreatedNotificationRef.set(newCreatedNotificationObj);

  // Sending Push Notification CallBack
  if (fcmToken && fcmToken !== undefined && fcmToken !== '') {
    const serverKey = constanst.SERVERKEY;
    const fcmURL = fcmService.sendNotificationURL;
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `key=${serverKey}`,
      },
    };
    try {
      const dataObj = {
        to: fcmToken,
        notification: {
          title: newCreatedNotificationObj.notification.title,
          body: newCreatedNotificationObj.notification.body,
          sound: 'Tri-tone',
          image: newCreatedNotificationObj.notification.icon,
        },
        apns: {
          payload: {
            aps: {
              'mutable-content': 1,
            },
          },
          fcm_options: {
            image: newCreatedNotificationObj.notification.icon,
          },
        },
        id: newCreatedNotificationObj.id,
        data: {
          taskId: newCreatedNotificationObj.data.taskId,
          goalId: newCreatedNotificationObj.data.goalId,
          goalOptionId: newCreatedNotificationObj.data.goalOptionId,
          taskGroupId: newCreatedNotificationObj.data.taskGroupId,
          isRead: newCreatedNotificationObj.data.isRead,
          companyId: newCreatedNotificationObj.data.companyId,
          isComplete: newCreatedNotificationObj.data.isComplete,
          type: newCreatedNotificationObj.data.type,
        },
        createdAt: newCreatedNotificationObj.createdAt,
      };
      await axios.post(fcmURL, dataObj, config);
    } catch (error) {
      Swal.fire('Error', 'Something Went Wrong!', 'Error');
      // alert('Something Went Wrong!');
    }
  }
};

// Fetch All Tasks
export const fetchAllTasks = (companyID) => (dispatch, getState) => {
  const { loaderAction } = loaderActions;
  dispatch(loaderAction(true));
  db.ref('tasks')
    .once('value')
    .then((snapshot) => {
      if (snapshot.exists()) {
        let tasks = _.filter(Object.values(snapshot.val()), {
          companyId: companyID,
        });
        let tasksArray = [];
        if (tasks) {
          tasks.forEach((task) => {
            if (task && task.endDate && task.endTime) {
              task.endDate =
                typeof task.endDate === 'object'
                  ? moment(task.endDate).format('MM/DD/YYYY')
                  : task.endDate;
              task.endTime =
                typeof task.endTime === 'object'
                  ? moment(task.endTime).format('LT')
                  : task.endTime;
              const endDate = moment(`${task.endDate} ${task.endTime}`);
              const currentDate = moment();
              if (currentDate.isBefore(endDate)) {
                tasksArray.push(task);
              }
            }
          });
        }
        dispatch(getTasks(tasksArray));
        dispatch(loaderAction(false));
      }
    }).catch((err) => {
      dispatch(loaderAction(false));
    })
};

// Fetch All TasKGroups
export const fetchTaskGroups = (companyID) => (dispatch, getState) => {
  db.ref('taskGroups/')
    .once('value')
    .then((snapshot) => {
      if (snapshot.exists()) {
        const snapshotValue = Object.values(snapshot.val());
        let taskGroups = _.filter(snapshotValue, { companyId: companyID });
        dispatch(setTaskGroups(taskGroups));
      }
    });
};

// Delete Task Group
export const delteTaskGroup = (taskGroupId) => async (dispatch, getState) => {
  const { inputValues } = getState().taskDetailReducer;
  const { taskGroupOptions } = inputValues;
  await db
    .ref(`taskGroups/${taskGroupId}`)
    .remove()
    .then(() => {
      let taskGroups = _.filter(
        taskGroupOptions,
        (taskGroup) => taskGroup.id !== taskGroupId
      );
      dispatch(setTaskGroups(taskGroups));
    })
    .catch((err) => {
      Swal.fire('Error', err.message, 'Error');
      // alert(err.message);
    });
};

// Delete Tasks
export const deleteTasks = (values) => async (dispatch, getState) => {
  const taskRef = db.ref(`tasks`);
  const stateTasks = getState().taskDetailReducer.tasks;
  try {
    const companyId = localStorage.getItem('companyID');
    const currentUserId = localStorage.getItem('username');
    if (!values.file.startsWith('/static/')) {
      try {
        if (values.files && values.files.length >= 1) {
          values.files.forEach(async (img) => {
            try {
              await storage.refFromURL(img.url).delete();
            } catch (err) {
              console.error(err);
            }
          });
        } else {
          try {
            await storage.refFromURL(values.file).delete();
          } catch (err) {
            console.error(err);
          }
        }
      } catch (err) {
        console.error(err.message);
      }
    }
    await taskRef.child(values.id).remove();
    /* ------------- Task Remove from user collection start -----------------*/
    if (values.assignBy === 'employee') {
      if (values.username !== '') {
        // Remove Goals From User Collection
        let goals = [];
        const goalSnapshot = await db
          .ref(`users/${values.username}/goals`)
          .once('value');
        if (goalSnapshot.exists()) {
          goalSnapshot.forEach((childSnap) => {
            goals.push(childSnap.val());
          });
        }
        if (goals) {
          let notMatchedGoals = [];
          let matchedGoals = [];
          _.forEach(goals, (goal) => {
            if (goal) {
              if (goal === values.goalId) {
                matchedGoals.push(goal);
              } else {
                notMatchedGoals.push(goal);
              }
            }
          });
          if (matchedGoals.length > 0) {
            matchedGoals.splice(0, 1);
          }
          goals = [];
          goals = [...notMatchedGoals, ...matchedGoals];
          try {
            await db.ref(`users/${values.username}`).update({ goals });
          } catch (error) {}
        }

        // Remove Tasks from Employee Collection
        await db.ref(`users/${values.username}/tasks/${values.id}`).remove();
        /*--------- Remove Notification By Task Id From Admin Start ---------*/
        try {
          let adminNotificationsArray = [];
          const snapshot = await db
            .ref(`users/${currentUserId}/notifications`)
            .once('value');
          snapshot.forEach((childSnap) => {
            adminNotificationsArray.push({
              ...childSnap.val(),
              key: childSnap.key,
            });
          });
          if (adminNotificationsArray) {
            const obj = adminNotificationsArray.find(
              (n) => n && n.key && n.data && n.data.taskId === values.id
            );
            if (obj && obj.key) {
              const notificationRef = db.ref(
                `/users/${values.username}/notifications/${obj.key}`
              );
              notificationRef.remove();
            }
          }
        } catch (err) {
          console.error(err.message);
        }
        /*------------ Remove Notification By Task Id From Admin End ---------*/
        /*------------ Remove Notification By Task Id From Employee Start ---------*/
        try {
          let employeeNotificationsArray = [];
          const snapshot = await db
            .ref(`users/${values.username}/notifications`)
            .once('value');
          snapshot.forEach((childSnap) => {
            employeeNotificationsArray.push({
              ...childSnap.val(),
              key: childSnap.key,
            });
          });
          if (employeeNotificationsArray) {
            const obj = employeeNotificationsArray.find(
              (n) => n && n.key && n.data && n.data.taskId === values.id
            );
            if (obj && obj.key) {
              const notificationRef = db.ref(
                `/users/${values.username}/notifications/${obj.key}`
              );
              notificationRef.remove();
            }
          }
        } catch (err) {
          console.error(err.message);
        }
        /*------------ Remove Notification By Task Id From Employee End ---------*/
      }
    } else if (values.assignBy === 'taskGroup') {
      let snapshotArray = [];
      await db
        .ref(`users`)
        .once('value')
        .then((snapshot) => {
          if (snapshot.exists()) {
            snapshot.forEach((childSnap) => {
              snapshotArray.push({ ...childSnap.val(), id: childSnap.key });
            });
          }
        });
      const filteredUser = snapshotArray.filter(
        (user) => user.empTaskGroupId === values.taskGroupId
      );
      /*----------- Remove Notification By Task Id From Admin Start ---------*/
      try {
        let notificationsAdminArray = [];
        const snapshot = await db
          .ref(`users/${currentUserId}/notifications`)
          .once('value');
        snapshot.forEach((childSnap) => {
          notificationsAdminArray.push({
            ...childSnap.val(),
            key: childSnap.key,
          });
        });
        if (notificationsAdminArray) {
          const obj = notificationsAdminArray.find(
            (n) => n && n.key && n.data && n.data.taskId === values.id
          );
          if (obj && obj.key) {
            const notificationRef = db.ref(
              `/users/${values.username}/notifications/${obj.key}`
            );
            notificationRef.remove();
          }
        }
      } catch (err) {
        console.error(err.message);
      }

      /*------------ Remove Notification By Task Id From Admin End ---------*/
      if (filteredUser) {
        filteredUser.forEach(async (user) => {
          if (user && user.username) {
            // Remove Goals From User Collection
            let goalsArray = [];
            const goalSnapshot = await db
              .ref(`users/${user.username}/goals`)
              .once('value');
            if (goalSnapshot.exists()) {
              goalSnapshot.forEach((childSnap) => {
                goalsArray.push(childSnap.val());
              });
            }
            if (goalsArray) {
              let notMatchedGoals = [];
              let matchedGoals = [];
              _.forEach(goalsArray, (goal) => {
                if (goal) {
                  if (goal === values.goalId) {
                    matchedGoals.push(goal);
                  } else {
                    notMatchedGoals.push(goal);
                  }
                }
              });
              if (matchedGoals.length > 0) {
                matchedGoals.splice(0, 1);
              }
              goalsArray = [];
              goalsArray = [...notMatchedGoals, ...matchedGoals];
              try {
                await db
                  .ref(`users/${user.username}`)
                  .update({ goals: goalsArray });
              } catch (error) {}
            }

            // Remove Tasks from Employee Collection
            await db.ref(`users/${user.username}/tasks/${values.id}`).remove();
            /*---------- Remove Notification By Task Id From Employee Start ---------*/
            try {
              let notificationsArray = [];
              const snapshot = await db
                .ref(`users/${user.username}/notifications`)
                .once('value');
              snapshot.forEach((childSnap) => {
                notificationsArray.push({
                  ...childSnap.val(),
                  key: childSnap.key,
                });
              });
              if (notificationsArray) {
                const obj = notificationsArray.find(
                  (n) => n && n.key && n.data && n.data.taskId === values.id
                );
                if (obj && obj.key) {
                  const notificationRef = db.ref(
                    `/users/${user.username}/notifications/${obj.key}`
                  );
                  notificationRef.remove();
                }
              }
            } catch (err) {
              console.error(err.message);
            }
            /*---------------- Remove Notification By Task Id From Employee End ---------*/
          }
        });
      }
    }
    /* ------------- Task Remove from user collection end -----------------*/
    // Update Member in Store
    let usersArray = [];
    await db
      .ref('users')
      .once('value')
      .then((snapshot) => {
        if (snapshot.exists()) {
          snapshot.forEach((childSnap) => {
            usersArray.push({ ...childSnap.val(), id: childSnap.key });
          });
        }
      });
    let employees = usersArray;
    employees = _.filter(employees, { companyId: companyId, type: 'Employee' });
    dispatch(memberActions.setMember(employees));
    await dispatch(memberActions.fetchMember(usersArray));
    // Update Store Tasks.
    let tasksArray = stateTasks.filter((t) => t.id !== values.id);
    dispatch(getTasks(tasksArray));
    return values;
  } catch (error) {
    console.error(error);
  }
};

export const convertHTMLToText = (text) => {
  const editorDescription = EditorState.createWithContent(
    ContentState.createFromBlockArray(convertFromHTML(text))
  );
  const dataObj = convertToRaw(editorDescription.getCurrentContent());
  let description = '';
  if (dataObj) {
    description = dataObj.blocks[0].text;
  } else {
    description = text.replace(/(<([^>]+)>)/gi, '');
  }
  return description;
};

const notificationEmployeeUpdate = async (
  username,
  taskObj,
  fcmToken,
  taskId
) => {
  const companyId = localStorage.getItem('companyID');
  const publicNotificationRef = db.ref(
    `users/${taskObj.username}/notifications`
  );
  let newCreatedNotificationRef = await publicNotificationRef.push();
  let description = convertHTMLToText(taskObj.description);
  const newCreatedNotificationObj = {
    notification: {
      title: taskObj.title,
      body: description,
      icon: taskObj.file ? taskObj.file : '',
    },
    id: newCreatedNotificationRef.key,
    data: {
      taskId: taskId,
      goalId: taskObj.goalId,
      goalOptionId: taskObj.goalOptionId,
      taskGroupId: taskObj.taskGroupId,
      isRead: false,
      companyId: companyId,
      isComplete: false,
      type: 'mobile',
    },
    createdAt: firebase.database.ServerValue.TIMESTAMP,
  };
  await newCreatedNotificationRef.set(newCreatedNotificationObj);

  // Sending Push Notification CallBack
  if (fcmToken && fcmToken !== undefined && fcmToken !== '') {
    const serverKey = constanst.SERVERKEY;
    const fcmURL = fcmService.sendNotificationURL;
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `key=${serverKey}`,
      },
    };
    try {
      const dataObj = {
        to: fcmToken,
        notification: {
          title: newCreatedNotificationObj.notification.title,
          body: newCreatedNotificationObj.notification.body,
          sound: 'Tri-tone',
          image: newCreatedNotificationObj.notification.icon,
        },
        apns: {
          payload: {
            aps: {
              'mutable-content': 1,
            },
          },
          fcm_options: {
            image: newCreatedNotificationObj.notification.icon,
          },
        },
        id: newCreatedNotificationObj.id,
        data: {
          taskId: newCreatedNotificationObj.data.taskId,
          goalId: newCreatedNotificationObj.data.goalId,
          goalOptionId: newCreatedNotificationObj.data.goalOptionId,
          taskGroupId: newCreatedNotificationObj.data.taskGroupId,
          isRead: newCreatedNotificationObj.data.isRead,
          companyId: newCreatedNotificationObj.data.companyId,
          isComplete: newCreatedNotificationObj.data.isComplete,
          type: newCreatedNotificationObj.data.type,
        },
        createdAt: newCreatedNotificationObj.createdAt,
      };
      await axios.post(fcmURL, dataObj, config);
    } catch (error) {
      Swal.fire('Error', `Something Went Wrong`, 'Error');
      // alert('Something Went Wrong!');
    }
  }
};

const notificationRemoveEmployeeUpdate = async (selectedEditedTask) => {
  try {
    let employeeNotificationsArray = [];
    const snapshot = await db
      .ref(`users/${selectedEditedTask.username}/notifications`)
      .once('value');
    snapshot.forEach((childSnap) => {
      employeeNotificationsArray.push({
        ...childSnap.val(),
        key: childSnap.key,
      });
    });
    if (employeeNotificationsArray) {
      const obj = employeeNotificationsArray.find(
        (n) => n && n.key && n.data && n.data.taskId === selectedEditedTask.id
      );
      if (obj && obj.key) {
        await db
          .ref(`/users/${selectedEditedTask.username}/notifications/${obj.key}`)
          .remove();
      }
    }
  } catch (err) {
    console.error(err.message);
  }
};

// Update Tasks
export const updateTaskDetails = (selectedEditedTask) => async (
  dispatch,
  getState
) => {
  // `${new Date().toISOString()}-${file.name}`
  const values = getState().taskDetailReducer.inputValues;
  const tasks = getState().taskDetailReducer.tasks;
  const members = getState().memberReducer.users;

  dispatch(savingTask(true));
  // Images Section  Start
  var files = values.file;
  var uploadedFiles = [];
  // Remove Files From Storage
  if (values.removedFiles && values.removedFiles.length > 0) {
    await values.removedFiles.forEach(async (img) => {
      try {
        if (!img.url.startsWith('/static/')) {
          await storage.refFromURL(img.url).delete();
        }
      } catch (err) {
        console.error(err);
      }
    });
  }

  // Upload Img To Storage
  if (files && files.length >= 1) {
    const array = Array.from({ length: files.length }, (value, index) => index);
    uploadedFiles = await Promise.all(
      await array.map(async (index) => {
        const file = files[index];
        const metadata = { contentType: file.type };
        let extention = null;
        let fileType = null;
        if (docType.includes(file.type)) {
          extention = file.name.split('.').pop(-1);
        } else if (imgTypes.includes(file.type)) {
          extention = file.name.split('.').pop(-1);
        }
        if (videoTypes.includes(file.type)) {
          fileType = 'video';
        } else if (docType.includes(file.type)) {
          fileType = 'document';
        } else if (imgTypes.includes(file.type)) {
          fileType = 'image';
        }
        // delete file.fileType;
        const uploadTask = storage
          .ref(`tasks/${file.name}`)
          .put(file, metadata);
        const url = await new Promise((resolve, reject) => {
          uploadTask.on(
            'state_changed',
            () => {},
            (error) => reject(error),
            async () => {
              try {
                const downloadUrl = await uploadTask.snapshot.ref.getDownloadURL();
                resolve(downloadUrl);
              } catch (err) {
                console.error(err.message);
              }
            }
          );
        });
        return {
          name: file.name,
          url,
          type: fileType,
          fileExtention: extention,
        };
      })
    );
  }

  var fileObj = null;
  var fileName = null;
  let fileExtention = null;
  let filesArray = [];
  if (uploadedFiles && uploadedFiles.length > 0) {
    if (values.originalFiles) {
      filesArray = values.originalFiles.concat(uploadedFiles);
    } else {
      filesArray = uploadedFiles;
    }
    _.forEach(filesArray, (file) => {
      if (file.isImage) delete file.isImage;
      if (file.isDocument) delete file.isDocument;
      if (file.isVideo) delete file.isVideo;
    });
    const object = filesArray.find((file) =>
      imgExtentionTypes.includes(file.fileExtention)
    );
    if (object) {
      fileObj = object.url;
      fileExtention = object.fileExtention;
      fileName = object.name;
    } else {
      if (filesArray && filesArray.length > 0) {
        fileObj = filesArray[0].url;
        fileExtention = filesArray[0].fileExtention;
        fileName = filesArray[0].name;
      } else {
        fileObj = '';
        fileExtention = '';
        fileName = '';
      }
    }
  } else {
    filesArray = values.originalFiles;
    if (filesArray && filesArray.length > 0) {
      _.forEach(filesArray, (file) => {
        if (file.isImage) delete file.isImage;
        if (file.isDocument) delete file.isDocument;
        if (file.isVideo) delete file.isVideo;
      });
      fileObj = filesArray[0].url;
      fileExtention = filesArray[0].fileExtention;
      fileName = filesArray[0].name;
    }
  }
  values.startDate =
    typeof values.startDate === 'object'
      ? moment(values.startDate).format('MM/DD/YYYY')
      : values.startDate;
  values.endDate =
    typeof values.endDate === 'object'
      ? moment(values.endDate).format('MM/DD/YYYY')
      : values.endDate;
  values.endTime =
    typeof values.endTime === 'object'
      ? moment(values.endTime).format('LT')
      : values.endTime;
  const taskObj = {
    assignBy: values.assignBy,
    empID:
      values.empID && values.empID.includes('-')
        ? values.empID.split('-')[0]
        : values.empID,
    username:
      values.empID && values.empID.includes('-')
        ? values.empID.split('-')[1]
        : selectedEditedTask.username,
    title: values.taskDetailTitle,
    description: draftToHtml(
      convertToRaw(values.editorState.getCurrentContent())
    ),
    goalId: values.taskDetailsGoalType, // GoalTypeId
    goalOptionId: values.taskDetailsGoalOption, // GoalOptionType
    ...values.goalTypeQualifiers,
    points: values.taskDetailPoints,
    taskGroupId: values.taskGroup, // taskGroupId
    startDate: values.startDate,
    endDate: values.endDate,
    endTime: values.endTime,
    file: fileObj && fileObj !== '' ? fileObj : '', // File URL
    fileExtention: fileExtention ? fileExtention : '', // File Extention
    files: filesArray, // Files Array
    fileName: fileName && fileName !== '' ? fileName : '', // File Name
    updatedAt: new Date().toISOString(),
  };

  await db
    .ref(`tasks/${selectedEditedTask.id}`)
    // .once('value')
    .update(taskObj)
    .then(async (res) => {
      let companyId = localStorage.getItem('companyID');
      const snapshot = await db
        .ref(`tasks/${selectedEditedTask.id}`)
        .once('value');
      let snapshotValues = snapshot.val();
      /* ------------------ Task Update In User Collection Start ------------------- */
      // When Task AssignBy === 'employee' and values changed [Done]
      if (
        selectedEditedTask.assignBy === 'employee' &&
        taskObj.assignBy === 'employee' &&
        selectedEditedTask.username === taskObj.username
      ) {
        try {
          await db
            .ref(`users/${taskObj.username}/tasks/${selectedEditedTask.id}`)
            .update(taskObj);
        } catch (err) {
          console.error(err);
        }
        // Updated Task Update in User Collection.
      }
      // When Task AssignBy === 'employee' and username changed [Done]
      if (
        selectedEditedTask.assignBy === 'employee' &&
        taskObj.assignBy === 'employee' &&
        selectedEditedTask.username !== taskObj.username
      ) {
        const snap = await db
          .ref(`users/${taskObj.username}/fcmToken`)
          .once('value');
        const userFcmToken = snap.val();

        // Remove from original username collection
        try {
          await db
            .ref(
              `users/${selectedEditedTask.username}/tasks/${selectedEditedTask.id}`
            )
            .remove();
          // Remove Notification Also
          await notificationRemoveEmployeeUpdate(selectedEditedTask);
        } catch (err) {
          console.error(err);
        }
        // Add In New User Collection in tasks collection.
        // Add Notification and Trigger API Also.
        try {
          await db
            .ref(`users/${taskObj.username}/tasks`)
            .child(selectedEditedTask.id)
            .set(snapshotValues);

          // Notification Section
          await notificationEmployeeUpdate(
            taskObj.username,
            snapshotValues,
            userFcmToken,
            selectedEditedTask.id
          );
        } catch (err) {
          console.error(err);
        }
      }
      // When Task AssignBy === 'taskGroup' and values changed [Done]
      if (
        selectedEditedTask.assignBy === 'taskGroup' &&
        taskObj.assignBy === 'taskGroup' &&
        selectedEditedTask.taskGroupId === taskObj.taskGroupId
      ) {
        const taskGroupUsers = members.filter(
          (s) => s.empTaskGroupId === taskObj.taskGroupId
        );
        taskGroupUsers &&
          taskGroupUsers.forEach(async (user) => {
            if (user && user.username) {
              try {
                await db
                  .ref(`users/${user.username}/tasks/${selectedEditedTask.id}`)
                  .update(snapshotValues);
              } catch (err) {
                console.error(err);
              }
            }
          });
        // Update The Task Object to All User Which is Associated with the TaskGroup
      }
      // When Task AssignBy === 'taskGroup' and TaskGroup Changed [Done]
      if (
        selectedEditedTask.assignBy === 'taskGroup' &&
        taskObj.assignBy === 'taskGroup' &&
        selectedEditedTask.taskGroupId !== taskObj.taskGroupId
      ) {
        // Remove from original users collection from all users associated with that taskGroup
        // Remove Notifications ALso for Original Users
        const oldTaskGroupMembers = members.filter(
          (s) => s.empTaskGroupId === selectedEditedTask.taskGroupId
        );
        oldTaskGroupMembers &&
          oldTaskGroupMembers.forEach(async (user) => {
            if (user && user.username) {
              try {
                await db
                  .ref(`users/${user.username}/tasks/${selectedEditedTask.id}`)
                  .remove();
                // await notificationRemoveEmployeeUpdate(selectedEditedTask);

                try {
                  let notificationsArray = [];
                  const snapshot = await db
                    .ref(`users/${user.username}/notifications`)
                    .once('value');
                  snapshot.forEach((childSnap) => {
                    notificationsArray.push({
                      ...childSnap.val(),
                      key: childSnap.key,
                    });
                  });
                  if (notificationsArray) {
                    const obj = notificationsArray.find(
                      (n) =>
                        n &&
                        n.key &&
                        n.data &&
                        n.data.taskId === selectedEditedTask.id
                    );
                    if (obj && obj.key) {
                      await db
                        .ref(`/users/${user.username}/notifications/${obj.key}`)
                        .remove();
                    }
                  }
                } catch (err) {
                  console.error(err.message);
                }

                // Remove Notification AS Well
              } catch (err) {
                console.error(err.message);
              }
            }
          });
        // Add In New User Collection in tasks collection of current taskGroup.
        // Add Notification and Trigger API
        const newTaskGroupMembers = members.filter(
          (s) => s.empTaskGroupId === taskObj.taskGroupId
        );

        const taskGroupUsers = [];
        newTaskGroupMembers.forEach((user) => {
          if (user) {
            taskGroupUsers.push({
              id: user.id,
              empName: user.empName,
              username: user.username, // TODO Remove UnNessassary
            });
          }
        });
        await db
          .ref(`tasks/${selectedEditedTask.id}`)
          .update({ taskGroupUsers });
        snapshotValues['taskGroupUsers'] = taskGroupUsers;
        newTaskGroupMembers &&
          newTaskGroupMembers.forEach(async (user) => {
            if (user && user.username) {
              try {
                await db
                  .ref(`users/${user.username}/tasks`)
                  .child(selectedEditedTask.id)
                  .set(snapshotValues);
                // Notification Section
                // await notificationEmployeeUpdate(
                //   user.username,
                //   snapshotValues,
                //   user.fcmToken,
                //   selectedEditedTask.id
                // );
                // Notifications <When No Token>
                const companyId = localStorage.getItem('companyID');
                const publicNotificationRef = db.ref(
                  `/users/${user.username}/notifications`
                );
                let newCreatedNotificationRef = await publicNotificationRef.push();
                const newCreatedNotificationObj = {
                  notification: {
                    title: snapshotValues.title,
                    body: convertHTMLToText(snapshotValues.description),
                    icon: snapshotValues.file,
                  },
                  id: newCreatedNotificationRef.key,
                  data: {
                    taskId: selectedEditedTask.id,
                    goalId: snapshotValues.goalId,
                    goalOptionId: snapshotValues.goalOptionId,
                    taskGroupId: snapshotValues.taskGroupId,
                    isRead: false,
                    companyId: companyId,
                    isComplete: false,
                    type: 'mobile',
                  },
                  createdAt: firebase.database.ServerValue.TIMESTAMP,
                };
                await newCreatedNotificationRef.set(newCreatedNotificationObj);

                // When Token Exisit
                if (
                  user.fcmToken &&
                  user.fcmToken !== undefined &&
                  user.fcmToken !== ''
                ) {
                  const serverKey = constanst.SERVERKEY;
                  const fcmURL = fcmService.sendNotificationURL;
                  const config = {
                    headers: {
                      'Content-Type': 'application/json',
                      Authorization: `key=${serverKey}`,
                    },
                  };
                  try {
                    const dataObj = {
                      to: user.fcmToken,
                      notification: {
                        title: newCreatedNotificationObj.notification.title,
                        body: newCreatedNotificationObj.notification.body,
                        sound: 'Tri-tone',
                        image: newCreatedNotificationObj.notification.icon,
                      },
                      apns: {
                        payload: {
                          aps: {
                            'mutable-content': 1,
                          },
                        },
                        fcm_options: {
                          image: newCreatedNotificationObj.notification.icon,
                        },
                      },
                      id: newCreatedNotificationObj.id,
                      data: {
                        taskId: newCreatedNotificationObj.data.taskId,
                        goalId: newCreatedNotificationObj.data.goalId,
                        goalOptionId:
                          newCreatedNotificationObj.data.goalOptionId,
                        taskGroupId: newCreatedNotificationObj.data.taskGroupId,
                        isRead: newCreatedNotificationObj.data.isRead,
                        companyId: newCreatedNotificationObj.data.companyId,
                        isComplete: newCreatedNotificationObj.data.isComplete,
                        type: newCreatedNotificationObj.data.type,
                      },
                      createdAt: newCreatedNotificationObj.createdAt,
                    };
                    const response = await axios.post(fcmURL, dataObj, config);
                    if (response.status === 200) {
                      if (response.data && response.data.success === 1) {
                      }
                    }
                  } catch (error) {
                    Swal.fire('Error', `Something Went Wrong`, 'Error');
                    // alert('Something Went Wrong!');
                  }
                }
              } catch (err) {
                console.error(err.message);
              }
            }
          });
      }

      // When Task AssignBy === 'employee' to 'taskGroup' [Done]
      if (
        selectedEditedTask.assignBy === 'employee' &&
        taskObj.assignBy === 'taskGroup'
      ) {
        // 1. Remove That User Which is Assosiated with that task.
        try {
          if (
            selectedEditedTask.username &&
            selectedEditedTask.username !== ''
          ) {
            await db
              .ref(
                `users/${selectedEditedTask.username}/tasks/${selectedEditedTask.id}`
              )
              .remove();
            await notificationRemoveEmployeeUpdate(selectedEditedTask);
          }
        } catch (err) {
          console.error(err);
        }
        // 2. Filter TaskGroup Members
        const newTaskGroupMembers = members.filter(
          (s) => s.empTaskGroupId === taskObj.taskGroupId
        );
        const taskGroupUsers = [];
        newTaskGroupMembers.forEach((user) => {
          if (user) {
            taskGroupUsers.push({
              id: user.id,
              empName: user.empName,
              username: user.username, // TODO Remove UnNessassary
            });
          }
        });
        // 3. Remove username and empId from snapshotValues and update in task.
        await db
          .ref(`tasks/${selectedEditedTask.id}`)
          .update({ taskGroupUsers, empID: '', username: '' });
        snapshotValues.empID = '';
        snapshotValues.username = '';
        snapshotValues['taskGroupUsers'] = taskGroupUsers;

        // 4. Add Task In TaskGroup Members
        newTaskGroupMembers &&
          newTaskGroupMembers.forEach(async (user) => {
            if (user && user.username) {
              try {
                await db
                  .ref(`users/${user.username}/tasks`)
                  .child(selectedEditedTask.id)
                  .set(snapshotValues);

                // Notification Section
                // await notificationEmployeeUpdate(
                //   user.username,
                //   snapshotValues,
                //   user.fcmToken,
                //   selectedEditedTask.id
                // );
                // Notifications <When No Token>
                const companyId = localStorage.getItem('companyID');
                const publicNotificationRef = db.ref(
                  `/users/${user.username}/notifications`
                );
                let newCreatedNotificationRef = await publicNotificationRef.push();
                const newCreatedNotificationObj = {
                  notification: {
                    title: snapshotValues.title,
                    body: convertHTMLToText(snapshotValues.description),
                    icon: snapshotValues.file,
                  },
                  id: newCreatedNotificationRef.key,
                  data: {
                    taskId: selectedEditedTask.id,
                    goalId: snapshotValues.goalId,
                    goalOptionId: snapshotValues.goalOptionId,
                    taskGroupId: snapshotValues.taskGroupId,
                    isRead: false,
                    companyId: companyId,
                    isComplete: false,
                    type: 'mobile',
                  },
                  createdAt: firebase.database.ServerValue.TIMESTAMP,
                };
                await newCreatedNotificationRef.set(newCreatedNotificationObj);

                // When Token Exisit
                if (
                  user.fcmToken &&
                  user.fcmToken !== undefined &&
                  user.fcmToken !== ''
                ) {
                  const serverKey = constanst.SERVERKEY;
                  const fcmURL = fcmService.sendNotificationURL;
                  const config = {
                    headers: {
                      'Content-Type': 'application/json',
                      Authorization: `key=${serverKey}`,
                    },
                  };
                  try {
                    const dataObj = {
                      to: user.fcmToken,
                      notification: {
                        title: newCreatedNotificationObj.notification.title,
                        body: newCreatedNotificationObj.notification.body,
                        sound: 'Tri-tone',
                        image: newCreatedNotificationObj.notification.icon,
                      },
                      apns: {
                        payload: {
                          aps: {
                            'mutable-content': 1,
                          },
                        },
                        fcm_options: {
                          image: newCreatedNotificationObj.notification.icon,
                        },
                      },
                      id: newCreatedNotificationObj.id,
                      data: {
                        taskId: newCreatedNotificationObj.data.taskId,
                        goalId: newCreatedNotificationObj.data.goalId,
                        goalOptionId:
                          newCreatedNotificationObj.data.goalOptionId,
                        taskGroupId: newCreatedNotificationObj.data.taskGroupId,
                        isRead: newCreatedNotificationObj.data.isRead,
                        companyId: newCreatedNotificationObj.data.companyId,
                        isComplete: newCreatedNotificationObj.data.isComplete,
                        type: newCreatedNotificationObj.data.type,
                      },
                      createdAt: newCreatedNotificationObj.createdAt,
                    };
                    const response = await axios.post(fcmURL, dataObj, config);
                    if (response.status === 200) {
                      if (response.data && response.data.success === 1) {
                      }
                    }
                  } catch (error) {
                    Swal.fire('Error', `Something Went Wrong`, 'Error');
                    // alert('Something Went Wrong!');
                  }
                }
              } catch (err) {
                console.error(err);
              }
            }
          });
      }
      // When Task AssignBy === 'taskGroup' to 'employee' [Done]
      if (
        selectedEditedTask.assignBy === 'taskGroup' &&
        taskObj.assignBy === 'employee'
      ) {
        const snap = await db
          .ref(`users/${taskObj.username}/fcmToken`)
          .once('value');
        const userFcmToken = snap.val();

        // 1. Remove TaskGroup User Which is Assosiated with that task.
        const originalTaskGroupMembers = members.filter(
          (s) => s.empTaskGroupId === selectedEditedTask.taskGroupId
        );
        await originalTaskGroupMembers.forEach(async (user) => {
          if (user && user.username) {
            try {
              await db
                .ref(`users/${user.username}/tasks/${selectedEditedTask.id}`)
                .remove();
              // await notificationRemoveEmployeeUpdate(selectedEditedTask);
              try {
                let employeeNotificationsArray = [];
                const snapshot = await db
                  .ref(`users/${user.username}/notifications`)
                  .once('value');
                snapshot.forEach((childSnap) => {
                  employeeNotificationsArray.push({
                    ...childSnap.val(),
                    key: childSnap.key,
                  });
                });
                if (employeeNotificationsArray) {
                  const obj = employeeNotificationsArray.find(
                    (n) =>
                      n &&
                      n.key &&
                      n.data &&
                      n.data.taskId === selectedEditedTask.id
                  );
                  if (obj && obj.key) {
                    await db
                      .ref(`/users/${user.username}/notifications/${obj.key}`)
                      .remove();
                  }
                }
              } catch (err) {
                console.error(err.message);
              }
            } catch (err) {
              console.error(err);
            }
          }
        });

        // 2. Change Payload to Employee Task Like
        await db.ref(`tasks/${selectedEditedTask.id}`).update({
          taskGroupUsers: [],
          empID: taskObj.empID,
          username: taskObj.username,
        });
        delete snapshotValues.taskGroupUsers;
        snapshotValues['empID'] = taskObj.empID;
        snapshotValues['username'] = taskObj.username;

        // 3. Add Current Task to Employee Selected Member's
        try {
          await db
            .ref(`users/${taskObj.username}/tasks`)
            .child(selectedEditedTask.id)
            .set(snapshotValues);

          // Notification Section
          await notificationEmployeeUpdate(
            taskObj.username,
            snapshotValues,
            userFcmToken,
            selectedEditedTask.id
          );
        } catch (err) {
          console.error(err);
        }
      }
      /* ------------------ Task Update In User Collection End ------------------- */

      // Update Member in Store
      let usersArray = [];
      await db
        .ref(`users`)
        .once('value')
        .then((snapshot) => {
          if (snapshot.exists()) {
            snapshot.forEach((childSnap) => {
              usersArray.push({ ...childSnap.val(), id: childSnap.key });
            });
          }
        });
      let employees = usersArray;
      employees = _.filter(employees, {
        companyId: companyId,
        type: 'Employee',
      });
      dispatch(memberActions.setMember(employees));
      await dispatch(memberActions.fetchMember(usersArray));

      // Task Filter
      let tasksArray = [...tasks];
      const taskIndex = tasksArray.findIndex(
        (t) => t.id === selectedEditedTask.id
      );
      tasksArray[taskIndex] = snapshotValues;
      let validTasks = [];
      if (tasksArray) {
        tasksArray.forEach((task) => {
          if (task && task.endDate && task.endTime) {
            const endDate = moment(`${task.endDate} ${task.endTime}`);
            const currentDate = moment();
            if (currentDate.isBefore(endDate)) {
              validTasks.push(task);
            }
          }
        });
      }
      dispatch(getTasks(validTasks));
      /* ------------------ Task Update In User Collection End ------------------- */
      setTimeout(() => dispatch(savingTask(false)), 1000);
      setTimeout(() => dispatch(savedTask(true)), 1500);
      setTimeout(() => {
        dispatch(savedTask(false));
      }, 2000);
      return taskObj;
    })
    .catch((err) => {
      console.error(err);
      dispatch(savingTask(false));
      dispatch(savedTask(false));
      dispatch(clearTaskInputModal());
    });
};

/* -------------------------- Actions Types -------------------------------*/

export const CLEAR_INPUT_VALUES = 'CLEAR_INPUT_VALUES';
export const CLEAR_INPUT_FILES = 'CLEAR_INPUT_FILES';
export const SET_TASK_GROUPS = 'SET_TASK_GROUPS';
export const SET_TASK_DETAIL_INPUT_VALUE = 'SET_TASK_DETAIL_INPUT_VALUE';
export const SET_GOAL_TYPE_OPTION_KEY = 'SET_GOAL_TYPE_OPTION_KEY';
export const SET_GOAL_TYPE_QUALIFIER_INPUT_VALUE =
  'SET_GOAL_TYPE_QUALIFIER_INPUT_VALUE';
export const SAVING_TASK = 'SAVING_TASK';
export const SAVED_TASK = 'SAVED_TASK';

export const GET_TASKS = 'GET_TASKS';
export const CLEAR_TASKS = 'CLEAR_TASKS';

export const CLEAR_FILE = 'CLEAR_FILE';
export const CLEAR_ORIGINAL_FILES = 'CLEAR_ORIGINAL_FILES';
export const SET_REMOVE_FILES = 'SET_REMOVE_FILES';
export const CLEAR_MODAL_INPUT_VALUES = 'CLEAR_MODAL_INPUT_VALUES';
/* -------------------------- Actions Funtions --------------------------- */

export const clearTaskGroups = () => (dispatch) => {
  dispatch(clearInputValues());
};

const clearInputValues = () => ({ type: CLEAR_INPUT_VALUES });

export const clearTaskFiles = (file) => (dispatch, getState) => {
  const selectedFile = file;
  const values = getState().taskDetailReducer.inputValues;
  const files = [...values.file].filter((file) => file !== selectedFile);
  dispatch(clearInputFiles(files));
};

const clearInputFiles = (file) => ({ type: CLEAR_INPUT_FILES, file });

export const setTaskGroups = (taskGroups) => ({
  type: SET_TASK_GROUPS,
  taskGroups,
});

export const setTaskDetailInputValue = (name, value) => {
  return {
    type: SET_TASK_DETAIL_INPUT_VALUE,
    name,
    value,
  };
};

export const getGoalTypeOptionKeys = (keys) => (dispatch) => {
  return keys.forEach((key) => {
    return dispatch(setGoalTypeOptionKeys(key));
  });
};

const setGoalTypeOptionKeys = (key) => ({
  type: SET_GOAL_TYPE_OPTION_KEY,
  key,
});

export const setGoalTypeQualifierInputValue = (name, value) => {
  return {
    type: SET_GOAL_TYPE_QUALIFIER_INPUT_VALUE,
    name,
    value,
  };
};

const savingTask = (bool) => ({ type: SAVING_TASK, savingTask: bool });

const savedTask = (bool) => ({ type: SAVED_TASK, savedTask: bool });

const getTasks = (data) => ({ type: GET_TASKS, tasks: data });

const clearOriginalFiles = (originalFiles) => ({
  type: CLEAR_ORIGINAL_FILES,
  originalFiles,
});

export const removeOriginalFile = (file) => (dispatch, getState) => {
  const selectedFile = file;
  const values = getState().taskDetailReducer.inputValues.originalFiles;
  const files = values.filter((file) => file.name !== selectedFile.name);

  const removedFiles = getState().taskDetailReducer.inputValues.removedFiles;
  removedFiles.push(selectedFile);

  dispatch(clearOriginalFiles(files));
  dispatch(setRemovedFiles(removedFiles));
};

const setRemovedFiles = (removedFiles) => ({
  type: SET_REMOVE_FILES,
  removedFiles,
});

export const clearTaskInputModal = (taskGroups) => (dispatch) => {
  dispatch({ type: CLEAR_MODAL_INPUT_VALUES });
};

export const unMountTaskDetailReducer = () => async (dispatch) => {
  // 1. Clear Tasks
  await dispatch({ type: CLEAR_TASKS });
  // 2. Clear Inputs Values
  await dispatch({ type: CLEAR_MODAL_INPUT_VALUES });
};
