import React, { useState } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import Picker from 'react-emojipicker';
import { colors } from './../TaskGroupForm';

const TaskGroupEditModal = ({
  onInputChange,
  updateTaskGroup,
  inputValues,
  savingGroup,
  savedGroup,
  onShow,
  onHide,
}) => {
  let emojiValue = '';
  const [isEmojiOpen, toggleIsEmoji] = useState(false);
  const handleEmojiToggle = () => {
    const isShow = isEmojiOpen ? false : true;
    toggleIsEmoji(isShow);
  };
  return (
    <Modal
      show={onShow}
      onHide={onHide}
      size='lg'
      className=''
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          Edit Strategy Group
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          <form className='task-group-form' onSubmit={(e) => updateTaskGroup()}>
            <label htmlFor='taskGroupColor'>Group Color</label>
            <select
              type='select'
              id='taskGroupColor'
              required
              name='taskGroupColor'
              onChange={(e) => onInputChange(e.target.name, e.target.value)}
              value={inputValues.taskGroupColor}
            >
              <option value='' style={{ fontWeight: 'bold' }}>
                Users will easy recognize this part of your strategy by its
                associated color, pick a color that goes with "Sales"{' '}
              </option>
              {colors &&
                colors.map((color) => (
                  <option key={color.id} id={color.id} value={color.value}>
                    {color.name}
                  </option>
                ))}
            </select>
            <div>
              <div className='row'>
                <div className='col'>
                  <label
                    style={{ paddingBottom: '6px' }}
                    htmlFor='taskGroupName'
                  >
                    Group Name
                  </label>
                  <input
                    type='text'
                    required
                    id='taskGroupName'
                    placeholder='What would you like this part of your strategy to be caled ?'
                    name='taskGroupName'
                    onChange={(e) =>
                      onInputChange(e.target.name, e.target.value)
                    }
                    value={inputValues.taskGroupName}
                    ref={(ref) => (emojiValue = ref)}
                  />
                  <i
                    style={{
                      position: 'absolute',
                      right: '23px',
                      top: '43px',
                      fontSize: '24px',
                      cursor: 'pointer',
                    }}
                    className='fa fa-smile-o'
                    aria-hidden='true'
                    onClick={() => handleEmojiToggle()}
                  />
                  {isEmojiOpen ? (
                    <Picker
                      onEmojiSelected={(emoji) => {
                        emojiValue.value = emojiValue.value + emoji.unicode;
                        onInputChange('taskGroupName', emojiValue.value);
                        handleEmojiToggle();
                      }}
                    />
                  ) : (
                    false
                  )}
                </div>
              </div>
            </div>
            <Form.Text className='text-muted'>
              example: sales, marketing, monthly finances, etc.
            </Form.Text>
            <label htmlFor='taskGroupName'>
              Short Description of your Strategy{' '}
            </label>
            <input
              type='text'
              required
              id='planName'
              name='planName'
              placeholder='We are doing this because...'
              onChange={(e) => onInputChange(e.target.name, e.target.value)}
              value={inputValues.planName}
            />
          </form>
        </>
      </Modal.Body>
      <Modal.Footer>
        <div className='row'>
          <div className='col-xl-3 col-lg-4 col-md-4 col-sm-4 col-4'>
            <Button
              className='savebutton'
              onClick={() => updateTaskGroup()}
              style={{ fontSize: '16px', padding: '10px' }}
              disabled={!!savingGroup}
            >
              {!!savingGroup && 'Updating...'}
              {!savingGroup && !!savedGroup && 'Updated!'}
              {!savingGroup && !savedGroup && 'Update'}
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default TaskGroupEditModal;
