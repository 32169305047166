import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import Picker from 'react-emojipicker';

export const colors = [
  { id: 1, name: 'Aqua', value: '#00ffff' },
  { id: 2, name: 'Blue', value: '#0000ff' },
  { id: 3, name: 'Cyan', value: '#00fffb' },
  { id: 4, name: 'Dark Green', value: '#004d00' },
  { id: 5, name: 'Fuchsia', value: '#ff00fd' },
  { id: 6, name: 'Green', value: '#008000' },
  { id: 7, name: 'Lime', value: '#00ff00' },
  { id: 8, name: 'Magenta', value: '#ff00ff' },
  { id: 9, name: 'Maroon', value: '#800000' },
  { id: 10, name: 'Olive', value: '#808000' },
  { id: 11, name: 'Orange', value: '#ffa500' },
  { id: 12, name: 'Purple', value: '#800080' },
  { id: 13, name: 'Red', value: '#ff0000' },
  { id: 14, name: 'Slate Blue', value: '#0000b3' },
  { id: 15, name: 'Yellow', value: '#ffff00' },
];
const TaskGroupForm = ({
  onInputChange,
  saveTaskGroup,
  inputValues,
  savingGroup,
  savedGroup,
}) => {
  let emojiValue = '';
  const [isEmojiOpen, toggleIsEmoji] = useState(false);
  const handleEmojiToggle = () => {
    const isShow = isEmojiOpen ? false : true;
    toggleIsEmoji(isShow);
  };
  return (
    <form className='task-group-form' onSubmit={(e) => saveTaskGroup(e)}>
      <label htmlFor='taskGroupColor'>Group Color</label>
      <select
        type='select'
        id='taskGroupColor'
        required
        name='taskGroupColor'
        onChange={({ target: { name, value } }) => onInputChange(name, value)}
        value={inputValues.taskGroupColor}
      >
        <option value='' style={{ fontWeight: 'bold' }}>
          Users will easy recognize this part of your strategy by its associated
          color, pick a color that goes with "Sales"{' '}
        </option>
        {colors &&
          colors.map((color) => (
            <option key={color.id} id={color.id} value={color.value}>
              {color.name}
            </option>
          ))}
      </select>
      <div>
        <div className='row'>
          <div className='col'>
            <label style={{ paddingBottom: '6px' }} htmlFor='taskGroupName'>
              Group Name
            </label>
            <input
              type='text'
              required
              id='taskGroupName'
              placeholder='What would you like this part of your strategy to be caled ?'
              name='taskGroupName'
              style={{ paddingRight: '28px' }}
              onChange={(e) => onInputChange(e.target.name, e.target.value)}
              value={inputValues.taskGroupName}
              ref={(ref) => (emojiValue = ref)}
            />
            <i
              style={{
                position: 'absolute',
                right: '23px',
                top: '43px',
                fontSize: '24px',
                cursor: 'pointer',
              }}
              className='fa fa-smile-o'
              aria-hidden='true'
              onClick={() => handleEmojiToggle()}
            />
            {isEmojiOpen ? (
              <Picker
                onEmojiSelected={(emoji) => {
                  emojiValue.value = emojiValue.value + emoji.unicode;
                  onInputChange('taskGroupName', emojiValue.value);
                  handleEmojiToggle();
                }}
              />
            ) : (
              false
            )}
          </div>
        </div>
      </div>
      <Form.Text className='text-muted'>
        example: sales, marketing, monthly finances, etc.
      </Form.Text>
      <label htmlFor='taskGroupName'>Short Description of your Strategy </label>
      <input
        type='text'
        required
        id='planName'
        name='planName'
        placeholder='We are doing this because...'
        onChange={(e) => onInputChange(e.target.name, e.target.value)}
        value={inputValues.planName}
      />
      <div className='row'>
        <div className='col-xl-3 col-lg-4 col-md-4 col-sm-4 col-4'>
          <button
            className='savebutton'
            style={{
              fontSize: '16px',
              padding: '10px',
            }}
            disabled={!!savingGroup}
          >
            {!!savingGroup && 'Saving...'}
            {!savingGroup && !!savedGroup && 'Group saved!'}
            {!savingGroup && !savedGroup && 'Save Group'}
          </button>
        </div>
      </div>{' '}
    </form>
  );
};

export default TaskGroupForm;
