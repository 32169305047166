import React from 'react';
import ReactDOM from 'react-dom';
import 'dotenv/config';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { appStore, persistor } from './redux/configureStore';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './App';
import * as serviceWorker from './serviceWorker';

import './index.css';

ReactDOM.render(
  <Provider store={appStore}>
    <PersistGate loading={null} persistor={persistor}>
      <Router>
        <App />
      </Router>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.registerServiceWorker();
// serviceWorker.register();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
