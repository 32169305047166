import _ from 'lodash';
import Swal from 'sweetalert2';

import { db } from '../../firebase.js';
import { setTaskGroups } from './taskDetailActions.js';

// Add New TaskGroup | Strategy
export const saveTaskGroup = (companyId) => async (dispatch, getState) => {
  dispatch(savingTaskGroup(true));
  const values = getState().taskGroupReducer.inputValues;
  const taskGroupRef = db.ref('taskGroups');
  let companyId = localStorage.getItem('companyID');
  await taskGroupRef.once('value').then((snapshot) => {
    const taskGroups = _.filter(Object.values(snapshot.val()), {
      companyId: companyId,
    });
    const isAvailableObj = _.find(
      taskGroups,
      (taskGroup) =>
        taskGroup.name.toLowerCase().trim() ===
        values.taskGroupName.toLowerCase().trim()
    );
    if (isAvailableObj) {
      dispatch(savingTaskGroup(false));
      Swal.fire(
        'Already Exist!',
        `${values.taskGroupName.trim()} already exist`,
        'warning'
      );
      return;
    } else {
      dispatch(savingTaskGroup(true));
      const publicCreatedTaskGroupRef = db.ref('taskGroups');
      let newCreatedTaskGroupRef = publicCreatedTaskGroupRef.push();
      newCreatedTaskGroupRef
        .set({
          color: values.taskGroupColor,
          name: values.taskGroupName,
          planName: values.planName,
          id: newCreatedTaskGroupRef.key,
          companyId: companyId,
        })
        .then(() => {
          setTimeout(() => dispatch(savingTaskGroup(false)), 2000);
          setTimeout(() => dispatch(savedTaskGroup(true)), 2500);
          setTimeout(() => dispatch(savedTaskGroup(false)), 3500);
          dispatch(clearTaskGroup());
        })
        .catch((err) => {
          dispatch(savingTaskGroup(false));
          dispatch(savedTaskGroup(false));
          dispatch(clearTaskGroup());
        });
    }
  });
};

// Update TaskGroup | Strategy
export const updateTaskGroup = (taskGroupId) => async (dispatch, getState) => {
  const values = getState().taskGroupReducer.inputValues;
  const { inputValues } = getState().taskDetailReducer;
  const { taskGroupOptions } = inputValues;

  dispatch(savingTaskGroup(true));

  if (values.taskGroupColor.trim() === '') {
    Swal.fire('Error', `Group Color Required`, 'warning');
    dispatch(savingTaskGroup(false));
    return { close: false };
  } else if (values.taskGroupName.trim() === '') {
    Swal.fire('Error', `Group Name Required`, 'warning');
    dispatch(savingTaskGroup(false));
    return { close: false };
  } else if (values.planName.trim() === '') {
    Swal.fire('Error', `Short Description of your Strategy`, 'warning');
    dispatch(savingTaskGroup(false));
    return { close: false };
  }

  await db
    .ref(`taskGroups/${taskGroupId}`)
    .update({
      color: values.taskGroupColor,
      name: values.taskGroupName,
      planName: values.planName,
    })
    .then((res) => {
      let taskGroups = [...taskGroupOptions];
      let taskGroup = _.find(
        taskGroups,
        (taskGroup) => taskGroup.id === taskGroupId
      );
      taskGroup.color = values.taskGroupColor;
      taskGroup.name = values.taskGroupName;
      taskGroup.planName = values.planName;
      dispatch(setTaskGroups(taskGroups));
      setTimeout(() => dispatch(savingTaskGroup(false)), 2000);
      setTimeout(() => dispatch(savedTaskGroup(true)), 2500);
      setTimeout(() => dispatch(savedTaskGroup(false)), 3500);
      dispatch(clearTaskGroup());
      return { close: true };
    })
    .catch((err) => {
      dispatch(savingTaskGroup(false));
      dispatch(savedTaskGroup(false));
      dispatch(clearTaskGroup());
      return { close: true };
    });
};

export const setTaskGroupInputValue = (name, value) => {
  return {
    type: 'SET_TASK_GROUP_INPUT_VALUE',
    name,
    value,
  };
};

const savingTaskGroup = (bool) => ({
  type: 'SAVING_TASK_GROUP',
  savingTaskGroup: bool,
});

const savedTaskGroup = (bool) => ({
  type: 'SAVED_TASK_GROUP',
  savedTaskGroup: bool,
});

const clearTaskGroup = () => ({
  type: 'CLEAR_TASK_GROUP',
});

export const resetTaskGroup = () => (dispatch) => {
  dispatch(clearTaskGroup());
};
