const initialState = {
  loader: false
};

export function loaderReducer(state = initialState, action) {
  switch (action.type) {
    case 'LOADER':
      return Object.assign({}, state, { loader: action.setLoader });
    default:
      return state;
  }
}
