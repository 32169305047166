import React from 'react';
import { Button, Modal } from 'react-bootstrap';

export const EmployeeEditModal = ({
  show,
  onHide,
  editEmpInfo,
  onInputChange,
  taskGroupOptions,
  updateEmpInfo,
  savingPlan,
}) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size='lg'
      className=''
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          Edit Member Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className='edit-form'>
          <div className='form-group'>
            <label> Member Name </label>
            <input
              className='form-control'
              type='text'
              placeholder='Member Name'
              value={editEmpInfo.empName}
              name='empName'
              onChange={(e) => onInputChange(e.target.name, e.target.value)}
            />
          </div>
          <div className='form-group'>
            <label> Phone Number </label>
            <input
              className='form-control'
              type='text'
              placeholder='Phone Number'
              value={editEmpInfo.empPhoneNumber}
              name='empPhoneNumber'
              onChange={(e) => onInputChange(e.target.name, e.target.value)}
            />
          </div>
          <div className='form-group'>
            <label> Strategy </label>
            <select
              className='form-control'
              type='select'
              name='empTaskGroupId'
              onChange={(e) => onInputChange(e.target.name, e.target.value)}
            >
              <option value='' disabled>
                Please Select Strategy
              </option>
              {taskGroupOptions &&
                taskGroupOptions.map((option) => (
                  <option
                    selected={editEmpInfo.empTaskGroupId === option.id}
                    key={option.id}
                    value={option.id}
                  >
                    {option.name}
                  </option>
                ))}
            </select>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <div className='row'>
          <div className='col-xl-3 col-lg-4 col-md-4 col-sm-4 col-4'>
            <Button
              disabled={!!savingPlan}
              className='savebutton'
              onClick={() => updateEmpInfo()}
            >
              {savingPlan && 'Updating...'}
              {!savingPlan && 'Update'}
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
