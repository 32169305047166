/* eslint-disable no-useless-escape */
import axios from 'axios';
import _ from 'lodash';
import * as firebase from 'firebase';
import Swal from 'sweetalert2';

import { isEmail } from './../../utils/sharedService';
import { db, fba } from '../../firebase.js';
import * as constanst from './../../constants';

// Add Member By Admin
export const savePlan = () => async (dispatch, getState) => {
  dispatch(savingPlan(true));
  const values = getState().memberReducer.inputValues;

  // All Fields Validation
  if (
    values.empEmailID === '' ||
    values.empName === '' ||
    values.empPhoneNumber === '' ||
    values.empUserName === ''
  ) {
    Swal.fire('Error', `Fill all details`, 'warning');
    dispatch(savingPlan(false));
    return;
  }

  // Get Company TaskGroups
  const taskGroupOptions = getState().taskDetailReducer.inputValues
    .taskGroupOptions;
  const taskGroup = _.find(taskGroupOptions, ['id', values.empTaskGroupId]);

  if (!taskGroup) {
    Swal.fire('Error', `Please Add Strategy First`, 'warning');
    dispatch(savingPlan(false));
    return;
  }

  if (values.empTaskGroupId === '') {
    Swal.fire('Error', `Please Add Strategy First`, 'warning');
    dispatch(savingPlan(false));
    return;
  }

  // Fetch All Users For Validation New User to be created!
  let usersArray = [];
  let usersLength; // All Users Count.
  await db
    .ref(`users`)
    .once('value')
    .then((snapshot) => {
      if (snapshot.exists()) {
        usersArray = Object.values(snapshot.val());
        usersLength = Object.values(snapshot.val()).length += 1;
      }
    });

  // Phone Number Validation
  if (values.empPhoneNumber && values.empPhoneNumber.length !== 10) {
    Swal.fire('Error', `Please Enter valid phone number`, 'warning');
    dispatch(savingPlan(false));
    return;
  }

  // TaskGroup Validation
  if (values.empTaskGroup === '') {
    Swal.fire('Error', `Please Select Strategy`, 'warning');
    dispatch(savingPlan(false));
    return;
  }

  // Email Id Validation
  if (usersArray.find((user) => user.email === values.empEmailID)) {
    Swal.fire(
      'Already Exist!',
      `Email ID is already exist please select different`,
      'warning'
    );
    dispatch(savingPlan(false));
    return;
  }

  // Member empId validation
  const companyId = localStorage.getItem('companyID');
  const companyName = localStorage.getItem('company');
  const employees = _.filter(usersArray, {
    companyId: companyId,
    type: 'Employee',
  });
  // For Old Data...
  const isEmpMatch = employees.filter(
    (emp) =>
      emp.empID &&
      emp.empID.trim().toLowerCase() === values.empID &&
      values.empID.trim().toLowerCase()
  );
  if (isEmpMatch && isEmpMatch.length > 0) {
    Swal.fire('Already Exist!', `Member id already exist`, 'warning');
    dispatch(savingPlan(false));
    return;
  }
  // if (_.some(employees, ['empID', values.empID.trim().toLowerCase()])) {
  //   Swal.fire('Already Exist!', `Member id already exist`, 'warning');
  //   dispatch(savingPlan(false));
  //   return;
  // }

  // For New Data...
  const isEmpMatch1 = employees.filter(
    (emp) =>
      emp.empID &&
      emp.empID.trim().toLowerCase() ===
        `${companyName}_${values.empID}`.trim().toLowerCase()
  );
  if (isEmpMatch1 && isEmpMatch1.length > 0) {
    Swal.fire('Already Exist!', `Member id already exist`, 'warning');
    dispatch(savingPlan(false));
    return;
  }
  // if (_.some(employees, ['empID', `${companyName}_${values.empID}`])) {
  //   Swal.fire('Already Exist!', `Member id already exist`, 'warning');
  //   dispatch(savingPlan(false));
  //   return;
  // }

  // username validation
  dispatch(savingPlan(true));

  // Generate Random Password for employee
  var randomPass = Math.random()
    .toString(36)
    .slice(2);

  const format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
  if (format.test(values.empUserName)) {
    Swal.fire(
      'Error',
      'username must not contains any special characters',
      'warning'
    );
    dispatch(savingPlan(false));
    return;
  }

  if (!isEmail(values.empEmailID)) {
    Swal.fire('Error', 'Please enter valid email id', 'warning');
    dispatch(savingPlan(false));
    return;
  }

  // Check username in db.
  const isEmpWithUsername = await db
    .ref(`users/${values.empUserName}`)
    .limitToFirst(1)
    .once('value');

  if (isEmpWithUsername.exists()) {
    Swal.fire(
      'Already Exist!',
      `User name already used by another member`,
      'warning'
    );
    dispatch(savingPlan(false));
    return;
  }

  // Check PhoneNumber in db if it already exist.
  const isPhoneNumberAvailable = _.filter(
    usersArray,
    (user) => user.empPhoneNumber === values.empPhoneNumber
  );

  if (isPhoneNumberAvailable && isPhoneNumberAvailable.length > 0) {
    Swal.fire('Already Exist!', 'Phone Number already in use', 'warning');
    dispatch(savingPlan(false));
    return;
  }

  if (values.empName && values.empName === '') {
    Swal.fire('Error', 'Please enter member name', 'warning');
    dispatch(savingPlan(false));
    return;
  }

  // Create New Employee and assign company.
  await fba
    .createUserWithEmailAndPassword(values.empEmailID, randomPass)
    .then(({ user }) => {
      const authToken = user.ma;
      const companyID = localStorage.getItem('companyID');
      const companyName = localStorage.getItem('company');
      let userObjToSet = {
        uid: user.uid,
        email: user.email,
        type: 'Employee',
        isActive: true,
        empPhoneNumber: values.empPhoneNumber,
        empPassword: randomPass,
        empTaskGroupId: taskGroup.id,
        empTaskGroupName: taskGroup.name,
        empName: values.empName,
        companyId: companyID,
        empID: `${companyName}_${values.empID}`, // companyName_empId
        username: values.empUserName + '' + usersLength,
      };

      // Mail Object
      let mail = {
        email: user.email,
        empPassword: randomPass,
        username: values.empUserName + '' + usersLength,
        companyName,
      };

      // Mail Headers
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      };

      // Add User in users collection with username & usersLength
      db.ref(`users/${values.empUserName + '' + usersLength}`)
        .set(userObjToSet)
        .then(async () => {
          // Send Mail Callback
          try {
            await axios.post(constanst.MAILSERVER, mail, config).then(() => {});
          } catch (error) {
            // alert('Something Went Wrong Mail Not Sent!');
          }
          setTimeout(() => dispatch(savingPlan(false)), 300);
          setTimeout(() => dispatch(clearTExt()), 300);
          Swal.fire('Success!', 'Member Added', 'success');
        })
        .catch((error) => {
          setTimeout(() => dispatch(savedPlan(false)), 300);
          setTimeout(() => dispatch(savingPlan(false)), 300);
          Swal.fire('Error', error.message, 'error');
        });
    })
    .catch((error) => {
      setTimeout(() => dispatch(savedPlan(false)), 300);
      setTimeout(() => dispatch(savingPlan(false)), 300);
      Swal.fire('Error', error.message, 'error');
    });
};

// Fetch All Users and Return Employees By CompanyID
export const fetchEmp = (companyID) => (dispatch, getState) => {
  db.ref('users')
    .once('value')
    .then((snapshot) => {
      if (snapshot.exists()) {
        const usersArray = [];
        snapshot.forEach((childSnap) => {
          usersArray.push({ ...childSnap.val(), id: childSnap.key });
        });
        const employees = _.filter(usersArray, {
          companyId: companyID,
          type: 'Employee',
        });
        dispatch(setMember(employees));
        dispatch(fetchMember(usersArray));
      }
    });
};

const removeUserTasks = async (tasks, user) => {
  if (tasks && user) {
    await tasks.forEach(async (task) => {
      if (task.assignBy === 'employee') {
        await db.ref(`tasks/${task.id}`).update({
          empID: '',
          username: '',
        });
      } else if (task.assignBy === 'taskGroup') {
        if (task.taskGroupUsers) {
          let filteredTaskGroupUsers = task.taskGroupUsers.filter(
            (tgUser) => tgUser.id !== user.username
          );
          if (filteredTaskGroupUsers && filteredTaskGroupUsers.length > 0) {
            // Update Task
            await db.ref(`tasks/${task.id}`).update({
              empID: '',
              username: '',
              taskGroupUsers: filteredTaskGroupUsers,
            });
            // Update Task in User Collection
            await filteredTaskGroupUsers.forEach(async (user) => {
              try {
                if (user && user.username) {
                  await db
                    .ref(`${user.username}/tasks/${task.id}`)
                    .update({ taskGroupUsers: filteredTaskGroupUsers });
                }
              } catch (error) {}
            });
          } else {
            await db.ref(`tasks/${task.id}`).update({
              assignBy: 'unassigned',
              empID: '',
              username: '',
              isUnassigned: true,
              isComplete: false,
              isDeleted: false,
            });
          }
        }
      }
    });
  }
  return true;
};

// Delete Emp
export const deleteMember = (data) => async (dispatch) => {
  try {
    let userRef = db.ref(`users`);
    const userSnap = await userRef.child(data.username).once('value');
    const user = { ...userSnap.val() };
    const tasks = user.tasks ? Object.values(user.tasks) : undefined;
    if (tasks) {
      await removeUserTasks(tasks, user);
    }

    if (user) {
      await userRef.child(data.username).remove();
    }

    // Remove User From Authentication
    try {
      if (!user.uid) {
        return;
      }
      let authToken;
      // Get Auth Token From Current User Login!
      await firebase
        .auth()
        .currentUser.getIdToken(true)
        .then((idToken) => {
          authToken = idToken;
        });

      if (!authToken || authToken === '' || authToken === null) {
        return;
      }
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      };
      await axios
        .post(constanst.REMOVEUSER, { uid: user.uid }, config)
        .then(() => {});
    } catch (error) {
      console.log('Something Went Wrong!');
    }
    // Get New Response
    let companyId = localStorage.getItem('companyID');
    dispatch(fetchEmp(companyId));
    return data;
  } catch (error) {
    Swal.fire('Error', error.message, 'error');
  }
};

// Update Emp
export const updateMember = () => async (dispatch, getState) => {
  dispatch(savingPlan(true));
  const values = getState().memberReducer.inputValues;
  const employees = getState().memberReducer.inputValues.employees;
  const { taskGroupOptions } = getState().taskDetailReducer.inputValues;
  const taskGroup = _.find(taskGroupOptions, ['id', values.empTaskGroupId]);
  const memberObj = {
    empName: values.empName,
    empPhoneNumber: values.empPhoneNumber,
    empTaskGroupId: taskGroup.id,
    empTaskGroupName: taskGroup.name,
  };
  await db
    .ref(`users/${values.empUserName}`)
    .update(memberObj)
    .then(async () => {
      const updatedUser = await db
        .ref(`users/${values.empUserName}`)
        .once('value');
      let usersArray = [...employees];
      const userIndex = usersArray.findIndex(
        (emp) => emp.username === values.empUserName
      );
      usersArray[userIndex] = updatedUser.val();
      await dispatch(setMember(usersArray));
      setTimeout(() => dispatch(savingPlan(false)), 2000);
      setTimeout(() => dispatch(savedPlan(true)), 2500);
      setTimeout(() => dispatch(savedPlan(true)), 3000);
      await dispatch({ type: 'MEMBER_CLEAR_INPUT_VALUES' });
      return updatedUser;
    })
    .catch((error) => {
      dispatch({ type: 'MEMBER_CLEAR_INPUT_VALUES' });
      setTimeout(() => dispatch(savingPlan(false)), 1000);
      setTimeout(() => dispatch(savedPlan(true)), 1500);
      setTimeout(() => dispatch(savedPlan(true)), 2000);
      Swal.fire('Error', error.message, 'error');
    });
};

// Actions
export const fetchMember = (users) => ({
  type: 'FETCH_MEMBERS',
  users: users,
});

export const setMemberInputValue = (name, value) => {
  return {
    type: 'SET_MEMBER_INPUT_VALUE',
    name,
    value,
  };
};

export const setMember = (employee) => ({
  type: 'SET_MEMBER',
  employee: employee,
});

const savingPlan = (bool) => ({
  type: 'SAVING_MEMBER',
  savingPlan: bool,
});

const savedPlan = (bool) => ({
  type: 'SAVED_MEMBER',
  savedPlan: bool,
});

const clearTExt = () => ({
  type: 'CLEAR_TEXT',
});

export const clearMemberInputValues = () => (dispatch) => {
  dispatch({
    type: 'MEMBER_CLEAR_INPUT_VALUES',
  });
};
