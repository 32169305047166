import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import Swal from 'sweetalert2';

import { db, fba } from '../../firebase.js';
import '../../css/common.css';
import {
  getFromLocalStorage,
  saveToLocalStorage,
} from './../../utils/sharedService';

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      error: '',
      validated: false,
      remember_me: false,
      isLoading: false,
    };
  }

  handleSignIn = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      const { username, password } = this.state;
      this.setState({ isLoading: true });
      try {
        // eslint-disable-next-line no-useless-escape
        var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
        if (format.test(username)) {
          Swal.fire(
            'Error',
            `username must not contains any special characters`,
            'warning'
          );
          this.setState({ isLoading: false });
          return;
        }
        db.ref(`users/${username}`).once('value', (snapshot) => {
          if (snapshot.exists()) {
            const email = snapshot.val() && snapshot.val().email;
            fba
              .signInWithEmailAndPassword(email, password)
              .then(({ user }) => {
                db.ref(`users/${username}`)
                  .once('value')
                  .then((snapshot) => {
                    let dataObj = snapshot.val();
                    localStorage.setItem('isLogin', true);
                    localStorage.setItem('userType', dataObj.type);
                    localStorage.setItem('companyID', dataObj.key);
                    localStorage.setItem('company', dataObj.company);
                    localStorage.setItem('username', username);
                    if (dataObj.type === 'SuperAdmin') {
                      this.props.history.push({
                        // pathname: '/Dashboard'
                        pathname: '/admin',
                      });
                    } else if (dataObj.type === 'Company') {
                      this.props.history.push({
                        pathname: '/admin/storyboard',
                      });
                    } else {
                      this.setState({ isLoading: false });
                      Swal.fire(
                        'Error',
                        `Sorry, You are not authenticate for web`,
                        'error'
                      );
                      return;
                    }
                  });
              })
              .catch((error) => {
                this.setState({ isLoading: false });
                Swal.fire('Error', error.message, 'error');
              });
          } else {
            this.setState({ isLoading: false });
            Swal.fire('Error', 'No user with that name', 'error');
          }
        });
      } catch (e) {
        this.setState({ isLoading: false });
        Swal.fire('Error', e.message, 'error');
      }
    } else {
      this.setState({ validated: true, isLoading: false });
    }
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  RememberMe = (event) => {
    const isRememberMe = getFromLocalStorage('rememberMe');
    saveToLocalStorage('rememberMe', isRememberMe ? false : true);
    this.setState({
      [event.target.name]: event.target.checked,
    });
  };

  render() {
    const { username, password, validated, isLoading } = this.state;
    let isRememberMe = getFromLocalStorage('rememberMe') ? true : false;
    return (
      <div className='loginpage'>
        <div className='left-container'></div>
        <div className='right-container'>
          <div className='formContainer'>
            <Form
              noValidate
              className='form-style'
              validated={validated}
              onSubmit={this.handleSignIn}
            >
              <h5 className='letterName'>
                PRODUCTI<span className='brandLastLetter'>O</span>
              </h5>
              <label className='appName'>
                Welcome back! Please login to your account.
              </label>
              <br />
              <Form.Group>
                <Form.Control
                  className='inputTag'
                  type='text'
                  placeholder='UserName'
                  name='username'
                  value={username}
                  onChange={this.handleChange}
                  required
                />
                <Form.Control.Feedback type='invalid'>
                  User name is required
                </Form.Control.Feedback>
              </Form.Group>
              <br />
              <Form.Group>
                <Form.Control
                  className='inputTag'
                  type='password'
                  placeholder='Password'
                  name='password'
                  value={password}
                  onChange={this.handleChange}
                  required
                />
                <Form.Control.Feedback type='invalid'>
                  password is required
                </Form.Control.Feedback>
              </Form.Group>
              <div className='loginhelp'>
                <div className='col-md-6 checkboxdiv'>
                  <Form.Group className='remeber_checkbox'>
                    {' '}
                    <Form.Check
                      type='checkbox'
                      label='Remember me'
                      className='login-checkbox'
                      checked={isRememberMe}
                      name='remember_me'
                      onChange={this.RememberMe}
                    />{' '}
                  </Form.Group>
                </div>
                <div className='col-md-6 forgotpass'>
                  <Link to='/ForgotPass'>
                    <label id='forgot-label'> Forgot Password</label>
                  </Link>
                </div>
              </div>
              <div className='rapper text-center'>
                <button
                  className='button-left'
                  type='submit'
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <i
                      className='fa fa-spinner fa-spin'
                      state={{ fontSize: '24px' }}
                    />
                  ) : (
                    'Login'
                  )}
                </button>{' '}
                &nbsp;&nbsp;&nbsp;
                <Link to='/Signup'>
                  <button className='button-right'> Sign up</button>
                </Link>
              </div>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}
