import React, { Component } from 'react';
import * as actions from '../redux/actions/taskGroupActions.js';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import TaskGroupForm from './TaskGroupForm';

class TaskGroupsContainer extends Component {
  onSaveTaskGroup = (e) => {
    const { saveTaskGroup } = this.props.actions;
    let companyId = localStorage.getItem('companyID');
    e.preventDefault();
    return saveTaskGroup(companyId);
  };
  render() {
    const { setTaskGroupInputValue } = this.props.actions;
    const {
      inputValues,
      savingTaskGroup,
      savedTaskGroup,
    } = this.props.taskGroupReducer;
    const { onSaveTaskGroup } = this;
    return (
      <div>
        <div className='plan-content'>
          <h2>Add a Strategy Group</h2>
        </div>
        <TaskGroupForm
          {...this.props}
          inputValues={inputValues}
          saveTaskGroup={onSaveTaskGroup}
          onInputChange={setTaskGroupInputValue}
          savingGroup={savingTaskGroup}
          savedGroup={savedTaskGroup}
        />
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { taskGroupReducer } = state;
  return {
    taskGroupReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(actions, dispatch) };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TaskGroupsContainer)
);
