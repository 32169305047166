import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
    };
  }

  render() {
    const {
      onInputChange,
      signIn,
      inputValues,
      signingIn,
      redirectToReferrer,
      location
    } = this.props;
    let { from } = location.state || { from: { pathname: "/advisor" } };
    if (!!redirectToReferrer) return <Redirect to={from} />;
    return (
      <div className="form-wrapper">
        <form
          className="sign-up-form"
          onSubmit={(e) => {
            e.preventDefault();
            signIn(error => this.setState({ error }));
          }}
        >
          {this.state.error && <div style={{ marginBottom: 20, color: 'red' }}>{this.state.error}</div>}
          <label htmlFor="username">Username</label>
          <input
            type="text"
            id="userName"
            name="userName"
            onChange={e => onInputChange(e.target.name, e.target.value)}
            value={inputValues.userName}
          />
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            name="password"
            onChange={e => onInputChange(e.target.name, e.target.value)}
            value={inputValues.password}
          />
          <button disabled={!!signingIn}>Log In</button>
          <div style={{ width: '100%', textAlign: 'center', marginTop: 40 }}>
            <Link to="/register">Create an Account</Link>
          </div>
        </form>
      </div>
    );
  }
}

export default Login;
