export function taskGroupReducer(
  state = {
    inputValues: {
      taskGroupColor: "",
      taskGroupName: "",
      planName: "",
    },
    savingTaskGroup: false,
    savedTaskGroup: false,
  },
  action
) {
  switch (action.type) {
    case "SET_TASK_GROUP_INPUT_VALUE":
      return {
        ...state,
        inputValues: {
          ...state.inputValues,
          [action.name]: action.value
        }
      };
    case "SAVING_TASK_GROUP":
      return {
        ...state,
        savingTaskGroup: action.savingTaskGroup
      };
    case "SAVED_TASK_GROUP":
      return {
        ...state,
        savedTaskGroup: action.savedTaskGroup
      };
    case "CLEAR_TASK_GROUP":
      return {
        ...state,
        inputValues: {
          taskGroupColor: "",
          taskGroupName: "",
          planName: "",
        },
        savingTaskGroup: false,
        savedTaskGroup: false,
      }
    default:
      return state;
  }
}
