import React, {Component, Fragment} from "react";
import Login from "./Login";
import SignUpForm from "./SignUpForm";
import AdvisorContainer from "./AdvisorContainer";
import * as actions from "../../redux/actions/appActions.js";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Route, withRouter} from "react-router-dom";

// const Protected = ({ component: Component, ...rest }) => {
//   return (
//     <Route
//       {...rest}
//       render={props => {
//         return rest.isAuthenticated ? (
//           <Component {...props} />
//         ) : (
//           <Redirect
//             to={{
//               pathname: "/login",
//               state: { from: props.location }
//             }}
//           />
//         );
//       }}
//     />
//   );
// };

class AdvisorView extends Component {
    onSignUp = cb => {
        const {createUserWithEmailAndPassword} = this.props.actions;
        return createUserWithEmailAndPassword(cb);
    };
    onSignIn = cb => {
        const {signInWithUsernameAndPassword} = this.props.actions;
        return signInWithUsernameAndPassword(cb);
    };

    render() {
        const {setAdvisorInputValue} = this.props.actions;
        const {inputValues, signingUp, user} = this.props.appReducer;
        const {onSignUp, onSignIn} = this;
        const {location} = this.props;
        return (
            <Fragment>
                <Route
                    path="/login"
                    render={props => (
                        <Login
                            {...this.props}
                            inputValues={inputValues}
                            signIn={onSignIn}
                            onInputChange={setAdvisorInputValue}
                            redirectToReferrer={user.uid !== undefined}
                            location={location}
                        />
                    )}
                />
                <Route
                    path="/register"
                    render={props => (
                        <SignUpForm
                            {...this.props}
                            onInputChange={setAdvisorInputValue}
                            inputValues={inputValues}
                            signUp={onSignUp}
                            signingUp={signingUp}
                            redirectToReferrer={user.uid !== undefined}
                            location={location}
                        />
                    )}
                />
                <Route
                    path="/advisor"
                    render={props => <AdvisorContainer {...props} />}
                />
            </Fragment>
        );
    }
}

function mapStateToProps(state, ownProps) {
    const {appReducer} = state;
    return {
        state,
        appReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(actions, dispatch)};
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(AdvisorView)
);
