import React, { Component } from 'react';
import { Form, Modal, Button } from 'react-bootstrap';
import _ from 'lodash';

class StoryboardGoalEditModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyId: '',
      id: '',
      name: '',
      taskGroup: '',
      options: [],
    };
  }

  addOption = (e) => {
    e.preventDefault();
    const { options } = this.state;
    const option = {
      name: '',
      values: [],
    };
    options.push(option);
    this.setState({ options });
  };

  updateOptionName = (optionIndex, value) => {
    const { options } = this.state;
    if (options) {
      options[optionIndex].name = value;
    } else {
      options.name = value;
    }
    this.setState({ options });
  };

  addOptionValue = (e, index) => {
    e.preventDefault();
    const { options } = this.state;
    if (options.values) {
      options[index].values.push('');
    } else {
      options.values.push('');
    }
    this.setState({ options });
  };

  updateOptionValueProps = (options) => {
    let optionsArray = [];
    if (options) {
      _.forEach(options, (option) => {
        if (option.values) {
          option.values = Object.values(option.values);
        }
        optionsArray.push(option);
      });
    }
    this.setState({ options: optionsArray });
  };

  updateOptionValue = (optionIndex, valueIndex, value) => {
    const { options } = this.state;
    options[optionIndex].values[valueIndex] = value;
    this.setState({ options });
  };

  componentWillReceiveProps(prevProps) {
    if (prevProps.selectedGoal) {
      const {
        companyId,
        id,
        name,
        taskGroup,
        options,
      } = prevProps.selectedGoal;
      this.setState({
        companyId,
        id,
        name,
        taskGroup,
        options: [],
      });
      if (options) {
        this.updateOptionValueProps(options);
      }
    }
  }

  render() {
    const { name, options } = this.state;
    const { savingGoal, savedGoal } = this.props;
    return (
      <Modal
        show={this.props.onShow}
        onHide={this.props.onHide}
        size='lg'
        className=''
        aria-labelledby='contained-modal-title-vcenter'
        centered
        // backdrop={!!savingGoal ? 'static' : ''}
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            Edit Goal
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <div>
              <form className='goal-form'>
                <Form.Group controlId='taskGroupG'>
                  <Form.Label>
                    {' '}
                    What Strategy are we creating a goal for?{' '}
                  </Form.Label>
                  <select
                    type='select'
                    id='taskGroup'
                    name='taskGroup'
                    style={{ color: '#383737' }}
                    required
                    onChange={(e) =>
                      this.setState({ taskGroup: e.target.value })
                    }
                    value={this.state.taskGroup}
                  >
                    <option value='' style={{ fontWeight: 'bold' }} disabled>
                      Please Select
                    </option>
                    {this.props.taskGroupOptions &&
                      this.props.taskGroupOptions.map((taskGroup) => (
                        <option key={taskGroup.id} value={taskGroup.id}>
                          {taskGroup.name}
                        </option>
                      ))}
                  </select>
                </Form.Group>
                <label htmlFor='goalName'>What is your goal name? </label>
                <input
                  type='text'
                  id='goalName'
                  name='goalName'
                  required
                  placeholder='Drive 100K in new Sales…'
                  onChange={(e) => this.setState({ name: e.target.value })}
                  value={name}
                />
                {options && options.length > 0 && (
                  <h3 style={{ color: '#383737', marginTop: '20px' }}>
                    This goal will have these type of possible options.
                  </h3>
                )}
                {options &&
                  options.map((option, optionIndex) => (
                    <div
                      key={`optionName-${optionIndex}`}
                      style={{
                        padding: 20,
                        backgroundColor: 'rgba(255,255,255,0.1)',
                        marginBottom: 0,
                      }}
                    >
                      <label htmlFor={`optionName-${optionIndex}`}>
                        What is the name of this option{' '}
                      </label>
                      <input
                        type='text'
                        id={`optionName-${optionIndex}`}
                        name={`optionName-${optionIndex}`}
                        required
                        placeholder='example: monthly reporting, biweekly monthly reporting, etc.'
                        onChange={(e) =>
                          this.updateOptionName(optionIndex, e.target.value)
                        }
                        value={option.name}
                      />
                      {option.values && option.values.length > 0 && (
                        <h4 style={{ color: '#383737', paddingTop: '15px' }}>
                          example: true, false, number, text, etc.
                        </h4>
                      )}
                      {option.values &&
                        option.values.map((value, valueIndex) => (
                          <div
                            key={`value-${optionIndex}-${valueIndex}`}
                            style={{
                              padding: '0px 20px',
                              backgroundColor: 'rgba(255,255,255,0.1)',
                              marginBottom: 15,
                            }}
                          >
                            <label
                              htmlFor={`value-${optionIndex}-${valueIndex}`}
                            >
                              Value
                            </label>
                            <input
                              type='text'
                              id={`value-${optionIndex}-${valueIndex}`}
                              name={`value-${optionIndex}-${valueIndex}`}
                              required
                              onChange={(e) =>
                                this.updateOptionValue(
                                  optionIndex,
                                  valueIndex,
                                  e.target.value
                                )
                              }
                              value={value}
                            />
                          </div>
                        ))}
                      <div className='row'>
                        <div className='col-xl-3 col-lg-4 col-md-4 col-sm-4 col-4'>
                          <button
                            className='savebutton'
                            style={{ fontSize: 16, padding: 10 }}
                            onClick={(e) => this.addOptionValue(e, optionIndex)}
                          >
                            Add Value
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                <div className='row'>
                  <div className='col-xl-3 col-lg-4 col-md-4 col-sm-4 col-4'>
                    <button
                      className='savebutton'
                      style={{ fontSize: 16, padding: 10 }}
                      onClick={this.addOption}
                    >
                      Add Option
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <div className='row'>
            <div className='col-xl-3 col-lg-4 col-md-4 col-sm-4 col-4'>
              <Button
                onClick={() => this.props.onSubmit(this.state)}
                className='savebutton'
                style={{ fontSize: '16px', padding: '10px' }}
                disabled={!!savingGoal}
              >
                {!savingGoal && !savedGoal && 'Update'}
                {!savingGoal && savedGoal && 'Updated'}
                {savingGoal && !savedGoal && 'Updating'}
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default StoryboardGoalEditModal;
