export function memberReducer(
  state = {
    inputValues: {
      empEmailID: '',
      empName: '',
      empPhoneNumber: '',
      empPassword: '',
      empTaskGroupId: '',
      employees: [],
      empID: '',
      empUserName: '',
    },
    savingPlan: false,
    savedPlan: false,
    users: [],
  },

  action
) {
  switch (action.type) {
    case 'SET_MEMBER_INPUT_VALUE':
      return {
        ...state,
        inputValues: {
          ...state.inputValues,
          [action.name]: action.value,
        },
      };
    case 'SET_PLANS':
      return {
        ...state,
        plans: action.plans,
      };
    case 'SET_MEMBER':
      return {
        ...state,
        inputValues: {
          employees: action.employee,
        },
      };
    case 'SAVING_MEMBER':
      return {
        ...state,
        savingPlan: action.savingPlan,
      };
    case 'SAVED_MEMBER':
      return {
        ...state,
        savedPlan: action.savedPlan,
      };
    case 'CLEAR_TEXT':
      return {
        ...state,
        inputValues: {
          empEmailID: '',
          empName: '',
          empPhoneNumber: '',
          empTaskGroupId: '',
          employees: [],
          empID: '',
          empUserName: '',
        },
      };
    case 'MEMBER_CLEAR_INPUT_VALUES':
      return {
        ...state,
        inputValues: {
          empEmailID: '',
          empName: '',
          empPhoneNumber: '',
          empTaskGroupId: '',
          employees: [...state.inputValues.employees],
          empID: '',
          empUserName: '',
        },
      };
    case 'FETCH_MEMBERS':
      return {
        ...state,
        users: action.users,
      };
    default:
      return state;
  }
}
