import React from 'react';

const MemberForm = ({
  onInputChange,
  savePlan,
  inputValues,
  savingPlan,
  savedPlan,
  taskGroupOptions,
}) => {
  return (
    <form className='plan-form add_member' onSubmit={(e) => savePlan(e)}>
      <label htmlFor='empName'>Member Name</label>
      <input
        required
        type='text'
        id='empName'
        name='empName'
        onChange={(e) => onInputChange(e.target.name, e.target.value)}
        value={inputValues.empName}
      />
      <label htmlFor='empEmailID'>Email ID</label>
      <input
        required
        type='text'
        id='empEmailID'
        name='empEmailID'
        onChange={(e) => onInputChange(e.target.name, e.target.value)}
        value={inputValues.empEmailID}
      />
      <label htmlFor='empID'>Member ID</label>
      <input
        required
        type='text'
        id='empID'
        name='empID'
        onChange={(e) => onInputChange(e.target.name, e.target.value)}
        value={inputValues.empID}
      />
      <label htmlFor='empUserName'>Username (this will be unique)</label>
      <input
        required
        type='text'
        id='empUserName'
        name='empUserName'
        onChange={(e) => onInputChange(e.target.name, e.target.value)}
        value={inputValues.empUserName}
      />
      <label htmlFor='empPhoneNumber'>Phone Number</label>
      <input
        required
        type='number'
        pattern='^[0-9]*$'
        id='empPhoneNumber'
        name='empPhoneNumber'
        onChange={(e) => onInputChange(e.target.name, e.target.value)}
        value={inputValues.empPhoneNumber}
      />
      <label htmlFor='empTaskGroupId'>Strategy </label>
      <select
        type='select'
        id='empTaskGroupId'
        name='empTaskGroupId'
        onChange={(e) => onInputChange(e.target.name, e.target.value)}
        value={inputValues.empTaskGroupId}
      >
        <option value='' style={{ fontWeight: 'bold' }}>
          Please Select the Strategy
        </option>
        {taskGroupOptions &&
          taskGroupOptions.map((option) => (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          ))}
      </select>
      <div className='row'>
        <div className='col-xl-3 col-lg-4 col-md-4 col-sm-4 col-12'>
          <button
            className='savebutton'
            disabled={!!savingPlan}
            style={{
              fontSize: '16px',
              padding: '10px',
              outline: 'none'
            }}
          >
            {savingPlan && 'Saving...'}
            {!savingPlan && !!savedPlan && 'Member saved!'}
            {!savingPlan && 'Save Member'}
          </button>
        </div>
      </div>{' '}
    </form>
  );
};

export default MemberForm;
