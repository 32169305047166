import { db, messaging } from './../firebase';

export const sendNotificationURL = 'https://fcm.googleapis.com/fcm/send';

export const onTokenRefresh = async () => {
  await messaging
    .getToken()
    .then((refreshedToken) => {
      if (refreshedToken) {
        sendTokenToServer(refreshedToken);
      }
    })
    .catch((err) => {
      deleteToken();
    });
};

export const requestPermission = () => {
  Notification.requestPermission().then(async (permission) => {
    if (permission === 'granted') {
      await onTokenRefresh();
    }
  });
};

export const deleteToken = async () => {
  await messaging
    .getToken()
    .then(async (currentToken) => {
      await messaging
        .deleteToken(currentToken)
        .then(() => {
          onTokenRefresh();
        })
        .catch((err) => {
          // console.log('Unable to delete token.',err.message);
        });
    })
    .catch((err) => {
      // console.log('Error retrieving Instance ID token.',err.message);
    });
};

async function sendTokenToServer(currentToken) {
  if (currentToken !== undefined) {
    let fcmToken = currentToken;
    if (currentToken && currentToken !== undefined) {
      const userName = localStorage.getItem('username');
      const snapshot = await db.ref(`users/${userName}`).once('value');
      if (snapshot.exists()) {
        let userObj = snapshot.val();
        userObj.id = userName;
        if (!userObj.fcmToken) {
          await db.ref(`users/${userObj.id}`).update({ fcmToken });
        }
        if (userObj.fcmToken && userObj.fcmToken !== currentToken) {
          await db.ref(`users/${userObj.id}`).update({ fcmToken });
        }
      }
    } else {
      currentToken = null;
      fcmToken = null;
    }
  }
}

export const onMessages = () => {
  messaging.onMessage((payload) => {});
};
