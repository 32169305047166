const initialState = {
  inputValues: {
    userName: "",
    email: "",
    password: ""
  },
  signingUp: false,
  userExists: false,
  isAuthenticated: false,
  signingIn: false,
  noAuth: false,
  user: {},
  advisees: [],
  inviteId: null,
  selectedAdvisee: {
    name: null,
    goals: {},
    plans: {},
    taskGroups: {},
    tasks: []
  },
  global: {
    goals: {},
    plans: {},
    tasks: {},
  }
};

export function appReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case "SET_ADVSIOR_INPUT_VALUE":
      return {
        ...state,
        inputValues: {
          ...state.inputValues,
          [action.name]: action.value
        }
      };
    case "SIGNING_UP":
      return {
        ...state,
        signingUp: action.bool
      };
    case "USER_EXISTS":
      return {
        ...state,
        userExists: action.bool
      };
    case "AUTHENTICATE":
      return {
        ...state,
        isAuthenticated: action.bool
      };
    case "NO_AUTH":
      return {
        ...state,
        noAuth: true
      };
    case "SET_USER":
      return {
        ...state,
        user: { ...action.user }
      };
    case "SET_INVITE_TOKEN":
      return {
        ...state,
        inviteId: action.inviteId
      };
    case "SET_ADVISEES":
      return {
        ...state,
        advisees: action.advisees
      };
    case "SET_SELECTED_ADVISEE":
      return {
        ...state,
        selectedAdvisee: { name: action.advisee }
      };
    case "SET_SELECTED_ADVISEE_GOALS":
      return {
        ...state,
        selectedAdvisee: {
          ...state.selectedAdvisee,
          goals: { ...action.goals }
        }
      };
    case "SET_SELECTED_ADVISEE_PLANS":
      return {
        ...state,
        selectedAdvisee: {
          ...state.selectedAdvisee,
          plans: { ...action.plans }
        }
      };
    case "SET_SELECTED_ADVISEE_TASK_GROUPS":
      return {
        ...state,
        selectedAdvisee: {
          ...state.selectedAdvisee,
          taskGroups: { ...action.taskGroups }
        }
      };
    case "SET_SELECTED_ADVISEE_TASKS":
      return {
        ...state,
        selectedAdvisee: {
          ...state.selectedAdvisee,
          tasks: [...action.tasks]
        }
      };
    case "SET_GLOBAL_GOALS":
      return {
        ...state,
        global: {
          ...state.global,
          goals: {...action.goals}
        }
      };
    case "SET_GLOBAL_PLANS":
      return {
        ...state,
        global: {
          ...state.global,
          plans: {...action.plans}
        }
      };
    case "SET_GLOBAL_TASKS":
      return {
        ...state,
        global: {
          ...state.global,
          tasks: {...action.tasks}
        }
      };
    case "LOGOUT":
      return initialState;
    default:
      return state;
  }
}
