import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form } from 'react-bootstrap';
import Swal from 'sweetalert2';

import * as actions from '../redux/actions/goalActions.js';
import * as taskDetailActions from '../redux/actions/taskDetailActions.js';

class GoalForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      taskGroup: '',
      options: [],
      companyId: localStorage.getItem('companyID'),
    };
  }

  componentDidMount() {
    let companyId = localStorage.getItem('companyID');
    this.props.fetchTaskGroups(companyId);
  }

  addOption = (e) => {
    e.preventDefault();
    const { options } = this.state;
    const option = {
      name: '',
      values: [],
    };
    options.push(option);
    this.setState({ options });
  };

  updateOptionName = (optionIndex, value) => {
    const { options } = this.state;
    options[optionIndex].name = value;
    this.setState({ options });
  };

  addOptionValue = (e, index) => {
    e.preventDefault();
    const { options } = this.state;
    options[index].values.push('');
    this.setState({ options });
  };

  updateOptionValue = (optionIndex, valueIndex, value) => {
    const { options } = this.state;
    options[optionIndex].values[valueIndex] = value;
    this.setState({ options });
  };

  async saveGoal(e) {
    e.preventDefault();
    if (this.state.options.length === 0) {
      Swal.fire('Error', `Option is required`, 'warning');
      return;
    }
    const { saveGoal } = this.props.actions;
    await saveGoal(this.state);
    this.setState({ name: '', taskGroup: '', options: [] });
  }

  render() {
    let { inputValues } = this.props.taskDetailReducer;
    const { name, options } = this.state;
    const {
      goalReducer: { savingGoal, savedGoal },
    } = this.props;
    return (
      <div>
        <div className='plan-content'>
          <h2>Add a Goal</h2>
        </div>
        <form className='goal-form' onSubmit={this.saveGoal.bind(this)}>
          <Form.Group controlId='taskGroupG'>
            <Form.Label> What Strategy are we creating a goal for? </Form.Label>
            <select
              type='select'
              id='taskGroup'
              name='taskGroup'
              style={{ color: '#383737' }}
              required
              onChange={(e) => this.setState({ taskGroup: e.target.value })}
              value={this.state.taskGroup}
            >
              <option value='' style={{ fontWeight: 'bold' }}>
                Please Select
              </option>
              {inputValues.taskGroupOptions &&
                inputValues.taskGroupOptions.map((taskGroup) => (
                  <option key={taskGroup.id} value={taskGroup.id}>
                    {taskGroup.name}
                  </option>
                ))}
            </select>
          </Form.Group>
          <label htmlFor='goalName'>What is your goal name? </label>
          <input
            type='text'
            id='goalName'
            name='goalName'
            required
            placeholder='Drive 100K in new Sales…'
            onChange={(e) => this.setState({ name: e.target.value })}
            value={name}
          />
          {options && options.length > 0 && (
            <h3 style={{ color: '#383737', marginTop: '20px' }}>
              This goal will have these type of possible options.
            </h3>
          )}
          {options &&
            options.map((option, optionIndex) => (
              <div
                key={`optionName-${optionIndex}`}
                style={{
                  padding: 20,
                  backgroundColor: 'rgba(255,255,255,0.1)',
                  marginBottom: 0,
                }}
              >
                <label htmlFor={`optionName-${optionIndex}`}>
                  What is the name of this option{' '}
                </label>
                <input
                  type='text'
                  id={`optionName-${optionIndex}`}
                  name={`optionName-${optionIndex}`}
                  required
                  placeholder='example: monthly reporting, biweekly monthly reporting, etc.'
                  onChange={(e) =>
                    this.updateOptionName(optionIndex, e.target.value)
                  }
                  value={option.name}
                />
                {option && option.values.length > 0 && (
                  <h4 style={{ color: '#383737', paddingTop: '15px' }}>
                    example: true, false, number, text, etc.
                  </h4>
                )}
                {option &&
                  option.values &&
                  option.values.map((value, valueIndex) => (
                    <div
                      key={`value-${optionIndex}-${valueIndex}`}
                      style={{
                        padding: '0px 20px',
                        backgroundColor: 'rgba(255,255,255,0.1)',
                        marginBottom: 15,
                      }}
                    >
                      <label htmlFor={`value-${optionIndex}-${valueIndex}`}>
                        Value
                      </label>
                      <input
                        type='text'
                        id={`value-${optionIndex}-${valueIndex}`}
                        name={`value-${optionIndex}-${valueIndex}`}
                        required
                        onChange={(e) =>
                          this.updateOptionValue(
                            optionIndex,
                            valueIndex,
                            e.target.value
                          )
                        }
                        value={value}
                      />
                    </div>
                  ))}
                <div className='row'>
                  <div className='col-xl-3 col-lg-4 col-md-4 col-sm-4 col-4'>
                    <button
                      className='savebutton'
                      style={{ fontSize: 16, padding: 10 }}
                      onClick={(e) => this.addOptionValue(e, optionIndex)}
                    >
                      Add Value
                    </button>
                  </div>
                </div>
              </div>
            ))}
          <div className='row'>
            <div className='col-xl-3 col-lg-4 col-md-4 col-sm-4 col-4'>
              <button
                className='savebutton'
                style={{ fontSize: 16, padding: 10 }}
                onClick={this.addOption}
              >
                Add Option
              </button>
            </div>
          </div>
          <br />
          <br />

          <div className='row'>
            <div className='col-xl-3 col-lg-4 col-md-4 col-sm-4 col-4'>
              <button
                type='submit'
                className='savebutton'
                style={{
                  fontSize: '16px',
                  padding: '10px',
                }}
              >
                {!savingGoal && !savedGoal && 'Save Goal'}
                {!savingGoal && savedGoal && 'Saved Goal'}
                {savingGoal && !savedGoal && 'Saving Goal'}
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { goalReducer, taskDetailReducer } = state;
  return {
    goalReducer,
    taskDetailReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(actions, dispatch),
    },
    ...bindActionCreators(taskDetailActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GoalForm);
