import _ from 'lodash';
import Swal from 'sweetalert2';

import { db } from '../../firebase.js';

// Add New Goal In Company
export const saveGoal = (goal) => async (dispatch, getState) => {
  const publicGoalsRef = db.ref('goals');
  const companyId = localStorage.getItem('companyID');

  // Check Goal Exist on Company
  const goalsSnap = await publicGoalsRef.once('value');
  const goals = _.filter(Object.values(goalsSnap.val()), {
    companyId: companyId,
  });
  // Find Selected Goal in Company Goals.
  const isAvailableObj = _.find(
    goals,
    (g) => g.name.toLowerCase().trim() === goal.name.toLowerCase().trim()
  );

  // If Exist Goal In Company throw Error
  if (isAvailableObj) {
    dispatch(savingGoal(false));
    Swal.fire('Already Exist!', `${goal.name.trim()} already exist`, 'warning');
    return;
  } else {
    // Good to Go Create Goal in Company.
    dispatch(savingGoal(true));

    // Adding Goal to Goal Collection
    let goalRef = await publicGoalsRef.push();
    goalRef
      .set({
        name: goal.name,
        id: goalRef.key,
        companyId: goal.companyId,
        taskGroup: goal.taskGroup,
        createdAt: new Date().toISOString(),
      })
      .then(() => {
        // Add Options to Created Goal
        const optionsRef = db.ref(`goals/${goalRef.key}/options`);
        goal.options.forEach((option, optionIndex) => {
          const optionRef = optionsRef.push();
          optionRef.set({
            name: option.name,
            id: optionRef.key,
            taskGroup: goal.taskGroup,
          });

          // Add Values in Selected Options
          const valuesRef = db.ref(
            `goals/${goalRef.key}/options/${optionRef.key}/values`
          );
          goal.options[optionIndex].values.forEach((value) => {
            const valueRef = valuesRef.push();
            valueRef.set(value);
          });
        });
        // Fetch Goals Again
        dispatch(fetchGoals());
      })
      .then(() => {
        setTimeout(() => dispatch(savingGoal(false)), 2000);
        setTimeout(() => dispatch(savedGoal(true)), 2500);
        setTimeout(() => dispatch(savedGoal(false)), 3500);
      })
      .catch((err) => {
        dispatch(savingGoal(false));
        dispatch(savedGoal(false));
      });
  }
};

// Fetch All Goals of companyID
export const fetchGoals = (companyID) => (dispatch, getState) => {
  db.ref('goals')
    .once('value')
    .then((snapshot) => {
      if (snapshot.exists()) {
        const goals = _.filter(Object.values(snapshot.val()), {
          companyId: companyID,
        });
        dispatch(setGoals(goals));
      }
    });
};

// Delete Goal By GoalId from Goals collection
export const deleteGoals = (goal) => async (dispatch, getState) => {
  let roalRef = db.ref(`goals`);
  const goals = getState().goalReducer.goals;
  try {
    await roalRef.child(goal.id).remove();
    const goalsArray = goals.filter((g) => g.id !== goal.id);
    dispatch(setGoals(goalsArray));
    return goal;
  } catch (error) {
    alert(error.message);
  }
};

// Update Goal By GoalId from Goals Collection
export const updateGoal = (goal) => async (dispatch, getState) => {
  const goals = getState().goalReducer.goals;
  dispatch(savingGoal(true));
  const publicGoalsRef = await db.ref(`goals/${goal.id}`);
  await db
    .ref(`goals/${goal.id}`)
    .update({
      name: goal.name,
      companyId: goal.companyId,
      taskGroup: goal.taskGroup,
      updatedAt: new Date().toISOString(),
    })
    .then(async () => {
      if (goal.options) {
        const optionsRef = await db.ref(`goals/${goal.id}/options`);
        goal.options.forEach(async (option, optionIndex) => {
          if (option && option.id) {
            const optionRef = await db.ref(
              `goals/${goal.id}/options/${option.id}`
            );
            optionRef.update({
              name: option.name,
              id: optionRef.key,
              taskGroup: goal.taskGroup,
            });
            if (option.values) {
              const valuesRef = db.ref(
                `goals/${goal.id}/options/${option.id}/values`
              );
              await valuesRef.remove();
              option.values.forEach((value) => {
                const valueRef = valuesRef.push();
                valueRef.set(value);
              });
            }
          } else {
            const optionRef = optionsRef.push();
            optionRef.set({
              name: option.name,
              id: optionRef.key,
              taskGroup: goal.taskGroup,
            });
            const valuesRef = db.ref(
              `goals/${goal.id}/options/${optionRef.key}/values`
            );
            goal.options[optionIndex].values.forEach((value) => {
              const valueRef = valuesRef.push();
              valueRef.set(value);
            });
          }
        });
      }
      const snapshot = await publicGoalsRef.once('value');
      const index = goals.findIndex((g) => g.id === goal.id);
      const goalsArray = [...goals];
      goalsArray[index] = snapshot.val();
      await dispatch(setGoals(goalsArray));
      setTimeout(() => dispatch(savingGoal(false)), 2000);
      setTimeout(() => dispatch(savedGoal(true)), 2500);
      setTimeout(() => dispatch(savedGoal(false)), 3500);
      return { goal: snapshot.val() };
    })
    .catch((err) => {
      dispatch(savingGoal(false));
      dispatch(savedGoal(false));
    });
};

// Actions
const setGoals = (goals) => ({
  type: 'SET_GOALS',
  goals,
});

const savingGoal = (bool) => ({
  type: 'SAVING_GOAL',
  savingGoal: bool,
});

const savedGoal = (bool) => ({
  type: 'SAVED_GOAL',
  savedGoal: bool,
});

export const setGoalInputValue = (name, value) => {
  return {
    type: 'SET_GOAL_INPUT_VALUE',
    name,
    value,
  };
};
