import moment from 'moment';

export const calendarDate = (date) => {
  return moment(date).calendar(null, {
    sameDay: 'LT',
    nextDay: '[Tomorrow]',
    nextWeek: 'dddd MMM DD',
    lastDay: '[Yesterday]',
    lastWeek: '[Last] dddd',
    sameElse: 'DD/MM/YYYY',
  });
};

export const formatDate = (date, formate) => {
  return moment(date).format(formate);
};

export const trimText = (text = '', limit) => {
  return text.length > limit ? `${text.slice(0, limit)}...` : text;
};

export const isEmail = (email) => {
  // eslint-disable-next-line no-control-regex
  const regEx = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
  return regEx.test(email);
};

// Storage
export const saveToLocalStorage = (key, value) => {
  localStorage[key] = JSON.stringify(value);
};

export const getFromLocalStorage = (key) => {
  if (localStorage[key]) {
    return JSON.parse(localStorage[key]);
  } else {
    return false;
  }
};

export const removeFromLocalStorage = (key) => {
  localStorage.removeItem(key);
};

export const isImg = (str) => {
  const imgRegex = /\.(gif|jpg|jpeg|tiff|png|webp|bmp)$/i;
  return imgRegex.test(str);
};
