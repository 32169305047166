import { db, fba } from '../../firebase.js';

const setUserInitialTasks = userName => {
  const tasks = [
    {
      goalId: '0',
      description: 'Go to the goals page and set goals.',
      title: 'Set Goals',
      planId: '0',
      points: '6',
      taskGroupId: '0',
      id: '0',
      isComplete: false
    },
    {
      goalId: '0',
      description: 'Go to the plans page and create a plan.',
      title: 'Establish a Plan',
      planId: '0',
      points: '6',
      taskGroupId: '0',
      id: '1',
      isComplete: false
    },
    {
      goalId: '0',
      description: 'Try completing a task and watch your score go up.',
      title: 'Test Task Completion',
      planId: '0',
      points: '6',
      taskGroupId: '0',
      id: '2',
      isComplete: false
    },
    {
      goalId: '0',
      description: 'After you have goals start your sprint.',
      title: 'Start a Sprint',
      planId: '0',
      points: '6',
      taskGroupId: '0',
      id: '3',
      isComplete: false
    }
  ];
  db.ref(`users/${userName}/tasks`).set(tasks);
};

// const setUserInitialGoals = (userName) => {
//     const updates = {};
//     updates['goals/isFunnel'] = 'false';
//     updates['goals/isRevenue'] = 'false';
//     updates['goals/isFunding'] = 'false';
//     updates['goals/isGrowth'] = 'false';
//     db.ref(`users/${userName}`)
//         .update(updates);
// };

// const getMonths = () => {
//     const nextMonth = (date) => {
//         if (date.getMonth() === 11) {
//             return new Date(date.getFullYear() + 1, 0, 1);
//         }
//         return new Date(date.getFullYear(), date.getMonth() + 1, 1);
//     };

//     const formatMonth = number => (`0${number}`).slice(-2);

//     const months = [];
//     let date = new Date();
//     for (let i = 0; i < 24; i++) {
//         date = nextMonth(date);
//         months.push(`${formatMonth(date.getMonth() + 1)}/${date.getFullYear()}`);
//     }
//     return months;
// };

export const createUserWithEmailAndPassword = cb => (dispatch, getState) => {
  const { userName, email, password } = getState().appReducer.inputValues;
  dispatch(signingUp(true));
  db.ref(`users/${userName}`)
    .once('value')
    .then(snapshot => {
      if (snapshot.exists()) {
        dispatch(userExists(true));
        cb('Username not available.');
        return false;
      }
      fba
        .createUserWithEmailAndPassword(email, password)
        .then(({ user }) => {
          db.ref(`users/${userName}`)
            .set({ email })
            .then(() => {
              setUserInitialTasks(userName);
              dispatch(
                setUser({
                  email: user.email,
                  uid: user.uid,
                  userName
                })
              );
              dispatch(authenticate(true));
              dispatch(signingUp(false));
            })
            .catch(error => {
              cb(error.message);
            });
        })
        .catch(error => {
          cb(error.message);
        });
    });
};

export const signInWithUsernameAndPassword = cb => (dispatch, getState) => {
  const { userName, password } = getState().appReducer.inputValues;
  dispatch(signingIn(true));
  db.ref(`users/${userName}`).once('value', snapshot => {
    if (snapshot.exists()) {
      const email = snapshot.val().email;
      fba
        .signInWithEmailAndPassword(email, password)
        .then(({ user }) => {
          dispatch(
            setUser({
              email: user.email,
              uid: user.uid,
              userName
            })
          );
          dispatch(authenticate(true));
        })
        .catch(error => {
          cb(error.message);
          dispatch(signInError(true, error));
        });
    } else {
      cb('User not found.');
      dispatch(signInError(true, 'User not found.'));
    }
  });
};

export const fetchAdvisees = () => (dispatch, getState) => {
  const {
    appReducer: { user }
  } = getState();
  db.ref(`advisorRelationships/advisors/${user.userName}`).once(
    'value',
    snapshot => {
      if (snapshot.exists()) {
        dispatch({
          type: 'SET_ADVISEES',
          advisees: Object.values(snapshot.val())
        });
      } else {
        dispatch({
          type: 'SET_ADVISEES',
          advisees: []
        });
        dispatch(setSelectedAdvisee(null));
        dispatch(setSelectedAdviseePlans({}));
        dispatch(setSelectedAdviseeTasks([]));
        dispatch(setSelectedAdviseeGoals({}));
        dispatch(setSelectedAdviseeTaskGroups({}));
      }
      onSelectAdvisee();
    }
  );
};

export const fetchGlobalData = index => (dispatch, getState) => {
  const getGoals = new Promise((resolve, reject) =>
    db.ref(`goals`).once('value', snapshot => {
      if (snapshot.exists()) {
        resolve(snapshot.val());
      } else {
        reject();
      }
    })
  );
  const getPlans = new Promise((resolve, reject) =>
    db.ref(`plans`).once('value', snapshot => {
      if (snapshot.exists()) {
        resolve(snapshot.val());
      } else {
        reject();
      }
    })
  );
  const getTasks = new Promise((resolve, reject) =>
    db.ref(`tasks`).once('value', snapshot => {
      if (snapshot.exists()) {
        resolve(snapshot.val());
      } else {
        reject();
      }
    })
  );

  Promise.all([getGoals, getPlans, getTasks]).then(values => {
    const goals = values[0];
    const plans = values[1];
    const tasks = values[2];

    dispatch(setGlobalPlans(plans));
    dispatch(setGlobalGoals(goals));
    dispatch(setGlobalTasks(tasks));
  });
};

export const onSelectAdvisee = index => (dispatch, getState) => {
  const {
    appReducer: {
      advisees,
      selectedAdvisee: { name }
    }
  } = getState();
  let indexOfName = advisees.indexOf(name);
  if (indexOfName === -1) indexOfName = 0;
  const selectedAdvisee =
    index !== undefined ? advisees[index] : advisees[indexOfName];
  if (selectedAdvisee) {
    dispatch(setSelectedAdvisee(selectedAdvisee));

    const getUserGoals = new Promise((resolve, reject) =>
      db.ref(`users/${selectedAdvisee}/goals`).once('value', snapshot => {
        if (snapshot.exists()) {
          resolve(snapshot.val());
        } else {
          reject();
        }
      })
    );
    const getUserPlans = new Promise((resolve, reject) =>
      db.ref(`users/${selectedAdvisee}/plans`).once('value', snapshot => {
        if (snapshot.exists()) {
          resolve(snapshot.val());
        } else {
          reject();
        }
      })
    );
    const getUserTasks = new Promise((resolve, reject) =>
      db.ref(`users/${selectedAdvisee}/tasks`).once('value', snapshot => {
        if (snapshot.exists()) {
          resolve(Object.values(snapshot.val()));
        } else {
          reject();
        }
      })
    );
    const getTaskGroups = new Promise((resolve, reject) =>
      db.ref('taskGroups').once('value', snapshot => {
        if (snapshot.exists()) {
          resolve(Object.values(snapshot.val()));
        } else {
          reject();
        }
      })
    );

    Promise.all([getUserGoals, getUserPlans, getUserTasks, getTaskGroups]).then(
      values => {
        const userGoals = values[0];
        const userPlans = values[1];
        const userTasks = values[2];
        const taskGroups = values[3];

        const filteredTasks = [];

        for (
          let userTaskIndex = 0;
          userTaskIndex < userTasks.length;
          userTaskIndex++
        ) {
          const taskMatchesPlans = userPlans[userTasks[userTaskIndex].planId];
          let taskMatchesGoals = userGoals[userTasks[userTaskIndex].goalId];

          for (const key in userGoals) {
            if (
              userTasks[userTaskIndex][key] &&
              userTasks[userTaskIndex][key] !== userGoals[key]
            ) {
              taskMatchesGoals = false;
            }
          }

          if (taskMatchesGoals && taskMatchesPlans) {
            filteredTasks.push({
              key: userTasks[userTaskIndex].id,
              ...userTasks[userTaskIndex]
            });
          }

          if (userTasks[userTaskIndex].planId === '0') {
            filteredTasks.push({
              key: userTasks[userTaskIndex].id,
              ...userTasks[userTaskIndex]
            });
          }
        }
        dispatch(setSelectedAdviseeGoals(userGoals));
        dispatch(setSelectedAdviseePlans(userPlans));
        dispatch(setSelectedAdviseeTasks(filteredTasks));
        dispatch(setSelectedAdviseeTaskGroups(taskGroups));
      }
    );
  } else {
    dispatch(setSelectedAdvisee(null));
    dispatch(setSelectedAdviseeGoals({}));
    dispatch(setSelectedAdviseePlans({}));
    dispatch(setSelectedAdviseeTasks([]));
    dispatch(setSelectedAdviseeTaskGroups({}));
  }
};

const setGlobalGoals = goals => ({
  type: 'SET_GLOBAL_GOALS',
  goals
});

const setGlobalPlans = plans => ({
  type: 'SET_GLOBAL_PLANS',
  plans
});

const setGlobalTasks = tasks => ({
  type: 'SET_GLOBAL_TASKS',
  tasks
});

const setSelectedAdvisee = advisee => ({
  type: 'SET_SELECTED_ADVISEE',
  advisee
});

const setSelectedAdviseeGoals = goals => ({
  type: 'SET_SELECTED_ADVISEE_GOALS',
  goals
});

const setSelectedAdviseePlans = plans => ({
  type: 'SET_SELECTED_ADVISEE_PLANS',
  plans
});

const setSelectedAdviseeTaskGroups = taskGroups => ({
  type: 'SET_SELECTED_ADVISEE_TASK_GROUPS',
  taskGroups
});

const setSelectedAdviseeTasks = tasks => ({
  type: 'SET_SELECTED_ADVISEE_TASKS',
  tasks
});

export const setAdvisorInputValue = (name, value) => {
  return {
    type: 'SET_ADVSIOR_INPUT_VALUE',
    name,
    value
  };
};

export const logout = () => ({
  type: 'LOGOUT'
});

export const setInviteId = inviteId => ({
  type: 'SET_INVITE_TOKEN',
  inviteId
});

export const validateinviteId = cb => (dispatch, getState) => {
  const { inviteId } = getState().appReducer;
  db.ref(`advisorInvites/${inviteId}`)
    .once('value')
    .then(snapshot => {
      if (snapshot.exists()) {
        const invite = snapshot.val();
        if (invite.responded === true) {
          dispatch(setInviteId(null));
          cb({ isValid: false });
        } else {
          cb({ isValid: true, invite, showModal: true });
        }
      } else {
        dispatch(setInviteId(null));
        cb({ isValid: false });
      }
    })
    .catch(error => {
      dispatch(setInviteId(null));
      cb({ isValid: false });
    });
};

export const inviteResponse = (accepted, invite) => (dispatch, getState) => {
  const { inviteId, user } = getState().appReducer;
  if (accepted) {
    db.ref(`advisorRelationships/advisors/${user.userName}/${inviteId}`).set(
      invite.advisee
    );
    db.ref(`advisorRelationships/advisees/${invite.advisee}/${inviteId}`).set(
      user.userName
    );
    dispatch(fetchAdvisees());
  }
  db.ref(`advisorInvites/${inviteId}/responded`).set(true);
  db.ref(`advisorInvites/${inviteId}/accepted`).set(accepted);
  dispatch(setInviteId(null));
};

const signingUp = bool => ({
  type: 'SIGNING_UP',
  bool
});

const userExists = bool => ({
  type: 'USER_EXISTS',
  bool
});

const signingIn = bool => ({
  type: 'SIGNING_IN',
  bool
});

const signInError = (bool, error = false) => ({
  type: 'SIGN_IN_ERROR',
  bool,
  error
});

export const authenticate = bool => ({
  type: 'AUTHENTICATE',
  bool
});

export const noAuth = () => ({
  type: 'NO_AUTH'
});

const setUser = user => ({
  type: 'SET_USER',
  user
});
