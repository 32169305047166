import firebase from 'firebase/app';
import 'firebase/app';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';
import 'firebase/messaging';
import * as constants from './constants';

firebase.initializeApp({
  apiKey: constants.FBK,
  authDomain: constants.FBD,
  projectId: constants.FBID,
  databaseURL: constants.FBDBURL,
  storageBucket: constants.FBSTORAGE,
  appId: constants.APPID,
  messagingSenderId: constants.MESSAGINGSENDERID,
});

const fb = firebase.app();
const fba = fb.auth();
const db = fb.database();
const storage = fb.storage();
const messaging = firebase.messaging();
export { fb, db, fba, storage, messaging };
