export function goalReducer(
  state = {
    goals: [],
    savingGoal: false,
    savedGoal: false,
    inputValues: {
      goalName: '',
      options: {},
      values: {}
    }
  },
  action
) {
  switch (action.type) {
    case 'SET_GOALS':
      return {
        ...state,
        goals: action.goals
      };
    case 'SAVING_GOAL':
      return {
        ...state,
        savingGoal: action.savingGoal
      };
    case 'SAVED_GOAL':
      return {
        ...state,
        savedGoal: action.savedGoal
      };
    case 'SET_GOAL_INPUT_VALUE':
      return {
        ...state,
        inputValues: {
          ...state.inputValues,
          [action.name]: action.value
        }
      };
    default:
      return state;
  }
}
