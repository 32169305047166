export const setStoryboardGoalInputValue = (name, value) => {
  return {
    type: 'SET_STORYBOARD_INPUT_VALUE',
    name,
    value,
  };
};

export const setGoalOptionsValue = (payload) => {
  return {
    type: 'SET_STORYBOARD_OPTION',
    payload,
  };
};

export const clearStoryBoardInputValue = () => {
  return {
    type: 'CLEAR_STORYBOARD_INPUT_VALUE',
  };
};
