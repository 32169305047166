import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import MemberForm from './MemberForm';
import * as memberActions from '../redux/actions/memberActions.js';
import * as loaderAction from '../redux/actions/loaderAction.js';
import * as taskDetailActions from '../redux/actions/taskDetailActions.js';

class MemberContainer extends Component {
  state = {
    loader: false
  };
  componentDidMount() {
    let companyId = localStorage.getItem('companyID');
    const { loaderAction } = this.props.loaderAction;
    loaderAction(false);
    const { fetchTaskGroups } = this.props.taskActions;
    fetchTaskGroups(companyId); // get list of Task group from Firebase
  }

  onSavePlan = e => {
    const { savePlan } = this.props.memberActions;
    e.preventDefault();
    const { loaderAction } = this.props.loaderAction;
    loaderAction(true);
    return savePlan();
  };

  render() {
    const { setMemberInputValue } = this.props.memberActions;
    const { inputValues, savingPlan, savedPlan } = this.props.memberReducer;
    const taskGroups = this.props.taskDetailReducer.inputValues;
    const { taskGroupOptions } = taskGroups;
    const { onSavePlan } = this;
    return (
      <div>
        <div className='plan-content'>
          <h2>Add New Member</h2>
        </div>
        <MemberForm
          {...this.props}
          taskGroupOptions={taskGroupOptions}
          onInputChange={setMemberInputValue}
          inputValues={inputValues}
          savePlan={onSavePlan}
          savingPlan={savingPlan}
          savedPlan={savedPlan}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { memberReducer, loaderReducer, taskDetailReducer } = state;
  return {
    memberReducer,
    loaderReducer,
    taskDetailReducer
  };
}

function mapDispatchToProps(dispatch) {
  return {
    taskActions: bindActionCreators(taskDetailActions, dispatch),
    memberActions: bindActionCreators(memberActions, dispatch),
    loaderAction: bindActionCreators(loaderAction, dispatch)
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MemberContainer)
);
