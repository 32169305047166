import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';

import * as appActions from './redux/actions/appActions.js';
import { fba } from './firebase.js';

import './App.css';
import Header from './components/header';
import Sidebar from './components/sidebar';
import GoalForm from './components/GoalForm';
import TaskGroupsContainer from './components/TaskGroupsContainer';
import TaskDetailsContainer from './components/TaskDetailsContainer';
import EmployeeDetails from './components/employee';
import AdvisorView from './components/advisor/Advisor';
import MemberContainer from './components/MemberContainer';
import Login from './components/Login';
import Signup from './components/Signup';
import ForgotPass from './components/ForgotPass';
import Storyboard from './components/storyboad';
import ArchivedTaskContainer from './components/ArchivedTaskContainer.js';

import PrivateRouteCompany from './PrivateRouteCompany';
import PublicRoute from './PublicRoute';
import NetworkDetector from './utils/NetworkDetector';

import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const TaskRoutes = (props) => (
  <Fragment>
    <div className='main-container'>
      <Header />
      <Sidebar />
    </div>
    <div className='Aside_right'>
      <div className='plans-container'>
        <Switch>
          <Route
            path='/admin/member'
            render={(props) => <MemberContainer {...props} />}
          />
          <Route
            path='/admin/taskGroups'
            render={(props) => <TaskGroupsContainer {...props} />}
          />
          <Route
            path='/admin/addMember'
            render={(props) => <TaskGroupsContainer {...props} />}
          />
          <Route
            path='/admin/goals'
            render={(props) => <GoalForm {...props} />}
          />
          <Route
            path='/admin/employee-details'
            render={(props) => <EmployeeDetails {...props} />}
          />
          <Route
            path='/admin/tasks'
            render={(props) => <TaskDetailsContainer {...props} />}
          />
          <Route
            path='/admin/archived-tasks'
            render={(props) => <ArchivedTaskContainer {...props} />}
          />
          <Route
            path='/admin/storyboard'
            render={(props) => <Storyboard {...props} />}
          />
          <Route path='/admin'>
            <Redirect to='/admin/storyboard' />
          </Route>
          {/* <Route path='/admin' render={props => <Admin {...props} />} /> */}
        </Switch>
      </div>
    </div>
  </Fragment>
);
let userType;
let isCompany = false;
class App extends Component {
  componentDidMount() {
    fba.onAuthStateChanged((user) => {
      if (!user) {
        localStorage.removeItem('persist:root');
        localStorage.removeItem('isLogin');
        localStorage.removeItem('companyID');
        localStorage.removeItem('company');
        localStorage.removeItem('userType');
        localStorage.removeItem('username');
        this.props.history.push('/');
      }
    });
  }
  render() {
    userType = localStorage.getItem('userType');
    if (userType === 'Company' || userType === 'SuperAdmin') {
      isCompany = true;
    }

    return (
      <div style={{ height: '100%', width: '100%' }}>
        <Switch>
          <PublicRoute
            restricted={isCompany}
            exact
            path='/'
            component={Login}
          />
          <PublicRoute
            restricted={isCompany}
            exact
            path='/Signup'
            component={Signup}
          />
          <PublicRoute
            restricted={isCompany}
            exact
            path='/ForgotPass'
            component={ForgotPass}
          />

          <PrivateRouteCompany
            restricted={isCompany}
            path='/admin'
            component={TaskRoutes}
          />
          {/* <PrivateRouteSuperAdmin
            restricted={isCompany}
            exact
            path='/Dashboard'
            component={Dashboard}
          /> */}
          <PublicRoute
            restricted={isCompany}
            exact
            path='/login'
            component={Login}
          />
          <AdvisorView {...this.props} />
        </Switch>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { appReducer } = state;
  return {
    state,
    appReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(appActions, dispatch),
    },
  };
}

export default NetworkDetector(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
);
