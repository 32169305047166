import { db } from '../../firebase.js';

export const fetchCompanyList = () => (dispatch, getState) => {
  db.ref('companyname')
    .once('value')
    .then(snapshot => {
      if (snapshot.exists()) {
        dispatch(companyList(snapshot.val()));
        return true;
      }
    });
};

const companyList = data => ({
  type: 'COMPANY_LIST',
  companyList: data
});
