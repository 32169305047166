import React, { Fragment } from "react";
import { Link, Route } from "react-router-dom";

const AdviseeInfo = ({ selectedAdvisee, global }) => {
  return (
    !!selectedAdvisee && (
      <div className="advisor-content">
        <Fragment>
          <div className="advisee-info-links">
            <Link to={`/advisor/${selectedAdvisee.name}/tasks`}>Tasks</Link>
            <Link to={`/advisor/${selectedAdvisee.name}/goals`}>Goals</Link>
            <Link to={`/advisor/${selectedAdvisee.name}/plans`}>Plans</Link>
          </div>
          <Route
            path="/advisor/:advisee/tasks"
            render={() => <AdviseeTasks selectedAdvisee={selectedAdvisee} global={global} />}
          />
          <Route
            path="/advisor/:advisee/goals"
            render={() => <AdviseeGoals selectedAdvisee={selectedAdvisee} global={global} />}
          />
          <Route
            path="/advisor/:advisee/plans"
            render={() => <AdviseePlans selectedAdvisee={selectedAdvisee} global={global} />}
          />
        </Fragment>
      </div>
    )
  );
};

const AdviseeTasks = ({ selectedAdvisee, global }) => {
  return (
    <div className="advisee-info-tasks-container">
      {!!selectedAdvisee &&
        !!selectedAdvisee.tasks &&
        selectedAdvisee.tasks.map(task => (
          <div key={task.id} className="advisee-info-task-card">
            <div className="advisee-info-task-details">
              <span>
                <strong>{task.title}</strong>
              </span>
              <span>Description: {task.description}</span>
              {global.goals[task.goalId] && <span>Goal Type: {global.goals[task.goalId].name}</span>}
              {global.plans[task.planId] && <span>Plan Type: {global.plans[task.planId].name}</span>}
              <span>Points: {task.points}</span>
            </div>
            <div className="advisee-info-task-completion">
              {task.isComplete ? (
                <span style={{ color: "#30C0FF" }}>Complete</span>
              ) : (
                <span style={{ color: "#F8444B" }}>Incomplete</span>
              )}
            </div>
          </div>
        ))}
    </div>
  );
};

const AdviseeGoals = ({ selectedAdvisee, global }) => {
  return (
    <div className="advisee-info-tasks-container">
      {!!selectedAdvisee && (
        <div>
          {Object.values(global.goals).map(goal => selectedAdvisee.goals && selectedAdvisee.goals[goal.id] ? (
            <div className="advisee-info-task-card">
              <div>
                <strong>{goal.name}</strong>
                <div>
                {Object.values(goal.options).map(option => selectedAdvisee.goals && selectedAdvisee.goals[option.id] ? (
                  <div>{option.name}: {selectedAdvisee.goals[option.id]}</div>
                ) : null)}
                </div>
              </div>
            </div>
          ) : null)}
        </div>
      )}
    </div>
  );
};

const AdviseePlans = ({ selectedAdvisee, global }) => {
  return (
    <div className="advisee-info-tasks-container">
      {!!selectedAdvisee && (
        <div>
          {Object.values(global.plans).map(plan => selectedAdvisee.plans && selectedAdvisee.plans[plan.id] ? (
            <div className="advisee-info-task-card">
                <strong>{plan.name}</strong>
            </div>
          ) : null)}
        </div>
      )}
    </div>
  );
};

export default AdviseeInfo;
