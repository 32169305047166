import React, { useState, useRef, useEffect } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import DatePicker from 'react-datepicker';
import Picker from 'react-emojipicker';
import Avatar from '@material-ui/core/Avatar';
import _ from 'lodash';
import GoalTypeQualifiers from './GoalTypeQualifiers';
import { docType, imgTypes, videoTypes } from './../utils/mime-type';
import Swal from 'sweetalert2';

const getGoalTypeQualifiers = (
  inputValues,
  onInputChange,
  getGoalTypeOptionKeys, // Dont't Remove it will break app
  goals
) => {
  let chosenGoalType = inputValues.taskDetailsGoalType;
  return (
    <GoalTypeQualifiers
      chosenGoalType={chosenGoalType}
      onInputChange={onInputChange}
      inputValues={inputValues}
      goals={goals}
    />
  );
};

const TaskDetailsForm = ({
  saveTaskDetails,
  onInputChange,
  onQualifierInputChange,
  getGoalTypeOptionKeys,
  inputValues,
  savingTask,
  savedTask,
  goals,
  employees,
  onClear,
  resetFile,
  removeOriginalFile,
  taskGroupOptions,
}) => {
  let emojiValue = '';
  const [isEmojiOpen, toggleIsEmoji] = useState(false);
  const handleEmojiToggle = () => {
    const isShow = isEmojiOpen ? false : true;
    toggleIsEmoji(isShow);
  };
  let imageRef = useRef();
  if (imageRef.current !== undefined && imageRef.current !== null) {
    if (!savingTask && !!savedTask) {
      imageRef.current.value = null;
    }
  }

  const handleImageChange = (e) => {
    const orginalFiles = inputValues.file && [...inputValues.file];
    let files = [...e.target.files];
    if(orginalFiles && orginalFiles.length > 0) {
      files = [...orginalFiles, ...files];
    }
    if (files.length === 1) {
      const file = e.target.files[0];
      if (file) {
        const acceptedTypes = [...videoTypes, ...docType.concat(imgTypes)];
        if (!acceptedTypes.includes(file.type)) {
          Swal.fire('Error', `Please Select Valid File!`, 'warning');
          imageRef.current.value = null;
          return;
        } else {
          onInputChange('file', files);
        }
      }
    } else if (files.length > 1) {
      if (files) {
        let validFiles = [];
        _.forEach(files, (file, i) => {
          const acceptedTypes = [...videoTypes, ...docType.concat(imgTypes)];
          if (!acceptedTypes.includes(file.type)) {
            Swal.fire('Error', `Please Select Valid File!`, 'warning');
            imageRef.current.value = null;
            return;
          } else {
            validFiles.push(file);
          }
        });
        onInputChange('file', validFiles);
      }
    } else {
      Swal.fire('Error', `Please Select Valid File!`, 'warning');
      return;
    }
  };

  const [goalOptions, setGoalOptions] = useState([]);

  const handleGoalOptions = (name, value) => {
    const goal = goals.filter((f) => f.id === value)[0];
    let filterOptions;
    if (goal) {
      filterOptions = goal.options ? Object.values(goal.options) : [];
    } else {
      filterOptions = [];
    }
    setGoalOptions(filterOptions);
    onInputChange(name, value);
    onInputChange('taskDetailsGoalOption', '');
  };

  const handleGoals = (name, value) => {
    onInputChange(name, value);
    onInputChange('taskDetailsGoalType', '');
    onInputChange('taskDetailsGoalOption', '');
  };

  const handleOptionsOnLoad = () => {
    const goal = goals.find((g) => g.id === inputValues.taskDetailsGoalType);
    if (goal && goal.options) {
      setGoalOptions(Object.values(goal.options));
    }
  };

  const handleEditorState = (e) => {
    onInputChange('editorState', e);
  };

  useEffect(() => {
    if (inputValues.taskId) {
      handleOptionsOnLoad();
    }
  }, []);

  return (
    <form
      id='task-details-form'
      className='task-details-form taskform'
      onSubmit={(e) => saveTaskDetails(e)}
    >
      <div className='row mb-2'>
        <div className='col col-sm-12'>
          <select
            type='select'
            id='assignBy'
            name='assignBy'
            required
            onChange={(e) => onInputChange(e.target.name, e.target.value)}
            value={inputValues.assignBy}
          >
            <option value='' style={{ fontWeight: 'bold' }} disabled>
              Assign By
            </option>
            <option id='employee' value='employee'>
              Member
            </option>
            <option id='taskGroup' value='taskGroup'>
              Strategy
            </option>
            <option id='employee' value='unassigned'>
              Unassigned
            </option>
          </select>
        </div>
      </div>
      {inputValues.assignBy && inputValues.assignBy === 'employee' ? (
        <div className='row mb-2'>
          <div className='col col-sm-12'>
            <select
              type='select'
              id='empID'
              name='empID'
              // required
              onChange={(e) => onInputChange(e.target.name, e.target.value)}
              value={inputValues.empID}
            >
              <option value='' style={{ fontWeight: 'bold' }} disabled>
                Select Member ID
              </option>
              {employees &&
                employees.map((emp, i) => (
                  <option key={i} value={`${emp.empName}-${emp.username}`}>
                    {emp.empName}
                  </option>
                ))}
            </select>
          </div>
        </div>
      ) : null}

      <div className='row mb-2'>
        <div className='col-md-4 col-sm-12'>
          <input
            required
            type='text'
            id='taskDetailTitle'
            name='taskDetailTitle'
            placeholder='Task title'
            style={{ paddingRight: '28px' }}
            onChange={(e) => onInputChange(e.target.name, e.target.value)}
            ref={(ref) => (emojiValue = ref)}
            value={inputValues.taskDetailTitle}
          />

          <i
            style={{
              position: 'absolute',
              right: '20px',
              top: '11px',
              fontSize: '21px',
              cursor: 'pointer',
            }}
            className='fa fa-smile-o'
            aria-hidden='true'
            onClick={() => handleEmojiToggle()}
          />
          {isEmojiOpen ? (
            <Picker
              onEmojiSelected={(emoji) => {
                emojiValue.value = emojiValue.value + emoji.unicode;
                inputValues.taskDetailTitle = emojiValue.value;
                handleEmojiToggle();
              }}
            />
          ) : (
            false
          )}
        </div>

        <div className='col-md-4 col-sm-12'>
          <input
            required
            type='number'
            id='taskDetailPoints'
            name='taskDetailPoints'
            placeholder='Points'
            onChange={(e) => onInputChange(e.target.name, e.target.value)}
            value={inputValues.taskDetailPoints}
          />
        </div>

        <div className='col-md-4 col-sm-12'>
          <select
            // required
            type='select'
            id='taskGroup'
            name='taskGroup'
            onChange={(e) => handleGoals(e.target.name, e.target.value)}
            value={inputValues.taskGroup}
          >
            <option value='' style={{ fontWeight: 'bold' }}>
              Select Strategy
            </option>
            {taskGroupOptions &&
              taskGroupOptions.map((taskGroup, i) => (
                <option key={i} value={taskGroup.id}>
                  {taskGroup.name}
                </option>
              ))}
          </select>
        </div>
      </div>

      <div className='row mb-2'>
        <div className='col-md-6 col-sm-12'>
          <select
            // required
            type='select'
            id='taskDetailsGoalType'
            name='taskDetailsGoalType'
            onChange={(e) => handleGoalOptions(e.target.name, e.target.value)}
            value={inputValues.taskDetailsGoalType}
          >
            <option value='' style={{ fontWeight: 'bold' }}>
              Select Goal Type
            </option>
            {goals &&
              Object.values(goals).map((goal, i) => (
                <option key={i} value={goal.id}>
                  {goal.name}
                </option>
              ))}
          </select>
        </div>

        <div className='col-md-6 col-sm-12'>
          <select
            // required
            type='select'
            id='taskDetailsGoalOption'
            name='taskDetailsGoalOption'
            onChange={(e) => onInputChange(e.target.name, e.target.value)}
            value={inputValues.taskDetailsGoalOption}
          >
            <option value='' style={{ fontWeight: 'bold' }}>
              Select Option Type
            </option>
            {goalOptions &&
              goalOptions.map((option, i) => (
                <option key={i} value={option.id}>
                  {option.name}
                </option>
              ))}
          </select>
        </div>
      </div>
      <div className='row mb-2'>
        <div className='col-md-4 col-sm-12'>
          <DatePicker
            required
            minDate={new Date()}
            selected={
              inputValues.startDate && typeof inputValues.startDate !== 'string'
                ? inputValues.startDate
                : null
            }
            placeholderText='Start Date'
            onChange={(data) => onInputChange('startDate', data)}
          />
        </div>

        <div className='col-md-4 col-sm-12'>
          <DatePicker
            required
            minDate={new Date()}
            selected={
              inputValues.endDate && typeof inputValues.endDate !== 'string'
                ? inputValues.endDate
                : null
            }
            placeholderText='End Date'
            disabled
            onChange={(data) => onInputChange('endDate', data)}
          />
        </div>

        <div className='col-md-4 col-sm-12'>
          <DatePicker
            placeholderText='Select Time'
            required
            selected={
              inputValues.endTime && typeof inputValues.endTime !== 'string'
                ? inputValues.endTime
                : null
            }
            onChange={(data) => onInputChange('endTime', data)}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption='Time'
            dateFormat='h:mm aa'
          />
        </div>
      </div>
      <div className='row mb-2'>
        <div className='col'>
          <div className='form-group files'>
            <input
              type='file'
              onChange={(e) => handleImageChange(e)}
              className='form-control'
              id='isImg'
              ref={imageRef}
              multiple
            />
            <div className='row mt-3 pl-1'>
              {inputValues.originalFiles &&
                inputValues.originalFiles.map((file, i) => (
                  <>
                    <div className='col-2' key={i}>
                      {file && file.isImage && (
                        <div id='taskImages'>
                          <Avatar alt='Profile Img' src={file.url} />
                        </div>
                      )}
                      {file && file.isDocument && (
                        <div className='choosepdf'>
                          <i className='fa fa-file' />
                          {file.name}
                        </div>
                      )}
                      {file && file.isVideo && (
                        <div className='choosepdf'>
                          <i className='fa fa-video-camera' />
                          {file.name}
                        </div>
                      )}

                      {file && (
                        <i
                          className='fa fa-times imageicon'
                          onClick={() => {
                            removeOriginalFile(file);
                          }}
                        ></i>
                      )}
                    </div>
                  </>
                ))}
              {inputValues.file &&
                [...inputValues.file].map((file, i) => (
                  <>
                    <div className='col-2' key={i}>
                      {file && imgTypes.includes(file.type) && (
                        <div id='taskImages'>
                          <Avatar
                            alt='Profile Img'
                            src={URL.createObjectURL(file)}
                          />
                        </div>
                      )}
                      {file && docType.includes(file.type) && (
                        <div className='choosepdf'>
                          <i className='fa fa-file' /> {file.name}
                        </div>
                      )}
                      {file && videoTypes.includes(file.type) && (
                        <div className='choosepdf'>
                          <i className='fa fa-video-camera' /> {file.name}
                        </div>
                      )}
                      <i
                        className='fa fa-times imageicon'
                        onClick={() => {
                          resetFile(file);
                          imageRef.current.value = null;
                        }}
                      ></i>
                    </div>
                  </>
                ))}
            </div>
          </div>
        </div>
      </div>
      <div className='row mb-2'>
        <div className='col linkmodal'>
          <Editor
            editorState={inputValues.editorState}
            toolbarClassName='toolbarClassName'
            wrapperClassName='wrapperClassName'
            editorClassName='editorClassName'
            onEditorStateChange={(e) => handleEditorState(e)}
          />
        </div>
      </div>
      <div>
        {inputValues.taskDetailsGoalType &&
          getGoalTypeQualifiers(
            inputValues,
            onQualifierInputChange,
            getGoalTypeOptionKeys,
            goals
          )}
      </div>
      <div className='row'>
        <div className='col-xl-2 col-lg-4 col-md-4 col-sm-4 col-4'>
          <button
            className='btn btn-primary savebutton'
            disabled={!!savingTask}
          >
            {!!savingTask && 'Saving...'}
            {!savingTask && !!savedTask && 'Task saved!'}
            {!savingTask && !savedTask && 'Save Task'}
          </button>
        </div>
        <div
          onClick={onClear}
          className='col-xl-2 col-lg-3 col-md-4 col-sm-4 col-4 ml-3 ml-md-0'
        >
          <button className='btn cancelbtn'>Cancel</button>
        </div>
      </div>
    </form>
  );
};

export default TaskDetailsForm;
