import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import AdvisorInviteModal from "./AdvisorInviteModal";
import AdviseeInfo from "./AdviseeInfo";
import * as actions from "../../redux/actions/appActions.js";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";

const getUrlVars = () => {
  var vars = {};
  window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m,key,value) => {
    vars[key] = value;
  });
  return vars;
};

class AdvisorContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isValid: false,
      showModal: false,
      selectedAdviseeIndex: 0
    };
    const inviteId = getUrlVars()["inviteId"];
    if (!!inviteId) {
      props.actions.setInviteId(inviteId);
    }
  }

  componentDidMount() {
    const { validateinviteId, fetchAdvisees, fetchGlobalData } = this.props.actions;
    validateinviteId(({ isValid, invite, showModal }) => {
      this.setState({ isValid, invite, showModal });
    });
    fetchGlobalData();
    fetchAdvisees();
  }

  inviteResponse = accepted => {
    const { inviteResponse } = this.props.actions;
    inviteResponse(true, this.state.invite);
    this.setState({ isValid: false, invite: null, showModal: false });
  };

  closeModal = e => {
    this.setState({ showModal: false });
  };

  onSelectAdvisee = index => {
    const { onSelectAdvisee } = this.props.actions;
    onSelectAdvisee(index);
  };

  render() {
    const { user, advisees, selectedAdvisee, global } = this.props.appReducer;
    const { logout } = this.props.actions;
    return user.uid === undefined ? (
      <Redirect
        to={{
          pathname: "/login",
          state: { from: this.props.location }
        }}
      />
    ) : (
      <div className="app-container">
        <div
          style={{
            position: "relative",
            padding: 20,
            color: '#30c0ff'
          }}
        >
          <h2 style={{ margin: 0 }}>Welcome, {user.userName}</h2>
          <button
            style={{
              position: "absolute",
              top: 15,
              right: 20,
              fontSize: 16,
              width: 100,
              height: 35
            }}
            onClick={() => logout()}
          >
            Log Out
          </button>
        </div>
        <div
          style={{
            float: "left",
            width: "25%"
          }}
        >
          <div style={{ padding: 20, fontSize: 24, borderBottom: "1px solid #eaeaea", color: '#fff' }}>
            Advisees
          </div>
          {!!advisees &&
            !!advisees.length &&
            advisees.map((advisee, index) => (
              <div
                key={advisee}
                onClick={() => this.onSelectAdvisee(index)}
                style={{
                  padding: 20,
                  fontWeight:
                    advisee === selectedAdvisee.name
                      ? "bold"
                      : "normal",
                  cursor: "pointer",
                  borderBottom: "1px solid #eaeaea",
                  color: '#fff'
                }}
              >
                {advisee}
              </div>
            ))}
        </div>
        <AdviseeInfo selectedAdvisee={selectedAdvisee} global={global} />
        <div style={{ clear: "both" }} />
        {this.state.isValid && this.state.showModal && (
          <AdvisorInviteModal
            advisee={this.state.invite.advisee}
            inviteResponse={this.inviteResponse}
            closeModal={this.closeModal}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { appReducer } = state;
  return {
    appReducer
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(actions, dispatch) };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AdvisorContainer)
);
