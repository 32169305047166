import React from "react";
import Portal from "../Portal";
import Modal from "../Modal";

const AdvisorInviteModal = props => (
  <Portal>
    <Modal>
      <div>
        <div className="advisor-invite-close" onClick={props.closeModal} />
        <h2>You're Invited!</h2>
        <div className="advisor-invite">
          <span>
            You have an invite from <strong>{props.advisee}</strong> to be their
            advisor.
          </span>
          <div className="advisor-invite-button-group">
            <button onClick={() => props.inviteResponse(true)}>Accept</button>
            <button onClick={() => props.inviteResponse(false)}>Decline</button>
          </div>
        </div>
      </div>
    </Modal>
  </Portal>
);

export default AdvisorInviteModal;
