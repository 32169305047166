import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import rootReducer from './reducers/rootReducer';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [
    'taskDetailReducer',
    'planReducer',
    'goalReducer',
    'taskGroupReducer',
    'memberReducer',
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const reduxDevtools = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// let middlewares
// if (process.env.NODE_ENV === 'development') {
//   middlewares = reduxDevtools(applyMiddleware(thunkMiddleware));
// } else if (process.env.NODE_ENV === 'production') {
//   middlewares = applyMiddleware(thunkMiddleware);
// }

let middlewares = applyMiddleware(thunkMiddleware);

const appStore = createStore(persistedReducer, middlewares);

const persistor = persistStore(appStore);

export { appStore, persistor };
