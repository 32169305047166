import _ from 'lodash';
import * as moment from 'moment';

export const checkTaskExpiry = (tasks) => {
  let tasksArray = [];
  if (tasks) {
    Object.values(tasks).forEach((task) => {
      if (task && task.endDate && task.endTime) {
        task.endDate =
          typeof task.endDate === 'object'
            ? moment(task.endDate).format('MM/DD/YYYY')
            : task.endDate;
        task.endTime =
          typeof task.endTime === 'object'
            ? moment(task.endTime).format('LT')
            : task.endTime;
        const endDate = moment(`${task.endDate} ${task.endTime}`);
        const currentDate = moment();
        if (currentDate.isBefore(endDate)) {
          tasksArray.push(task);
        }
      }
    });
  }
  return tasksArray;
};

export const checkTaskIsComplete = (tasks, users, selectedTask) => {
  if (selectedTask && selectedTask !== undefined) {
    let usersArray = [];
    let allTasks = checkTaskExpiry(tasks);
    const companyID = localStorage.getItem('companyID');
    let allUsers = _.filter(users, ['companyId', companyID]);
    usersArray = _.filter(allUsers, ['empTaskGroupId', selectedTask.id]);

    let notCompleteTasksArray = [];
    if (allTasks) {
      _.forEach(allTasks, (task) => {
        if (task && task.assignBy === 'employee') {
          let filterUser = _.filter(allUsers, ['username', task.username]);
          if (filterUser) {
            _.forEach(filterUser, (u) => {
              if (u && u.tasks) {
                _.forEach(u.tasks, (utask) => {
                  if (utask && utask.id) {
                    if (
                      task.id === utask.id &&
                      utask.isComplete === false &&
                      utask.isDeleted === false
                    ) {
                      notCompleteTasksArray.push(task);
                    }
                  }
                });
              }
            });
          }
        } else if (task && task.assignBy === 'taskGroup') {
          let taskGroupUsers = [];
          if (task && task.taskGroupUsers)
            _.forEach(task.taskGroupUsers, (tgUsers) => {
              if (tgUsers && tgUsers.id) {
                _.forEach(usersArray, (user) => {
                  if (user && user.id && user.id === tgUsers.id) {
                    taskGroupUsers.push(user);
                  }
                });
              }
            });
          if (taskGroupUsers) {
            let taskGroupUsersLength = taskGroupUsers.length;
            let isCompleteTasksLength = 0;
            let isDeletedTasksLength = 0;
            _.forEach(taskGroupUsers, (user) => {
              if (user && user.tasks) {
                _.forEach(user.tasks, (utask) => {
                  if (utask && utask.id && utask.assignBy === 'taskGroup') {
                    if (
                      (task.id === utask.id && utask.isComplete === true) ||
                      (task.id === utask.id && utask.isDeleted === true)
                    ) {
                      if (utask.isComplete) isCompleteTasksLength++;
                      if (utask.isDeleted) isDeletedTasksLength++;
                    }
                  }
                });
              }
            });
            let isCompleted = false;
            let isDeleted = false;
            isCompleted =
              taskGroupUsersLength === isCompleteTasksLength ? true : false;
            isDeleted =
              taskGroupUsersLength === isDeletedTasksLength ? true : false;
            if (!isCompleted && !isDeleted) {
              if (!_.some(notCompleteTasksArray, ['id', task.id])) {
                notCompleteTasksArray.push(task);
              }
            }
          }
        }
      });
    }
    notCompleteTasksArray &&
      _.map(notCompleteTasksArray, (task) => {
        if (task) {
          if (task.isComplete) task.isComplete = false;
          if (task.isDeleted) task.isDeleted = false;
        }
      });
    return notCompleteTasksArray;
  }
};

export const getComplatedTasks = (usersArray, selectedTask) => {
  let completedTasksArray = [];
  let isNotCompletedDeleted = [];
  if (usersArray) {
    _.forEach(usersArray, (user) => {
      if (user.tasks) {
        user.tasks = Object.values(user.tasks);
        _.forEach(user.tasks, (task) => {
          if (
            task &&
            task.taskGroupId === selectedTask.id &&
            task.assignBy === 'employee'
          ) {
            // For Complete
            if (task.isComplete) {
              completedTasksArray.push(task);
            }
            // For Not Complete
            if (task.isComplete === false && task.isDeleted === false) {
              isNotCompletedDeleted.push(task);
            }
          } else if (
            task &&
            task.taskGroupId === selectedTask.id &&
            task.assignBy === 'taskGroup'
          ) {
            let taskGroupUsers = [];
            if (task && task.taskGroupUsers)
              _.forEach(task.taskGroupUsers, (tgUsers) => {
                if (tgUsers && tgUsers.id) {
                  _.forEach(usersArray, (user) => {
                    if (user && user.id && user.id === tgUsers.id) {
                      taskGroupUsers.push(user);
                    }
                  });
                }
              });
            if (taskGroupUsers) {
              let taskGroupUsersLength = taskGroupUsers.length;
              let isCompleteTasksLength = 0;
              let isDeletedTasksLength = 0;
              _.forEach(taskGroupUsers, (user) => {
                if (user && user.tasks) {
                  _.forEach(user.tasks, (utask) => {
                    if (utask && utask.id && utask.assignBy === 'taskGroup') {
                      if (
                        (task.id === utask.id && utask.isComplete === true) ||
                        (task.id === utask.id && utask.isDeleted === true)
                      ) {
                        if (utask.isComplete) isCompleteTasksLength++;
                        if (utask.isDeleted) isDeletedTasksLength++;
                      }
                    }
                  });
                }
              });
              // For Complete
              if (taskGroupUsersLength === isCompleteTasksLength) {
                completedTasksArray.push(task);
              }
              // For Not Complete
              let isCompleted = false;
              let isDeleted = false;
              isCompleted =
                taskGroupUsersLength === isCompleteTasksLength ? true : false;
              isDeleted =
                taskGroupUsersLength === isDeletedTasksLength ? true : false;
              if (!isCompleted && !isDeleted) {
                if (!_.some(isNotCompletedDeleted, ['id', task.id])) {
                  isNotCompletedDeleted.push(task);
                }
              }
            }
          }
        });
      }
    });
  }
  return completedTasksArray;
};

export const getTasksByCompanyId = (tasks) => {
  const companyID = localStorage.getItem('companyID');
  return _.filter(Object.values(tasks), ['companyId', companyID]);
};

export const getUsersByCompanyId = (users) => {
  const companyID = localStorage.getItem('companyID');
  return _.filter(users, ['companyId', companyID]);
};

export const getActiveUserByEmpTaskGroupId = (users, taskGroup) => {
  let usersArray = getUsersByCompanyId(users);
  const activeMembers = _.filter(usersArray, (user) => {
    if (user.empTaskGroupId && user.empTaskGroupId !== '') {
      return user.empTaskGroupId === taskGroup.id;
    }
  });
  return activeMembers;
};
