export function planReducer(
    state = {
        inputValues: {
            groupName: "",
            groupItem: "",
        },
        savingPlan: false,
        savedPlan: false,
        plans: [],
    },
    action
) {
    switch (action.type) {
        case "SET_PLAN_INPUT_VALUE":
            return {
                ...state,
                inputValues: {
                    ...state.inputValues,
                    [action.name]: action.value
                }
            };
        case "SET_PLANS":
            return {
                ...state,
                plans: action.plans
            }
        case "SAVING_PLAN":
            return {
                ...state,
                savingPlan: action.savingPlan
            }
        case "SAVED_PLAN":
            return {
                ...state,
                savedPlan: action.savedPlan
            }
        default:
            return state;
    }
}
