import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import moment from 'moment';
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import Swal from 'sweetalert2';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import StoryboardGoalEditModal from '../modals/storyboardGoalTypeEdit';
import StoryboardTaskEditModal from '../modals/storyboardTaskEdit';
import GoalTypeContainer from './GoalTypeContainer';
import TaskGroupContainer from './TaskGroupContainer';
import TasksContainer from './TasksContainer';
import AllTaskGroup from './allTaskGroup';
import Ranking from './ranking.js';

import * as goalActions from '../../redux/actions/goalActions.js';
import * as memberActions from '../../redux/actions/memberActions.js';
import * as taskDetailActions from '../../redux/actions/taskDetailActions';
import * as storyboardActions from '../../redux/actions/storyboardAction';
import * as profileActions from './../../redux/actions/profileActions';
import * as loaderActions from './../../redux/actions/loaderAction';
import * as taskGroupActions from './../../redux/actions/taskGroupActions';

import { fba } from '../../firebase.js';
import { requestPermission } from './../../utils/fcmService';
import TaskGroupEditModal from '../modals/taskEditModal';
import {
  imgExtentionTypes,
  docExtentionType,
  videoExtentionType,
} from '../../utils/mime-type';

class Storyboard extends Component {
  state = {
    companyName: localStorage.getItem('company'),
    selectedTask: [],
    selectedUsersByTask: [],
    goalTypesById: [],
    toggleGoals: false,
    isChecked: false,
    isSelected: '',
    toggleTasks: false,
    tasksById: [],
    goalModalShow: false,
    selectedGoal: [],
    search_task: '',
    filtered: null,
    filterBy: 'title',
    taskModalShow: false,
    selectedEditedTask: [],
    tasksByTaskGroup: [],
    toggleTaskByTaskGroup: false,
    goalSelectedOption: { name: '', value: '' },
    allTasks: [],
    taskGroupId: '',
    isGoalSelected: {
      goalId: '',
      goalOptionId: '',
      value: false,
    },
    taskGroupModalShow: false,
    selectedEditedTaskGroup: {},
    editorState: EditorState.createEmpty(),
  };

  async componentDidMount() {
    fba.onAuthStateChanged((user) => {
      if (user) {
        requestPermission();
      }
    });
    let companyId = localStorage.getItem('companyID');
    const {
      fetchTaskGroups,
      fetchGoals,
      fetchEmp,
      fetchAllTasks,
    } = this.props.actions;
    await fetchTaskGroups(companyId); // get list of Task group from Firebase
    await fetchEmp(companyId); // get list of Employee from Firebase
    await fetchGoals(companyId); // get list of goals from Firebase
    await fetchAllTasks(companyId); // get list of all task from firebase

    navigator.serviceWorker.addEventListener('message', (message) => {
      if (message.data.firebaseMessaging) {
        const payload = message.data.firebaseMessaging.payload;
        if (payload) {
          Swal.fire({
            title: payload.notification.title,
            text: payload.notification.body,
            showCancelButton: true,
            confirmButtonColor: '#3a3a64',
            confirmButtonText: 'Show',
            imageUrl: payload.notification.icon,
            imageHeight: 100,
            imageAlt: payload.notification.title,
          }).then(async (result) => {
            if (result.value) {
              let companyId = localStorage.getItem('companyID');
              const {
                fetchTaskGroups,
                fetchGoals,
                fetchEmp,
                fetchAllTasks,
              } = this.props.actions;
              await fetchTaskGroups(companyId); // get list of Task group from Firebase
              await fetchEmp(companyId); // get list of Employee from Firebase
              await fetchGoals(companyId); // get list of goals from Firebase
              await fetchAllTasks(companyId); // get list of all task from firebase
              if (window.location.pathname === '/admin/storyboard') {
                this.renderStoryboardByNotification(payload);
              }
            }
          });
        }
      }
    });
  }

  renderStoryboardByNotification = (payload) => {
    if (payload && payload.data) {
      const { data } = payload;
      if (
        data &&
        data.taskGroupId &&
        data.taskGroupId !== '' &&
        data.goalId &&
        data.goalId !== '' &&
        data.goalOptionId &&
        data.goalOptionId !== ''
      ) {
        this.setState({ ...this.state, taskGroupId: '', selectedTask: [] });
        const taskGroupId = payload.data.taskGroupId;
        const goalId = payload.data.goalId;
        const goalOptionId = payload.data.goalOptionId;
        if (taskGroupId && goalId) {
          const { tasks } = this.props.taskDetailReducer;
          let tasksByTaskGroup = [];
          if (tasks) {
            tasksByTaskGroup = tasks.filter(
              (f) => f.taskGroupId === taskGroupId
            );
          } else {
            tasksByTaskGroup = [];
          }
          const { goals } = this.props.goalReducer;
          const finalGoals = goals.filter((a) => a.taskGroup === taskGroupId);

          const { inputValues } = this.props.taskDetailReducer;
          const { taskGroupOptions } = inputValues;
          const task = _.filter(taskGroupOptions, (t) => t.id === taskGroupId);
          let fetchTasks = [];
          if (tasks) {
            fetchTasks = tasks.filter((a) => a.goalId === goalId);
            _.map(fetchTasks, (task) => (task.selectLable = 'Please Select'));
          } else {
            fetchTasks = [];
          }
          const { users } = this.props.memberReducer;
          this.setState({
            ...this.state,
            taskGroupId,
            selectedTask: task[0],
            selectedUsersByTask: users,
            goalTypesById: finalGoals,
            isChecked: false,
            toggleGoals: true,
            toggleTasks: true,
            tasksByTaskGroup,
            filtered: fetchTasks,
            toggleTaskByTaskGroup: true,
            allTasks: tasks,
            isGoalSelected: {
              goalId,
              goalOptionId,
              value: true,
            },
          });
        }
      }
    }
  };

  onSelectingTaskGroup = (task, isChecked) => {
    this.setState({ ...this.state, tasksByTaskGroup: [], selectedTask: [] });
    const { tasks } = this.props.taskDetailReducer;
    let tasksByTaskGroup = [];
    if (tasks) {
      tasksByTaskGroup = tasks.filter((f) => f.taskGroupId === task.id);
    } else {
      tasksByTaskGroup = [];
    }
    const { goals } = this.props.goalReducer;
    const finalGoals = goals.filter((a) => a.taskGroup === task.id);
    const { users } = this.props.memberReducer;

    const { clearStoryBoardInputValue } = this.props.actions;
    clearStoryBoardInputValue();

    this.setState({
      selectedTask: task,
      selectedUsersByTask: users && users !== undefined ? users : [],
      goalTypesById: finalGoals,
      isChecked: false,
      toggleGoals: true,
      toggleTasks: false,
      tasksByTaskGroup,
      toggleTaskByTaskGroup: true,
      allTasks: tasks,
      isGoalSelected: {
        goalId: '',
        goalOptionId: '',
        value: false,
      },
    });
  };

  handleToggleGoalModel = (obj) => {
    this.setState({ goalModalShow: true, selectedGoal: obj });
  };

  handleDeleteGoal = (obj) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this goal!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3a3a64',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      preConfirm: async () => {
        Swal.getTitle().innerHTML = 'Please Wait!';
        Swal.getConfirmButton().innerHTML = `Deleting...`;
        Swal.getCancelButton().style.display = 'none';
        const { deleteGoals } = this.props.actions;
        await deleteGoals(obj).then((res) => {
          const finalGoals = this.state.goalTypesById.filter(
            (a) => a.id !== obj.id
          );
          this.setState({
            goalTypesById: finalGoals,
            toggleTasks: this.state.tasksById.length === 0 ? false : true,
          });
        });
      },
    }).then((res) => {
      if (res.value) {
        Swal.fire('Deleted!', 'Your goal has been deleted.', 'success');
      }
    });
  };

  onSelectingTaskByCheckbox = (e, obj) => {
    let fetchTasks = [];
    const { tasks } = this.props.taskDetailReducer;
    if (tasks) {
      fetchTasks = tasks.filter((a) => (a.goalId === obj.id) && (a.companyId === obj.companyId));
      _.map(fetchTasks, (task) => (task.selectLable = 'Please Select'));
    }
    const { users } = this.props.memberReducer;
    this.setState({
      ...this.state,
      selectedUsersByTask: users,
      toggleTasks: true,
      isSelected: obj.name,
      tasksById: fetchTasks,
      filtered: fetchTasks,
      isGoalSelected: {
        goalId: obj.id,
        goalOptionId: '',
        value: true,
      },
    });
  };

  handleSelectedGoalOptionChange = (name, val) => {
    let fetchTasks = [];
    const { tasks } = this.props.taskDetailReducer;
    if (tasks) {
      fetchTasks = tasks.filter((a) => a.goalOptionId === val);
      _.map(fetchTasks, (task) => (task.selectLable = 'Please Select'));
    }
    const { users } = this.props.memberReducer;

    const { setStoryboardGoalInputValue } = this.props.actions;
    setStoryboardGoalInputValue(name, val);
    this.setState({
      ...this.state,
      isChecked: true,
      selectedUsersByTask: users,
      toggleTasks: true,
      filtered: fetchTasks,
    });
  };

  handleToggleTaskModel = (selectedEditedTask) => {
    const {
      assignBy,
      empID,
      title,
      points,
      taskGroupId,
      goalId,
      goalOptionId,
      startDate,
      endDate,
      endTime,
      file,
      files,
      description,
    } = selectedEditedTask;
    const onInputChange = this.props.actions.setTaskDetailInputValue;
    const startDateObj = moment(startDate).toDate();
    const endDateObj = moment(endDate).toDate();
    const endTimeObj = moment(`${endDate} ${endTime}`).toDate();
    const editorDescription = EditorState.createWithContent(
      ContentState.createFromBlockArray(convertFromHTML(description))
    );
    onInputChange('assignBy', assignBy);
    onInputChange('empID', empID);
    onInputChange('taskDetailTitle', title);
    onInputChange('taskDetailPoints', points);
    onInputChange('taskGroup', taskGroupId);
    onInputChange('taskDetailsGoalType', goalId);
    onInputChange('taskDetailsGoalOption', goalOptionId);
    onInputChange('startDate', startDateObj);
    onInputChange('endDate', endDateObj);
    onInputChange('endTime', endTimeObj);
    onInputChange('editorState', editorDescription);
    if (file === '') {
      onInputChange('file', '');
    }
    if (files && files.length > 0) {
      _.forEach(files, (file, i) => {
        if (imgExtentionTypes.includes(file.fileExtention)) {
          file.isImage = true;
        } else if (docExtentionType.includes(file.fileExtention)) {
          file.isDocument = true;
        } else if (videoExtentionType.includes(file.fileExtention)) {
          file.isVideo = true;
        } else if (!file.fileExtention) {
          const url = file.url.split('?alt')[0];
          file.isImage = imgExtentionTypes.indexOf(url.split('.').pop(-1)) >= 0;
          file.isDocument =
            docExtentionType.indexOf(url.split('.').pop(-1)) >= 0;
          file.isVideo =
            videoExtentionType.indexOf(url.split('.').pop(-1)) >= 0;
        }
      });
      onInputChange('originalFiles', files);
    } else {
      onInputChange('originalFiles', null);
    }
    this.setState({ taskModalShow: true, selectedEditedTask });
  };

  handleCloseTaskModel = () => {
    this.setState({ taskModalShow: false });
  };

  handleDeleteTask = (obj) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this task!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3a3a64',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      preConfirm: async () => {
        Swal.getTitle().innerHTML = 'Please Wait!';
        Swal.getConfirmButton().innerHTML = `Deleting...`;
        Swal.getCancelButton().style.display = 'none';
        const { deleteTasks } = this.props.actions;
        await deleteTasks(obj).then((res) => {
          const fetchTasks = this.state.tasksById.filter(
            (a) => a.id !== obj.id
          );
          const tasksByTaskGroup = this.state.tasksById.filter(
            (a) => a.id !== obj.id
          );
          const { users } = this.props.memberReducer;
          this.setState({
            tasksById: fetchTasks,
            filtered: fetchTasks,
            tasksByTaskGroup,
            selectedUsersByTask: users,
          });
        });
      },
    }).then((res) => {
      if (res.value) {
        Swal.fire('Deleted!', 'Your task has been deleted.', 'success');
      }
    });
  };

  handleInputChange = (e) => this.setState({ [e.target.name]: e.target.value });

  filterBy = (val) => this.setState({ filterBy: val });

  filterTask = (event) => {
    let { tasksById, filterBy } = this.state;
    const { target } = event;
    const targetValue = target.value.toLowerCase();
    if (targetValue !== '') {
      const regexPattern = new RegExp(targetValue.trim(), 'g');
      const filterObj = _.reduce(
        tasksById,
        (acc, obj) => {
          if (filterBy === 'title') {
            if (
              obj.title.toLowerCase() &&
              obj.title.toLowerCase().match(regexPattern)
            ) {
              acc.push(obj);
            }
          } else if (filterBy === 'username') {
            if (
              obj.username.toLowerCase() &&
              obj.username.toLowerCase().match(regexPattern)
            ) {
              acc.push(obj);
            }
          } else if (filterBy === 'startDate') {
            if (obj.startDate && obj.startDate.match(regexPattern)) {
              acc.push(obj);
            }
          } else if (filterBy === 'points') {
            if (obj.points && obj.points.match(regexPattern)) {
              acc.push(obj);
            }
          }
          return acc;
        },
        []
      );
      this.setState({
        filtered: filterObj,
      });
    } else {
      this.setState({
        filtered: tasksById,
      });
    }
  };

  handleSubmitGoalType = (obj) => {
    if (obj) {
      const { updateGoal } = this.props.actions;
      return updateGoal(obj).then(() => {
        const selectedTaskGroup = this.state.selectedTask;
        const { goals } = this.props.goalReducer;
        const finalGoals = goals.filter(
          (g) => g.taskGroup === selectedTaskGroup.id
        );
        const { tasks } = this.props.taskDetailReducer;
        const tasksByTaskGroup =
          tasks && tasks.filter((f) => f.taskGroupId === selectedTaskGroup.id);
        const { users } = this.props.memberReducer;
        const { clearStoryBoardInputValue } = this.props.actions;
        clearStoryBoardInputValue();
        this.setState({
          selectedUsersByTask: users,
          goalTypesById: finalGoals,
          isChecked: false,
          toggleGoals: true,
          toggleTasks: this.state.toggleTasks ? true : false,
          tasksByTaskGroup,
          toggleTaskByTaskGroup: true,
          allTasks: tasks,
          isGoalSelected: {
            goalId: '',
            goalOptionId: '',
            value: false,
          },
          goalModalShow: false,
        });
      });
    }
  };

  handleTaskUpdateSubmit = () => {
    const { selectedEditedTask } = this.state;
    const { updateTaskDetails } = this.props.actions;
    return updateTaskDetails(selectedEditedTask).then((res) => {
      let fetchTasks = [];
      const { tasks } = this.props.taskDetailReducer;
      let tasksByTaskGroup = [];

      if (tasks) {
        tasksByTaskGroup = tasks.filter(
          (f) => f.taskGroupId === selectedEditedTask.taskGroupId
        );
        fetchTasks = tasks.filter(
          (a) => a.goalId === selectedEditedTask.goalId
        );
        _.map(fetchTasks, (task) => (task.selectLable = 'Please Select'));
      }
      const { users } = this.props.memberReducer;
      Swal.fire('Updated!', 'Your task has been updated.', 'success');
      this.setState({
        ...this.state,
        selectedUsersByTask: users,
        toggleTasks: true,
        tasksById: fetchTasks,
        filtered: fetchTasks,
        taskModalShow: false,
        selectedEditedTask: {},
        tasksByTaskGroup: tasksByTaskGroup,
      });
      this.props.actions.clearTaskInputModal();
    });
  };

  handleDeleteTaskGroup = (taskGroupId) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this taskGroup!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3a3a64',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      preConfirm: async () => {
        Swal.getTitle().innerHTML = 'Please Wait!';
        Swal.getConfirmButton().innerHTML = `Deleting...`;
        Swal.getCancelButton().style.display = 'none';
        const { delteTaskGroup } = this.props.actions;
        await delteTaskGroup(taskGroupId).then((res) => {
          const { inputValues } = this.props.taskDetailReducer;
          this.setState({
            selectedTask: [],
            goalTypesById: [],
            isChecked: false,
            toggleGoals: false,
            toggleTasks: false,
            toggleTaskByTaskGroup: false,
            isGoalSelected: {
              goalId: '',
              goalOptionId: '',
              value: false,
            },
          });
        });
      },
    }).then((res) => {
      if (res.value) {
        Swal.fire('Deleted!', 'Your taskGroup has been deleted.', 'success');
      }
    });
  };

  onTaskGroupModalToggle = (event, task) => {
    const { setTaskGroupInputValue } = this.props.actions;
    setTaskGroupInputValue('taskGroupColor', task.color);
    setTaskGroupInputValue('taskGroupName', task.name);
    setTaskGroupInputValue('planName', task.planName);
    this.setState({ taskGroupModalShow: true, selectedEditedTaskGroup: task });
  };

  onUpdateTaskGroup = () => {
    const { selectedEditedTaskGroup } = this.state;
    const { updateTaskGroup } = this.props.actions;
    return updateTaskGroup(selectedEditedTaskGroup.id).then((res) => {
      if (res && res.close) {
        this.setState({
          taskGroupModalShow: false,
          selectedTask: this.state.selectedEditedTaskGroup,
        });
      } else if (res === undefined) {
        this.setState({
          taskGroupModalShow: false,
          selectedTask: this.state.selectedEditedTaskGroup,
        });
      }
    });
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.profileReducer.notification &&
      this.props.profileReducer.notification
    ) {
      const prevPropsId =
        prevProps.profileReducer.notification &&
        prevProps.profileReducer.notification !== undefined &&
        prevProps.profileReducer.notification !== ''
          ? prevProps.profileReducer.notification
          : '';

      const propsId =
        this.props.profileReducer.notification &&
        this.props.profileReducer.notification !== undefined &&
        this.props.profileReducer.notification !== ''
          ? this.props.profileReducer.notification
          : '';

      if (prevPropsId !== propsId) {
        this.renderStoryboardByNotification(
          this.props.profileReducer.notification
        );
      }
    }
  }

  // componentWillUnmount() {
  //   this.props.actions.unMountTaskDetailReducer();
  // }

  render() {
    // Actions
    const {
      setStoryboardGoalInputValue,
      setGoalOptionsValue,
      setTaskDetailInputValue,
      setTaskGroupInputValue,
      removeOriginalFile,
      clearTaskFiles,
      clearTaskInputModal,
      resetTaskGroup,
    } = this.props.actions;
    // Reducers
    const { savingTaskGroup, savedTaskGroup } = this.props.taskGroupReducer;
    const taskGroupInputValues = this.props.taskGroupReducer.inputValues;
    const { goals, savingGoal, savedGoal } = this.props.goalReducer;
    const { employees } = this.props.memberReducer.inputValues;
    const isLoading = this.props.loaderReducer.loader;
    // TaskDetail Reducer
    const { inputValues, savingTask, savedTask } = this.props.taskDetailReducer;
    const { taskGroupOptions } = inputValues;
    if (inputValues.startDate && inputValues.startDate !== '') {
      const startDate = inputValues.startDate;
      inputValues.endDate = '';
      let endDate = startDate;
      endDate = moment(moment(endDate).add('days', 30)).toDate();
      inputValues.endDate = endDate;
    }
    let filter_goals = [];
    if (inputValues.taskGroup !== '') {
      filter_goals = _.filter(goals, { taskGroup: inputValues.taskGroup });
      let emp = _.filter(employees, { empID: inputValues.empID.split('-')[0] });
      if (emp.length > 0) {
        if (emp[0].goals !== undefined) {
          for (let i = 0; i < emp[0].goals.length; i++) {
            filter_goals = _.remove(filter_goals, (obj) => {
              return obj.id !== emp[0].goals[i];
            });
          }
        }
      }
    }
    return (
      <>
        {isLoading ? (
          <div>
            <Backdrop
              style={{
                zIndex: 99,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: 'transparent',
              }}
              open={isLoading}
            >
              <CircularProgress color='inherit' />
            </Backdrop>
          </div>
        ) : (
          <div>
            <div className='plan-content'>
              <h2>Storyboard</h2>
              <p className='plan-para'> Welcome, {this.state.companyName}</p>
            </div>
            <div className='row'>
              <>
                <div className='col-xl-3 col-lg-4 col-md-5 col-sm-12 col-12'>
                  <div className='taskgroup'>
                    <TaskGroupContainer
                      taskGroupId={this.state.taskGroupId}
                      taskGroupOptions={taskGroupOptions}
                      onSelectingTaskGroup={this.onSelectingTaskGroup}
                      onDeletingTaskGroup={this.handleDeleteTaskGroup}
                      onTaskGroupModalOpen={this.onTaskGroupModalToggle}
                    />
                  </div>
                  <div className='taskgroup mt-2 backgroudtransparent'>
                    {this.state.toggleTaskByTaskGroup &&
                    this.state.toggleGoals ? (
                      <Ranking
                        tasksByTaskGroup={this.state.tasksByTaskGroup}
                        selectedUsersByTask={this.state.selectedUsersByTask}
                        selectedTask={this.state.selectedTask}
                      />
                    ) : null}
                  </div>
                </div>
              </>
              <div className='col-xl-9 col-lg-8 col-md-7 col-sm-12 col-12 pl-0 tableboxgroup'>
                <div className='taskgroup ml-0 mr-3 pl-1'>
                  {this.state.toggleGoals ? (
                    <GoalTypeContainer
                      goals={this.state.goalTypesById}
                      selectedTask={this.state.selectedTask}
                      selectedGoal={this.state.selectedGoal}
                      onEdit={this.handleToggleGoalModel}
                      onDelete={this.handleDeleteGoal}
                      handleChange={this.onSelectingTaskByCheckbox}
                      isChecked={false}
                      handleSelectedGoalOptionChange={
                        this.handleSelectedGoalOptionChange
                      }
                      setStoryboardGoalInputValue={setStoryboardGoalInputValue}
                      storyboardReducer={this.props.storyboardReducer}
                      setGoalOptionsValue={setGoalOptionsValue}
                      isGoalSelected={this.state.isGoalSelected}
                    />
                  ) : null}
                </div>
                {this.state.toggleTasks ? (
                  <>
                    <div className='taskgroup ml-0 mr-3 mt-3 mb-3'>
                      <TasksContainer
                        selectedTask={this.state.selectedTask}
                        fetchTasks={this.state.filtered}
                        onEdit={this.handleToggleTaskModel}
                        onDelete={this.handleDeleteTask}
                        onFilter={this.filterTask}
                        filterBy={this.filterBy}
                        search={this.state.search_task}
                        selectedUsersByTask={this.state.selectedUsersByTask}
                      />
                    </div>
                  </>
                ) : null}
                {this.state.toggleTaskByTaskGroup ? (
                  <AllTaskGroup
                    selectedTask={this.state.selectedTask}
                    tasksByTaskGroup={this.state.tasksByTaskGroup}
                    selectedUsersByTask={this.state.selectedUsersByTask}
                    tasks={this.state.allTasks}
                    taskGroupModalShow={this.state.taskGroupModalShow}
                  />
                ) : null}
              </div>
            </div>
            {/* Goal Edit Modal */}
            <StoryboardGoalEditModal
              {...this.props}
              taskGroupOptions={taskGroupOptions}
              onShow={this.state.goalModalShow}
              onEdit={this.handleToggleGoalModel}
              selectedGoal={this.state.selectedGoal}
              onHide={() =>
                this.setState({ goalModalShow: false, selectedGoal: {} })
              }
              savingGoal={savingGoal}
              savedGoal={savedGoal}
              onSubmit={this.handleSubmitGoalType}
            />

            {/* Task Edit Modal */}
            {this.state.taskModalShow && (
              <StoryboardTaskEditModal
                inputValues={inputValues}
                onInputChange={setTaskDetailInputValue}
                onShow={this.state.taskModalShow}
                onHide={() => {
                  clearTaskInputModal();
                  this.setState({
                    taskModalShow: false,
                    selectedEditedTask: {},
                  });
                }}
                key={inputValues.taskId}
                clearTaskFiles={clearTaskFiles}
                removeOriginalFile={removeOriginalFile}
                taskGroupOptions={taskGroupOptions}
                selectedEditedTask={this.state.selectedEditedTask}
                employees={employees}
                goals={filter_goals}
                savedTask={savedTask}
                savingTask={savingTask}
                handleSubmit={this.handleTaskUpdateSubmit}
              />
            )}
            {/* taskGroup Edit Modal */}
            {this.state.taskGroupModalShow && (
              <TaskGroupEditModal
                {...this.props}
                onShow={this.state.taskGroupModalShow}
                onHide={() => {
                  resetTaskGroup();
                  this.setState({ taskGroupModalShow: false });
                }}
                inputValues={taskGroupInputValues}
                onInputChange={setTaskGroupInputValue}
                savingGroup={savingTaskGroup}
                savedGroup={savedTaskGroup}
                updateTaskGroup={this.onUpdateTaskGroup}
              />
            )}
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    taskDetailReducer,
    goalReducer,
    memberReducer,
    storyboardReducer,
    profileReducer,
    loaderReducer,
    taskGroupReducer,
  } = state;
  return {
    taskDetailReducer,
    goalReducer,
    memberReducer,
    storyboardReducer,
    profileReducer,
    loaderReducer,
    taskGroupReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      ...bindActionCreators(taskDetailActions, dispatch),
      ...bindActionCreators(goalActions, dispatch),
      ...bindActionCreators(memberActions, dispatch),
      ...bindActionCreators(storyboardActions, dispatch),
      ...bindActionCreators(profileActions, dispatch),
      ...bindActionCreators(loaderActions, dispatch),
      ...bindActionCreators(taskGroupActions, dispatch),
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Storyboard)
);
