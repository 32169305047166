import { db } from '../../firebase.js';
import _ from 'lodash';

export const savePlan = companyId => async (dispatch, getState) => {
  const values = getState().planReducer.inputValues;
  const publicCreatedPlansRef = db.ref('plans');
  dispatch(savingPlan(true));
  let newCreatedPlanRef = await publicCreatedPlansRef.push();
  newCreatedPlanRef
    .set({
      isActive: false,
      id: newCreatedPlanRef.key,
      name: values.groupItem,
      taskGroupId: values.groupName,
      companyId: companyId
    })
    .then(() => {
      setTimeout(() => dispatch(savingPlan(false)), 2000);
      setTimeout(() => dispatch(savedPlan(true)), 2500);
      setTimeout(() => dispatch(savedPlan(false)), 3500);
    })
    .catch(err => {
      dispatch(savingPlan(false));
      dispatch(savedPlan(false));
    });
};

export const fetchPlans = companyID => (dispatch, getState) => {
  db.ref('plans')
    .once('value')
    .then(snapshot => {
      if (snapshot.exists()) {
        let plans = Object.values(snapshot.val());
        var list = Object.values(plans);
        let li = _.filter(list, { companyId: companyID });
        dispatch(setPlans(li));
      }
    });
};

const setPlans = plans => ({
  type: 'SET_PLANS',
  plans
});

export const setPlanInputValue = (name, value) => {
  return {
    type: 'SET_PLAN_INPUT_VALUE',
    name,
    value
  };
};

const savingPlan = bool => ({
  type: 'SAVING_PLAN',
  savingPlan: bool
});

const savedPlan = bool => ({
  type: 'SAVED_PLAN',
  savedPlan: bool
});
