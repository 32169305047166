import React, { Fragment } from 'react';

const GoalTypeQualifiers = ({
  onInputChange,
  inputValues: { goalTypeQualifiers },
  goals,
}) => {
  return (
    <>
      {goals &&
        goals.map((option) =>
          option.values !== undefined ? (
            <Fragment key={option.id}>
              <label htmlFor={option.id}>{option.name}</label>
              <select
                required
                type='select'
                id={option.id}
                name={option.id}
                onChange={(e) => onInputChange(e.target.name, e.target.value)}
                value={
                  Object.keys(goalTypeQualifiers).length !== 0
                    ? goalTypeQualifiers[option.id]
                    : ''
                }
              >
                <option value='' style={{ fontWeight: 'bold' }}>
                  Please Select
                </option>
                {option.options &&
                  Object.values(option.options).map((value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
              </select>
            </Fragment>
          ) : (
            ''
          )
        )}
    </>
  );
};

export default GoalTypeQualifiers;
