/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, createRef } from 'react';
import _ from 'lodash';
import { db } from '../../firebase';

class GoalTypeContainer extends Component {
  state = {
    goalId: '',
    taskDetailsGoalOption: '',
    taskDetailsGoalOptionValue: '',
    filteredValuesByOption: [],
    isChecked: false,
  };
  checkBoxRef = createRef();

  onChangeGoalOption = async (name, valueId) => {
    this.setState({
      ...this.state,
      [name]: valueId,
      taskDetailsGoalOption: '',
      taskDetailsGoalOptionValue: '',
      filteredValuesByOption: [],
    });
    const { goals } = this.props;
    let new_goal = [];
    new_goal = Object.values(goals);
    const options = new_goal[0].options
      ? Object.values(new_goal[0].options)
      : [];
    const filteredOptions = options.filter((o) => o.id === valueId);
    await this.props.handleSelectedGoalOptionChange(name, valueId);
    let snapshotArray = [];
    if (filteredOptions && filteredOptions.length > 0) {
      await db
        .ref(
          `goals/${this.state.goalId}/options/${filteredOptions[0].id}/values`
        )
        .once('value')
        .then((snapshot) => {
          snapshot &&
            snapshot.forEach((childSnap) => {
              snapshotArray.push({
                goalId: this.state.goalId,
                optionId: valueId,
                val: childSnap.val(),
                id: childSnap.key,
              });
            });
        });
      this.setState({
        ...this.state,
        taskDetailsGoalOption: valueId,
        filteredValuesByOption: snapshotArray,
      });
    }
    await this.props.setGoalOptionsValue(snapshotArray);
    await this.props.setStoryboardGoalInputValue('isChecked', true);
  };

  onChangeGoalOptionValue = (name, value) => {
    this.setState({ ...this.state, [name]: value });
  };

  handleChangeCheckbox = (e, obj) => {
    const { inputValues } = this.props.storyboardReducer;
    this.setState({ isChecked: inputValues.isChecked });
    this.props.setStoryboardGoalInputValue(e.target.name, e.target.checked);
    this.props.handleChange(e, obj);
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.goals.length > 0) {
      _.forEach(nextProps.goals, (goal) => {
        if (goal.id !== this.state.taskDetailsGoalOptionValue) {
          this.setState({
            ...this.state,
            taskDetailsGoalOption: [],
            taskDetailsGoalOptionValue: [],
            filteredValuesByOption: [],
            isChecked: false,
          });
        }
      });
    }
  }

  getData = (new_goal) => {
    const { inputValues } = this.props.storyboardReducer;
    _.map(new_goal, (goal) => {
      if (goal) {
        if (goal.options && goal.options !== undefined) {
          goal.options = Object.values(goal.options);
        }
      }
    });

    const goalData = (
      <div className='table-responsive'>
        <table className='table tablegoal table-head-fixed table-resposnive-width'>
          <thead className='thead-light1'>
            <tr>
              <th className='goalname'>Goal Name</th>
              <th className='text-center'>Options</th>
              <th className='text-center'>Value</th>
              <th className='pl-13 text-center'>Actions</th>
            </tr>
          </thead>
          <div className='available-items available12'>
            <tbody>
              {_.map(new_goal, (obj, i) => (
                <tr key={i} id={obj.id}>
                  <>
                    <td>
                      <div className='radio'>
                        <input
                          type='radio'
                          name='is_company'
                          checked={this.props.isGoalSelected.goalId === obj.id}
                          onChange={(e) => this.handleChangeCheckbox(e, obj)}
                          value={inputValues.isChecked}
                          ref={this.checkBoxRef}
                        />

                        <span className='checkround'></span>
                      </div>
                      <span className='tablename'>{obj.name}</span>
                    </td>{' '}
                    <td className='text-center'>
                      <select
                        style={{ marginLeft: '-10px' }}
                        required
                        type='select'
                        id='taskDetailsGoalOption'
                        name='taskDetailsGoalOption'
                        onChange={async (e) => {
                          const target = e.target;
                          await this.setState({
                            ...this.state,
                            goalId: obj.id,
                          });
                          await this.onChangeGoalOption(
                            target.name,
                            target.value
                          );
                        }}
                        defaultValue={''}
                        // value={this.state.taskDetailsGoalOption}
                      >
                        <option value='' style={{ fontWeight: 'bold' }}>
                          Please Select Option
                        </option>
                        {obj.options &&
                          obj.options !== undefined &&
                          obj.options.map((o, index) => (
                            <option id={o.id} key={index} value={o.id}>
                              {o.name}
                            </option>
                          ))}
                      </select>
                    </td>
                    {obj.options ? (
                      <td className='text-center'>
                        <select
                          style={{ marginLeft: '11px' }}
                          required
                          type='select'
                          id='taskDetailsGoalOptionValue'
                          name='taskDetailsGoalOptionValue'
                          onChange={(e) =>
                            this.onChangeGoalOptionValue(
                              e.target.name,
                              e.target.value
                            )
                          }
                          defaultValue={''}
                          // value={this.state.taskDetailsGoalOptionValue}
                        >
                          <option value='' style={{ fontWeight: 'bold' }}>
                            Please Select Value
                          </option>
                          {inputValues.filteredValuesByOption &&
                            _.map(
                              inputValues.filteredValuesByOption,
                              (value, valIndex) =>
                                obj.id === value.goalId ? (
                                  <option
                                    key={valIndex}
                                    id={value.id}
                                    value={value.val}
                                  >
                                    {value.val}
                                  </option>
                                ) : null
                            )}
                        </select>
                      </td>
                    ) : null}
                    <td className='text-center'>
                      <a
                        onClick={() => this.props.onEdit(obj)}
                        className='d-inline green-icon mr-2'
                      >
                        <i className='fa fa-pencil'></i>
                      </a>
                      <a
                        onClick={() => this.props.onDelete(obj)}
                        className='d-inline red-icon'
                      >
                        <i className='fa fa-trash-o'></i>
                      </a>
                    </td>
                  </>
                </tr>
              ))}
            </tbody>
          </div>
        </table>
      </div>
    );
    if (new_goal && new_goal.length === 0) {
      return <p className='text-center'>No Data Found...</p>;
    }
    return goalData;
  };

  render() {
    const { selectedTask, goals } = this.props;
    let new_goal = [];
    new_goal = Object.values(goals);
    return (
      <div className='taskcontent'>
        <h5 className='font-weight-bold d-inline'>
          {selectedTask && selectedTask.name && selectedTask.name !== undefined
            ? selectedTask.name
            : 'N/A'}{' '}
          <span className='font-weight-normal'> / Goal Type</span>
        </h5>
        <hr className='mb-3' />
        {this.getData(new_goal)}
      </div>
    );
  }
}

export default GoalTypeContainer;
